// Vendor Imports
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import { setEndDateColumn,
  setEventTitleColumn,
  setStartDateColumn
} from '../../../actions';
import {
  getCurrentMetadata,
  getNonGeoLocationColumns,
  getRecommendedDimensions,
  hasData
} from '../../../selectors/metadata';
import {
  getEndDateColumn,
  getEventTitleColumn,
  getStartDateColumn,
  getVisualizationType
} from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import AutocompleteColumnSelector from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';

// Constants
import { NULL_COLUMN_NAME, POINT_COLUMN_TYPES } from '../../../constants';

const scope = 'shared.visualizations.panes.data.fields.calendar';

export class CalendarOptionsSelector extends Component {
  renderColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  };

  renderColumndDropdown = (dropDownOptions = {}) => {
    const { dropDownId, optionsData, onSelection, defaultValue, labelName } = dropDownOptions;
    const columnOptions = _.map(optionsData, column => ({
      title: column.name,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderColumnOption
    }));
    const options = [
      {
        title: I18n.t('no_value', { scope }),
        value: NULL_COLUMN_NAME
      },
      ...columnOptions
    ];

    const dropDownContainerClassName = `${dropDownId}-dropdown-container`;
    const dropDownAttributes = {
      id: dropDownId,
      placeholder: I18n.t('no_value', { scope }),
      options,
      onSelection: (option, index) => {
        if (option.value === NULL_COLUMN_NAME) {
          option.value = null;
        }
        onSelection(option, index);
      },
      // Forge Autocomplete does not call onSelect if option.value = null.
      // We pass it as a string so that the user can select the option.
      value: defaultValue === null | defaultValue === '' ? NULL_COLUMN_NAME : defaultValue
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="calendar-selector">{I18n.t(`${labelName}`, { scope })}</label>
        <div className={dropDownContainerClassName}>
          <AutocompleteColumnSelector {...dropDownAttributes} />
        </div>
      </div>
    );
  };

  renderStartDateColumn() {
    const { onSetStartDateColumn, metadata, vifAuthoring } = this.props;
    const visualizationType = getVisualizationType(vifAuthoring);
    const startDateDropDownOptions = {
      dropDownId: 'start-date-column',
      optionsData: getRecommendedDimensions(metadata, visualizationType),
      onSelection: (option) => onSetStartDateColumn(option.value),
      defaultValue: getStartDateColumn(vifAuthoring),
      labelName: 'start_date'
    };

    return this.renderColumndDropdown(startDateDropDownOptions);
  }

  renderEndDateColumn() {
    const { onSetEndDateColumn, metadata, vifAuthoring } = this.props;
    const visualizationType = getVisualizationType(vifAuthoring);
    const endDateDropDownOptions = {
      dropDownId: 'end-date-column',
      optionsData: getRecommendedDimensions(metadata, visualizationType),
      onSelection: (option) => onSetEndDateColumn(option.value),
      defaultValue: getEndDateColumn(vifAuthoring),
      labelName: 'end_date'
    };

    return this.renderColumndDropdown(endDateDropDownOptions);
  }

  renderEventTitleColumn() {
    const { onSetEventTitleColumn, metadata, vifAuthoring } = this.props;
    const visualizationType = getVisualizationType(vifAuthoring);
    const endDateDropDownOptions = {
      dropDownId: 'event-title-column',
      optionsData: getNonGeoLocationColumns(metadata, visualizationType),
      onSelection: (option) => onSetEventTitleColumn(option.value),
      defaultValue: getEventTitleColumn(vifAuthoring),
      labelName: 'event_title'
    };

    return this.renderColumndDropdown(endDateDropDownOptions);
  }

  renderCalendarOptions() {
    return (
      <div className="calendar-option-container">
        {this.renderStartDateColumn()}
        {this.renderEndDateColumn()}
        {this.renderEventTitleColumn()}
      </div>
    );
  }

  render() {
    const shouldRender = hasData(this.props.metadata);

    return shouldRender ? this.renderCalendarOptions() : null;
  }
}

CalendarOptionsSelector.propTypes = {
  metadata: PropTypes.shape({
    name: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        renderTypeName: PropTypes.oneOf([
          ...POINT_COLUMN_TYPES,
          'calendar_date',
          'checkbox',
          'date',
          'money',
          'number',
          'text'
        ]),
        name: PropTypes.string,
        fieldName: PropTypes.string,
      })
    )
  }),
  onSetStartDateColumn: PropTypes.func,
  onSetEndDateColumn: PropTypes.func,
  onSetEventTitleColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetStartDateColumn: setStartDateColumn,
  onSetEndDateColumn: setEndDateColumn,
  onSetEventTitleColumn: setEventTitleColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarOptionsSelector);
