// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setLegendPosition, setShowLegend, setShowLegendOpened } from '../../../actions';
import {
  getLegendPosition,
  getShowLegend,
  getShowLegendOpened,
  isPieChart
} from '../../../selectors/vifAuthoring';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.legends_and_flyouts';
const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

export class LegendsSelector extends Component {
  constructor(props) {
    super(props);

    const position = getLegendPosition(props.vifAuthoring) || POSITION_RIGHT;

    // The state position value just holds what should be displayed in the position dropdown.
    this.state = { position };
  }

  getShowLegend() {
    const { vifAuthoring } = this.props;
    const defaultValue = isPieChart(vifAuthoring); // pie charts default to showing the legend

    return getShowLegend(defaultValue)(vifAuthoring);
  }

  renderShowLegendCheckbox() {
    const { onSetShowLegend } = this.props;
    const checked = this.getShowLegend();

    const inputProps = {
      checked,
      id: 'show-legend',
      onChange: (event) => onSetShowLegend(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputProps} />
        <label className="inline-label" htmlFor="show-legend">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.show_legend.title', { scope })}
        </label>
      </div>
    );
  }

  renderShowLegendOpenedCheckbox({ disabled }) {
    const { onSetShowLegendOpened, vifAuthoring } = this.props;
    const checked = getShowLegendOpened(vifAuthoring);

    const inputProps = {
      checked,
      disabled,
      id: 'show-legend-open',
      onChange: (event) => onSetShowLegendOpened(event.target.checked),
      type: 'checkbox'
    };

    const containerProps = {
      className: classNames('checkbox', { disabled }),
      id: 'show-legends-opened-container'
    };

    return (
      <div {...containerProps}>
        <input {...inputProps} />
        <label className="inline-label" htmlFor="show-legend-open">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.display_open_by_default.title', { scope })}
        </label>
      </div>
    );
  }

  renderPositionDropdown({ disabled }) {
    const { onSetLegendPosition } = this.props;

    const options = [
      { title: I18n.t('fields.inline_legend.left', { scope }), value: POSITION_LEFT },
      { title: I18n.t('fields.inline_legend.right', { scope }), value: POSITION_RIGHT }
    ];

    const dropdownProps = {
      disabled,
      id: 'legend-position',
      onSelection: (option) => {
        onSetLegendPosition(option.value);
        this.setState({ position: option.value });
      },
      options,
      value: this.state.position
    };

    return (
      <div id="legend-position-container">
        <Dropdown {...dropdownProps} />
      </div>
    );
  }

  renderRadioButtons() {
    const { onSetLegendPosition, vifAuthoring } = this.props;
    const disabled = !this.getShowLegend();
    const isPositioned = !_.isNil(getLegendPosition(vifAuthoring));

    const containerProps = {
      className: classNames('authoring-field', { disabled })
    };

    const overlayInputProps = {
      checked: !isPositioned,
      disabled,
      id: 'legend-overlay',
      name: 'legend-position-radio',
      onChange: () => onSetLegendPosition(null),
      type: 'radio'
    };

    const inlineInputProps = {
      checked: isPositioned,
      disabled,
      id: 'legend-inline',
      name: 'legend-position-radio',
      onChange: () => onSetLegendPosition(this.state.position),
      type: 'radio'
    };

    return (
      <div {...containerProps}>
        <div id="overlay-legend-container" className="radiobutton">
          <input {...overlayInputProps} />
          <label htmlFor="legend-overlay">
            <span className="fake-radiobutton" />
          </label>
          {I18n.t('fields.overlay_legend.title', { scope })}
        </div>
        {this.renderShowLegendOpenedCheckbox({ disabled: isPositioned || disabled })}
        <div id="inline-legend-container" className="radiobutton">
          <input {...inlineInputProps} />
          <label htmlFor="legend-inline">
            <span className="fake-radiobutton" />
          </label>
          {I18n.t('fields.inline_legend.title', { scope })}
        </div>
        {this.renderPositionDropdown({ disabled: !isPositioned || disabled })}
      </div>
    );
  }

  render() {
    const radioButtons = isPieChart(this.props.vifAuthoring) ? null : this.renderRadioButtons();

    return (
      <div>
        {this.renderShowLegendCheckbox()}
        {radioButtons}
      </div>
    );
  }
}

LegendsSelector.propTypes = {
  onSetLegendPosition: PropTypes.func,
  onSetShowLegend: PropTypes.func,
  onSetShowLegendOpened: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetLegendPosition: setLegendPosition,
  onSetShowLegend: setShowLegend,
  onSetShowLegendOpened: setShowLegendOpened
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(LegendsSelector);
