import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button, { VARIANTS, SIZES } from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import SingleColumnSelector from './SingleColumnSelector';
import { FeatureFlags } from 'common/feature_flags';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const scope = 'shared.visualizations.panes.data.fields';

// Renders multiple rows of column dropdown and aggregation dropdown for selection.
// On add new, it renders only a column selector in a row and is called inProgressColumn selector.
// After selecting the column, the aggregation selector is shown defaulting to the first value as
// selected and onNew callback is called.
export class ColumnAndAggregationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewInProgressColumnAndAggregateSelector: this.props.showNewInProgressColumnAndAggregateSelector
    };
  }

  handleOnClickAddLink = () => {
    this.setState({ showNewInProgressColumnAndAggregateSelector: true });
  };

  handleOnSelectAggregation = (index, aggregationValue, columnAndAggregateValue) => {
    const { onUpdate } = this.props;
    const seriesIndex = _.get(columnAndAggregateValue, 'seriesIndex', index);

    onUpdate(index, {
      column: columnAndAggregateValue.column,
      seriesIndex,
      aggregation: aggregationValue
    });
  };

  handleOnSelectColumn = (index, columnValue, columnAndAggregateValue) => {
    const { onAdd, onUpdate } = this.props;
    if (_.isNil(columnAndAggregateValue)) {
      this.setState({ showNewInProgressColumnAndAggregateSelector: false });
      // Completed a in progress columnAndAggregateSelection
      onAdd(columnValue);
    } else {
      const seriesIndex = _.get(columnAndAggregateValue, 'seriesIndex', index);

      onUpdate(index, {
        column: columnValue,
        seriesIndex,
        aggregation: columnAndAggregateValue.aggregation
      });
    }
  };

  renderAddLink = () => {
    const { isFlyoutSeries } = this.props;
    const { showNewInProgressColumnAndAggregateSelector } = this.state;
    const buttonAttributes = {
      id: 'add-measure-link',
      disabled: showNewInProgressColumnAndAggregateSelector,
      onClick: () => this.handleOnClickAddLink(),
      variant: VARIANTS.LINK,
      size: SIZES.SMALL
    };
    const title = isFlyoutSeries ?
      I18n.t('flyout_measure.add_flyout_value', { scope }) :
      I18n.t('measure.add_measure', { scope });
    return (
      <div className="add-link-container">
        <Button {...buttonAttributes}>
          <SocrataIcon name="add" />
          {title}
        </Button>
      </div>
    );
  };

  renderAggregationSelector = (columnAndAggregateValue, index) => {
    const { aggregations, disableInProgressColumnSelector } = this.props;

    // Display no aggregation dropdown when no column name is selected or when
    // displayAggregation is false.
    const column = _.get(columnAndAggregateValue, 'column');
    const displayAggregation = _.get(columnAndAggregateValue, 'displayAggregation', true);

    if (_.isNil(column) || !displayAggregation) {
      return null;
    }

    const options = [
      ...aggregations.map(aggregation => ({ title: aggregation.title, value: aggregation.type })),
      { title: I18n.translate('shared.visualizations.aggregations.none'), value: null }
    ];

    const aggregationAttributes = {
      disabled: disableInProgressColumnSelector,
      id: `aggregation-selection-${index}`,
      label: I18n.t('measure.aggregation_label', { scope, number: index + 1 }),
      options,
      value: columnAndAggregateValue.aggregation,
      onSelection: (aggregation) => this.handleOnSelectAggregation(index, aggregation.value, columnAndAggregateValue)
    };

    return (
      <div className="secondary-dropdown-container">
        <Dropdown {...aggregationAttributes} />
      </div>
    );
  };

  renderColumnAndAggregationSelector = (columnAndAggregateValue, key) => {
    return (
      <li className="list-item" key={key}>
        {this.renderColumnSelector(columnAndAggregateValue, key)}
        {this.renderAggregationSelector(columnAndAggregateValue, key)}
        {this.renderDeleteLink(columnAndAggregateValue, key)}
      </li>
    );
  };

  renderColumnSelector = (columnAndAggregateValue, index) => {
    const { columns, disableInProgressColumnSelector, values, showAlreadySelectedColumns } = this.props;
    const columnValue = _.get(columnAndAggregateValue, 'column', null);
    const unavailableColumns = showAlreadySelectedColumns ? [] : _.map(values, 'column');
    const withCurrentColumnOptions = _.filter(columns, (column) => {
      return !_.includes(unavailableColumns, column.value) || columnValue === column.value;
    });

    let columnAttributes = {
      disabled: disableInProgressColumnSelector,
      id: `column-selection-${index}`,
      shouldRenderDeleteColumnLink: false,
      label: I18n.t('measure.column_label', { scope, number: index + 1 }),
      options: withCurrentColumnOptions,
      placeholder: I18n.t('measure.select_column', { scope }),
      onSelection: (column) => {
        this.handleOnSelectColumn(index, column.value, columnAndAggregateValue);
      }
    };

    if (!_.isNil(columnAndAggregateValue)) {
      columnAttributes = _.merge({ value: columnValue }, columnAttributes);
    }

    return (
      <div className="primary-dropdown-container">
        <SingleColumnSelector {...columnAttributes} />
      </div>
    );
  };

  renderInProgressColumnSelector = () => {
    const { values } = this.props;
    return (
      <li className="list-item in-progress-column" key={values.length}>
        {this.renderColumnSelector(null, values.length)}
      </li>
    );
  };

  renderDeleteLink = (columnAndAggregateValue, index) => {
    const { isFlyoutSeries, onDelete, shouldRenderDeleteLink } = this.props;
    const seriesIndex = isFlyoutSeries ? index : columnAndAggregateValue.seriesIndex;

    if (!shouldRenderDeleteLink && !isFlyoutSeries) {
      return null;
    }

    const attributes = {
      className: 'delete-link',
      id: `column-delete-link-${index}`,
      'aria-label': I18n.t('measure.delete_label', { scope, number: index + 1 }),
      onClick: () => onDelete(seriesIndex),
      variant: VARIANTS.LINK
    };

    return (
      <div className="delete-link-container">
        <ForgeIconButton slot="end" dense={true} densityLevel={6} className="delete-selected-column-button">
          <Button {...attributes}>
            <ForgeIcon name="clear" />
          </Button>
        </ForgeIconButton>
      </div>
    );
  };

  render() {
    const { shouldRenderAddLink, values } = this.props;
    const { showNewInProgressColumnAndAggregateSelector } = this.state;
    const columnAndAggregationSelectors = _.map(values, this.renderColumnAndAggregationSelector);
    const addLink = shouldRenderAddLink ?
      this.renderAddLink() :
      null;

    return (
      <div>
        <ul className="dropdowns-list column-aggregation-selector">
          {columnAndAggregationSelectors}
          {showNewInProgressColumnAndAggregateSelector && this.renderInProgressColumnSelector()}
        </ul>
        {addLink}
      </div>
    );
  }
}

ColumnAndAggregationSelector.propTypes = {
  aggregations: PropTypes.array,
  columns: PropTypes.array,
  disableInProgressColumnSelector: PropTypes.bool,
  isFlyoutSeries: PropTypes.bool,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  showAlreadySelectedColumns: PropTypes.bool,
  shouldRenderAddLink: PropTypes.bool,
  shouldRenderDeleteLink: PropTypes.bool,
  showNewInProgressColumnAndAggregateSelector: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      // Used to render the aggregation column.
      aggregation: PropTypes.string,
      // Used to render the column.
      column: PropTypes.any,
      // Used to determine if the aggregation dropdown should appear.
      displayAggregation: PropTypes.bool
    })
  )
};

ColumnAndAggregationSelector.defaultProps = {
  disableInProgressColumnSelector: false,
  isFlyoutSeries: true
};

export default ColumnAndAggregationSelector;
