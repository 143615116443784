import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ForgeButton } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { currentUserIsLoggedIn } from 'common/current_user';

import { save } from '../actions';
import { SaveStates } from '../lib/constants';

export class SaveButton extends PureComponent {
  render() {
    const { dark, isDirty, saveState, onClick } = this.props;
    const isSaving = saveState === SaveStates.SAVING;

    const disabled = !isDirty || !currentUserIsLoggedIn() || this.props.disabled || isSaving;

    const buttonProps = {
      busy: isSaving.toString(),
      className: 'btn-save',
      dark,
      disabled,
      onClick,
      variant: 'primary'
    };

    return (
      <ForgeButton type="outlined">
        <button data-testid="viz-can-aab-save" type="button" {...buttonProps}>{I18n.t('visualization_canvas.save')}</button>
      </ForgeButton>
    );
  }
}

SaveButton.defaultProps = {
  disabled: false,
  dark: 'true'
};

SaveButton.propTypes = {
  dark: PropTypes.oneOf(['true', 'false']),
  disabled: PropTypes.bool,
  isDirty: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  saveState: PropTypes.oneOf(_.values(SaveStates))
};

function mapStateToProps(state) {
  return _.pick(state, 'isDirty', 'saveState');
}

function mapDispatchToProps(dispatch, props) {
  return {
    onClick(e) {
      if (_.isFunction(props.onClick)) {
        props.onClick(e); // Override onClick
      } else {
        dispatch(save());
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton);
