import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { components as SocrataVisualizations } from 'common/visualizations';
import { getFilteredDataTableVif } from 'common/visualizations/VisualizationCommon';
import { connect } from 'react-redux';

export class Table extends Component {
  // if the VIF has not changed between renders, don't re-render!
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.vif, this.props.vif);
  }

  render() {
    const { vif } = this.props;

    return (
      <div className="table-contents">
        <SocrataVisualizations.Visualization vif={vif} />
      </div>
    );
  }
}

Table.propTypes = {
  vif: PropTypes.object.isRequired
};

function mapStateToProps({ parentView, parentViewRowLabel, filters }) {
  const tableVif = getFilteredDataTableVif(parentView?.id, parentViewRowLabel, filters);
  return {
    vif: tableVif
  };
}

export default connect(mapStateToProps)(Table);
