import React from 'react';
import _ from 'lodash';
import ForgeTab from './ForgeTab';
import { ForgeList, ForgeMiniDrawer } from '@tylertech/forge-react';

interface ICustomizationTab {
  id: string;
  title: string;
  icon: string;
}

interface ICustomizationTabsProps {
  tabs: ICustomizationTab[];
  selection: string;
  onTabNavigation: (tabId: string) => void;
}

export const CustomizationTabs = (props: ICustomizationTabsProps) => {
  const tabAttributes = (tab: ICustomizationTab) => {
    return {
      id: tab.id,
      key: tab.id,
      title: tab.title,
      icon: tab.icon,
      selected: props.selection === tab.id,
      onTabNavigation: props.onTabNavigation
    };
  };

  const renderTab = (tab: ICustomizationTab) => {
    return <ForgeTab {...tabAttributes(tab)} />;
  };

  return (
    <ForgeMiniDrawer direction="left" className="content-sidebar-tabs">
      <ForgeList>{_.map(props.tabs, renderTab)}</ForgeList>
    </ForgeMiniDrawer>
  );
};

export default CustomizationTabs;
