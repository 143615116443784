import { each, get, includes, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { formatDate } from 'common/dates';
import I18n from 'common/i18n';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import InfoPaneComponent from 'common/components/InfoPaneComponent';
import InfoPaneButtons from './InfoPaneButtons';
import { FOOTER } from 'common/components/InfoPane/constants';
import FeatureFlags from 'common/feature_flags';
import { isInternalToPublicFederatedSoQLBasedView } from 'common/views/view_types';
import { getVisualizationCanvasName } from '../selectors';
import { getPrimarySeries } from 'common/visualizations/helpers/VifSelectors';

export function mapStateToProps(state) {
  const { autolinkedDescription, isEphemeral, lastUpdatedAt, parentView, view, vifs } = state;
  const { hideDataSourceLink } = get(window, 'serverConfig.customConfigurations', {});

  // EN-68996: update this to use parentView.id and parentView.name once we do the db migration
  let parentViewId = parentView.id;
  let parentViewName = parentView.name;
  if (FeatureFlags.valueOrDefault('use_vif_dataset_uid_for_vizcan_filters', true)) {
    const primarySeries = getPrimarySeries(vifs);
    parentViewId = get(primarySeries, 'dataSource.datasetUid', parentView.id);
    parentViewName = get(primarySeries, 'dataSource.name', parentView.name);
  }

  const updatedAtString = isEphemeral
    ? I18n.t('visualization_canvas.info_pane.unsaved')
    : formatDate(lastUpdatedAt);
  const series = get(vifs[0], 'series', []);

  let dataSourceDetails = {
    location: FOOTER,
    primaryDataSource: {
      name: parentViewName, // EN-68996: update this to use parentView.name once we do the db migration
      url: `/d/${parentViewId}` // EN-68996: update this to use parentView.id once we do the db migration
    },
    otherDataSources: []
  };

  if (series.length > 1) {
    each(series, (seriesItem) => {
      const domain = get(seriesItem, 'dataSource.domain') || getDefaultDomain();
      const datasetUid = get(seriesItem, 'dataSource.datasetUid');
      const isPrimaryLayer = get(seriesItem, 'primary');
      const url = `https://${domain}/d/${datasetUid}`;
      const isNewGLMap = get(seriesItem, 'type') === 'map';
      const alreadyHasDatasetUid =
        includes(map(dataSourceDetails, 'otherDataSources'), datasetUid) || datasetUid === parentViewId; // EN-68996: update this to use parentView.id once we do the db migration

      // Only unified maps have multiple layers from different datasets.
      // All other chart types will be sourced by a single dataset.
      if (!isNewGLMap || isPrimaryLayer || alreadyHasDatasetUid) {
        return;
      }

      dataSourceDetails.otherDataSources.push({ url, domain, datasetUid });
    });
  }

  if (hideDataSourceLink === 'true' || isInternalToPublicFederatedSoQLBasedView(parentView)) {
    dataSourceDetails = {};
  }

  return {
    name: getVisualizationCanvasName(state),
    description: autolinkedDescription,
    provenance: get(view, 'provenance', null),
    category: (view || parentView).category,
    isPaneCollapsible: true,
    dataSourceDetails,
    metadata: {
      first: {
        label: I18n.t('visualization_canvas.info_pane.updated'),
        content: updatedAtString
      },
      second: {
        label: I18n.t('visualization_canvas.info_pane.view_count'),
        content: get(view, 'viewCount', 0)
      }
    },
    renderButtons() {
      return <InfoPaneButtons vifs={vifs} />;
    }
  };
}

export default connect(mapStateToProps)(InfoPaneComponent);
