import _ from 'lodash';

import * as actions from '../actions';

export var defaultState = [
  {
    isLoading: false,
    error: null,
    domain: null,
    datasetUid: null,
    data: null,
    isCuratedRegionsLoading: false,
    hasCuratedRegionsError: false,
    curatedRegions: null
  }
];

export default function metadataCollection(state, action) {
  if (_.isUndefined(state)) {
    return defaultState;
  }

  state = _.cloneDeep(state);

  switch (action.type) {
    // Return with defaultState on reset
    case actions.RESET_STATE:
      state = defaultState;
      break;

    case actions.REQUEST_METADATA:
      _.set(state, [action.seriesIndex, 'isLoading'], true);
      _.set(state, [action.seriesIndex, 'isUpdating'], action.updating);
      _.set(state, [action.seriesIndex, 'error'], null);
      _.set(state, [action.seriesIndex, 'domain'], action.domain);
      _.set(state, [action.seriesIndex, 'datasetUid'], action.datasetUid);
      _.set(state, [action.seriesIndex, 'data'], null);
      break;

    case actions.RECEIVE_METADATA:
      const seriesIndex = _.get(action, 'requestDetails.seriesIndex');
      const isResponseFromStaleRequest = (
        state[seriesIndex].datasetUid !== _.get(action, 'requestDetails.datasetUid') ||
        state[seriesIndex].domain !== _.get(action, 'requestDetails.domain')
      );

      // Background: In maps, each layer can be created out of different datasets. At any time in
      // the AX, we hold the metadata for the layer that is currently being edited. There is a AX
      // metadata refactoring story, that will clean up this limitation.
      // When I go into series 0 for editing, a metadata call lets say `request-0` starts
      // Immediately I go back and go into series 1 for editing, a metadata call for that series's
      //        dataset starts, lets say `request-1`.
      // If `request-1` completes first, it sets the metadata for the currently in-edit series/layer
      // When `request-0` completes later, it overwrites and sets the wrong metadata.
      //
      // This cases happens if the series-0 is a derived view and series-1 is a normal view.
      // Metadata call for derived views take more time
      if (!isResponseFromStaleRequest) {
        _.set(state, [seriesIndex, 'isLoading'], false);
        _.set(state, [seriesIndex, 'isUpdating'], false);
        _.set(state, [seriesIndex, 'error'], null);
        _.set(state, [seriesIndex, 'data'], action.datasetMetadata);
        _.set(state, [seriesIndex, 'hasColumnStats'], action.hasColumnStats);
      }
      break;

    case actions.HANDLE_METADATA_ERROR:
      _.set(state, [action.seriesIndex, 'isLoading'], false);
      _.set(state, [action.seriesIndex, 'isUpdating'], false);
      _.set(state, [action.seriesIndex, 'error'], action.error);
      _.set(state, [action.seriesIndex, 'domain'], null);
      _.set(state, [action.seriesIndex, 'datasetUid'], null);
      _.set(state, [action.seriesIndex, 'data'], null);
      break;

    case actions.REQUEST_CURATED_REGIONS:
      _.set(state, [action.seriesIndex, 'isCuratedRegionsLoading'], true);
      _.set(state, [action.seriesIndex, 'hasCuratedRegionsError'], false);
      _.set(state, [action.seriesIndex, 'curatedRegions'], null);
      break;

    case actions.RECEIVE_CURATED_REGIONS:
      _.set(state, [action.seriesIndex, 'isCuratedRegionsLoading'], false);
      _.set(state, [action.seriesIndex, 'hasCuratedRegionsError'], false);
      _.set(state, [action.seriesIndex, 'curatedRegions'], action.curatedRegions);
      break;

    case actions.REQUEST_COMPUTED_REGION_COLUMNS:
      _.set(state, [action.seriesIndex, 'isComputedRegionColumnsLoading'], true);
      _.set(state, [action.seriesIndex, 'hasComputedRegionColumnsError'], false);
      _.set(state, [action.seriesIndex, 'computedRegionColumns'], null);
      break;

    case actions.RECEIVE_COMPUTED_REGION_COLUMNS:
      _.set(state, [action.seriesIndex, 'isComputedRegionColumnsLoading'], false);
      _.set(state, [action.seriesIndex, 'hasComputedRegionColumnsError'], false);
      _.set(state, [action.seriesIndex, 'computedRegionColumns'], action.computedRegionColumns);
      break;

    case actions.REMOVE_METADATA_SERIES:
      state.splice(action.seriesIndex, 1);
      break;

    case actions.HANDLE_CURATED_REGIONS_ERROR:
      _.set(state, [action.seriesIndex, 'isCuratedRegionsLoading'], false);
      _.set(state, [action.seriesIndex, 'hasCuratedRegionsError'], true);
      _.set(state, [action.seriesIndex, 'curatedRegions'], null);
      break;

    case actions.SWAP_METADATA_COLLECTION_SERIES:
      [state[action.fromIndex], state[action.toIndex]] = [state[action.toIndex], state[action.fromIndex]];
      break;
  }

  return state;
}
