import { ForgeDecimalPrecision } from 'common/FormatColumn/DecimalPrecision';
import { ThousandsSeparator } from 'common/FormatColumn/ThousandsSeparator';
import {
  NUMBER_CURRENCY_PRECISION,
  DEFAULT_CURRENCY_FORMAT,
  AgFilterDataType,
  NUMBER_FINANCIAL_PRECISION
} from 'common/authoring_workflow/constants';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import { get, isEqual } from 'lodash';
import React from 'react';
import AgGridDisplayName from '../../AgGrid/AgGridDisplayName';
import AgGridCurrencySelector from '../../AgGridNew/AgGridCurrencySelector';
import AgGridDateFormat from '../../AgGridNew/AgGridDateFormat';
import AgGridNumberPrecisionStyle from '../../AgGridNew/AgGridNumberPrecisionStyle';
import { ColumnFormat, PrecisionStyle, ViewColumn } from 'common/types/viewColumn';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';

import I18n from 'common/i18n';

const scope = 'shared.visualizations.panes.presentation.fields.ag_updated_column_format';

interface DisplayOptionsProps {
  tableColumnFormat: TableColumnFormat;
  viewColumn: ViewColumn;
  onUpdateColumnFormat: (newColumnFormat: TableColumnFormat, columnName: string) => void;
}

const DisplayOptions = (props: DisplayOptionsProps) => {
  const { tableColumnFormat, viewColumn, onUpdateColumnFormat } = props;
  const displayName = get(tableColumnFormat, 'displayName', '');
  const format = get(tableColumnFormat, 'format', {});
  const renderType = get(viewColumn, 'dataTypeName', 'text');
  const isNumberDataType = isEqual(renderType, AgFilterDataType.NUMBER);
  const precisionStyle = get(format, 'precisionStyle', '');
  const accordionTitle = I18n.t('display_options.title', { scope });

  const onDisplayNameChange = (column: string) => {
    onUpdateColumnFormat({ ...tableColumnFormat, displayName: column }, viewColumn.fieldName);
  };

  const onPrecisionStyleChange = (newPrecisionStyle: PrecisionStyle) => {
    const newPrecision = [NUMBER_CURRENCY_PRECISION, NUMBER_FINANCIAL_PRECISION].includes(newPrecisionStyle)
      ? 2
      : undefined;
    const newFormat: ColumnFormat = {
      ...format,
      precisionStyle: newPrecisionStyle,
      precision: newPrecision,
      percentScale: '1'
    };
    onUpdateColumnFormat({ ...tableColumnFormat, format: newFormat }, viewColumn.fieldName);
  };

  const onCurrencyStyleChange = (newCurrencyStyle: string) => {
    const newFormat = {
      ...format,
      currencyStyle: newCurrencyStyle
    };
    onUpdateColumnFormat({ ...tableColumnFormat, format: newFormat }, viewColumn.fieldName);
  };

  const handleThousandsSeparatorChange = (newFormat: ColumnFormat) => {
    onUpdateColumnFormat({ ...tableColumnFormat, format: newFormat }, viewColumn.fieldName);
  };

  const handleUpdateDecimalPrecision = (newFormat: ColumnFormat) => {
    const updatedFormat = {
      ...tableColumnFormat,
      format: newFormat
    };
    onUpdateColumnFormat(updatedFormat, viewColumn.fieldName);
  };

  const onDateFormatChange = (newFormat: ColumnFormat) => {
    onUpdateColumnFormat({ ...tableColumnFormat, format: newFormat }, viewColumn.fieldName);
  };

  return (
    <div className="control-wrapper">
      <AccordionContainer>
        <AccordionPane key="displayOptions" title={accordionTitle}>
          <AgGridDisplayName
            displayName={displayName}
            onDisplayNameChange={(column: string) => onDisplayNameChange(column)}
          />
          {isNumberDataType && (
            <div className="ag-grid-numeric-format-container">
              <AgGridNumberPrecisionStyle
                precisionStyle={precisionStyle}
                onPrecisionStyleChange={onPrecisionStyleChange}
              />
              {precisionStyle === NUMBER_CURRENCY_PRECISION && (
                <AgGridCurrencySelector
                  currency={get(format, 'currencyStyle', DEFAULT_CURRENCY_FORMAT)}
                  onCurrencyStyleChange={onCurrencyStyleChange}
                />
              )}
              <ForgeDecimalPrecision onChange={handleUpdateDecimalPrecision} format={format} hideArrows />
              <ThousandsSeparator format={format} onChange={handleThousandsSeparatorChange} />
            </div>
          )}
          <AgGridDateFormat format={format} dataType={renderType} onDateFormatChange={onDateFormatChange} />
        </AccordionPane>
      </AccordionContainer>
    </div>
  );
};

export default DisplayOptions;
