// Vendor Imports
import _ from 'lodash';
import React from 'react';

// Project Imports
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { SoQLType } from 'common/types/soql';
import Button, { VARIANTS } from 'common/components/Button';

const scope = 'shared.visualizations.panes.legends_and_flyouts.fields.additional_flyout_values';

const DeleteColumnLink: React.FC<{
  [prop: string]: any;
}> = (props) => {
  return (
    <div className="delete-link-container" aria-label={I18n.translate('remove_flyout_value', { scope })}>
      <Button className="delete-link" variant={VARIANTS.LINK} {...props}>
        <span className="socrata-icon-close" role="presentation" />
      </Button>
    </div>
  );
};

interface DropdownOption {
  title: string;
  value: string;
  type: SoQLType;
  render: (option: { type: string; title: string }) => JSX.Element;
}

interface Props {
  /** Force disable the selector even if there are options */
  disabled?: boolean;
  /** DOM ID to assign to the dropdown */
  id?: string;
  /** Accessibility label */
  label: string;
  /** Called when the user clicks the Delete link */
  onDelete?: (index: number) => void;
  /** Called when the user makes a selection */
  onSelection: (option: DropdownOption, index: number) => void;
  /** Options to select from, in the format expected by Dropdown/Picklist */
  options: DropdownOption[];
  /** String to display when no column is selected */
  placeholder?: string;
  /** Used to differentiate multiple column selectors, e.g. when selecting for a hierarchy */
  relativeIndex?: number;
  /** Column names that have been selected in other selectors, and thus shouldn't be shown in this one */
  selectedValues?: string[];
  /** Allow user to request deletion of this column selector */
  shouldRenderDeleteColumnLink: boolean;
  /** If undefined, will render a "pending" column UI, i.e. with no column selected */
  value?: string;
}

const SingleColumnSelector: React.FC<Props> = (props) => {
  const {
    relativeIndex = 0,
    id = `column-selection-${relativeIndex}`,
    disabled = false,
    options,
    value,
    label,
    shouldRenderDeleteColumnLink,
    selectedValues = [],
    placeholder = I18n.translate('select_column', { scope }),
    onSelection,
    onDelete,
  } = props;

  const withCurrentColumnOptions = _.filter(options, (option) => {
    return !_.includes(selectedValues, option.value) || value === option.value;
  });

  let deleteColumnLink = null;
  if (onDelete && shouldRenderDeleteColumnLink) {
    deleteColumnLink = (
      <DeleteColumnLink
        id={`column-delete-link-${relativeIndex}`}
        onClick={() => {
          onDelete(relativeIndex);
        }}
      />
    );
  }

  return (
    <>
      <div className="primary-dropdown-container">
        <Dropdown
          id={id}
          disabled={disabled || options.length === 0}
          label={label}
          onSelection={(option: any) => {
            onSelection(option, relativeIndex);
          }}
          options={withCurrentColumnOptions}
          value={value}
          placeholder={placeholder}
        />
      </div>
      {deleteColumnLink}
    </>
  );
};

export default SingleColumnSelector;
