// Vendor Imports
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import I18n from 'common/i18n';
import DebouncedInput from '../../shared/DebouncedInput';

// Constants
import {
  MEASURE_AXIS_SCALE_CUSTOM,
  MEASURE_AXIS_SCALE_MIN_TO_MAX,
  MEASURE_AXIS_SCALE_ZERO_TO_MAX
} from '../../../constants';

export class AxisScaleSelector extends Component {
  renderRadioButton({ checked, className, disabled, id, label, onChange, value }) {
    const inputProps = {
      checked,
      disabled,
      id,
      onChange,
      name: 'measure-axis-scale',
      type: 'radio',
      value
    };

    return (
      <div className={className}>
        <input {...inputProps} />
        <label htmlFor={id}>
          <span className="fake-radiobutton" />
          <div className="translation-within-label">{label}</div>
        </label>
      </div>
    );
  }

  renderTextboxes() {
    const {
      disabled,
      maxLimit,
      minLimit,
      onMaxValueTextboxChange,
      onMinValueTextboxChange
    } = this.props;

    const maxValueTextbox = this.renderTextbox({
      disabled,
      id: 'measure-axis-scale-custom-max',
      label: I18n.t('shared.visualizations.panes.axis.fields.scale.maximum'),
      onChange: onMaxValueTextboxChange,
      value: maxLimit
    });

    const minValueTextbox = this.renderTextbox({
      disabled,
      id: 'measure-axis-scale-custom-min',
      label: I18n.t('shared.visualizations.panes.axis.fields.scale.minimum'),
      onChange: onMinValueTextboxChange,
      value: minLimit
    });

    return (
      <div className="double-column-input-group">
        {minValueTextbox}
        {maxValueTextbox}
      </div>
    );
  }

  renderTextbox({ disabled, id, label, onChange, value }) {
    const labelAttributes = {
      className: 'block-label',
      htmlFor: 'id'
    };

    const inputAttributes = {
      className: 'text-input',
      disabled,
      id,
      onChange,
      type: 'number',
      value
    };

    return (
      <div>
        <label {...labelAttributes}>{label}</label>
        <DebouncedInput {...inputAttributes} />
      </div>
    );
  }

  render() {
    const {
      axisScale,
      disabled,
      disableScaleMinToMaxValue,
      onRadioButtonChange
    } = this.props;

    const className = classNames('authoring-field radiobutton', {
      disabled
    });

    const zeroToMaxScaleRadioButton = this.renderRadioButton({
      checked: (axisScale === MEASURE_AXIS_SCALE_ZERO_TO_MAX),
      className: 'measure-axis-scale-zero-to-max-container',
      disabled,
      id: 'measure-axis-scale-zero-to-max',
      label: I18n.t('shared.visualizations.panes.axis.fields.scale.scale_to_max_value'),
      onChange: onRadioButtonChange,
      value: MEASURE_AXIS_SCALE_ZERO_TO_MAX
    });

    const minToMaxScaleRadioButton = this.renderRadioButton({
      checked: (axisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX),
      className: classNames('measure-axis-scale-min-to-max-container', { disabled: disableScaleMinToMaxValue }),
      disabled: disabled || disableScaleMinToMaxValue,
      id: 'measure-axis-scale-min-to-max',
      label: I18n.t('shared.visualizations.panes.axis.fields.scale.scale_to_min_and_max_values'),
      onChange: onRadioButtonChange,
      value: MEASURE_AXIS_SCALE_MIN_TO_MAX
    });

    const customScaleRadioButton = this.renderRadioButton({
      checked: (axisScale === MEASURE_AXIS_SCALE_CUSTOM),
      className: 'measure-axis-scale-custom-container',
      disabled,
      id: 'measure-axis-scale-custom',
      label: I18n.t('shared.visualizations.panes.axis.fields.scale.custom'),
      onChange: onRadioButtonChange,
      value: MEASURE_AXIS_SCALE_CUSTOM
    });

    const textboxes = (axisScale === MEASURE_AXIS_SCALE_CUSTOM) ? this.renderTextboxes() : null;

    return (
      <div>
        <div className={className}>
          {zeroToMaxScaleRadioButton}
          {minToMaxScaleRadioButton}
          {customScaleRadioButton}
        </div>
        {textboxes}
      </div>
    );
  }
}

AxisScaleSelector.propTypes = {
  axisScale: PropTypes.string,
  disabled: PropTypes.bool,
  disableScaleMinToMaxValue: PropTypes.bool,
  maxLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onMaxValueTextboxChange: PropTypes.func,
  onMinValueTextboxChange: PropTypes.func,
  onRadioButtonChange: PropTypes.func
};

export default AxisScaleSelector;
