// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setColorByQuantificationMethod,
  setColorLinesByColumn,
  setWeighLinesByColumn
} from 'common/authoring_workflow/actions';
import { NULL_COLUMN_NAME } from 'common/authoring_workflow/constants';
import {
  getCurrentMetadata,
  getNonGeoLocationColumns,
  getNumericalColumns
} from 'common/authoring_workflow/selectors/metadata';
import {
  getColorByQuantificationMethod,
  getColorLinesByColumn,
  getMapType,
  getWeighLinesByColumn
} from 'common/authoring_workflow/selectors/vifAuthoring';

import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import AutocompleteColumnSelector from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';
import { normalizeColorByQuantificationMethod } from 'common/authoring_workflow/helpers';

export class LineMapOptionsSelector extends Component {
  renderLineMapColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  };

  renderLineMapOptionsDropdown = (dropdownId, optionsData, onSelection, defaultValue) => {
    const { translationScope, vifAuthoring, onSetColorByQuantificationMethod } = this.props;
    const columnOptions = _.map(optionsData, (column) => ({
      title: column.name,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderLineMapColumnOption
    }));
    const options = [
      {
        title: I18n.t('no_value', { scope: translationScope }),
        value: NULL_COLUMN_NAME
      },
      ...columnOptions
    ];
    const hasOnlyDefaultValue = options.length <= 1;

    return {
      disabled: hasOnlyDefaultValue,
      id: dropdownId,
      placeholder: I18n.t('no_value', { scope: translationScope }),
      options,
      onSelection: (option, index) => {
        const quantificationMethod = normalizeColorByQuantificationMethod(
          getColorLinesByColumn(vifAuthoring),
          getColorByQuantificationMethod(vifAuthoring),
          option.type
        );

        onSetColorByQuantificationMethod(quantificationMethod);

        if (option.value === NULL_COLUMN_NAME) {
          option.value = null;
        }
        onSelection(option, index);
      },
      // Forge Autocomplete does not call onSelect if option.value = null.
      // We pass it as a string so that the user can select the option.
      value: defaultValue === null ? NULL_COLUMN_NAME : defaultValue
    };
  };

  renderLineWeightSelector = () => {
    const { metadata, onSetWeighLinesByColumn, translationScope, vifAuthoring } = this.props;
    const numericalColumnAttributes = this.renderLineMapOptionsDropdown(
      'line-weight-by-value-dropdown',
      getNumericalColumns(metadata),
      (option) => onSetWeighLinesByColumn(option.value),
      getWeighLinesByColumn(vifAuthoring)
    );

    return (
      <div className="authoring-field" id="weigh-lines-by-value-selection">
        <label className="block-label" htmlFor="base-layer">
          {I18n.t('line_weight_by_value', { scope: translationScope })}
        </label>
        <div className="base-layer-dropdown-container">
          <AutocompleteColumnSelector {...numericalColumnAttributes} />
        </div>
      </div>
    );
  };

  renderLineColorSelector = () => {
    const { metadata, onSetColorLinesByColumn, translationScope, vifAuthoring } = this.props;
    const allColumnAttributes = this.renderLineMapOptionsDropdown(
      'line-color-by-value-dropdown',
      getNonGeoLocationColumns(metadata),
      (option) => {
        onSetColorLinesByColumn(option.value);
      },
      getColorLinesByColumn(vifAuthoring)
    );

    return (
      <div className="authoring-field" id="color-lines-by-value-selection">
        <label className="block-label" htmlFor="base-layer">
          {I18n.t('line_color_by_value', { scope: translationScope })}
        </label>
        <div className="base-layer-dropdown-container">
          <AutocompleteColumnSelector {...allColumnAttributes} />
        </div>
      </div>
    );
  };

  render() {
    const { vifAuthoring } = this.props;
    const isLineMap = _.isEqual(getMapType(vifAuthoring), 'lineMap');

    return isLineMap ? (
      <div>
        {this.renderLineWeightSelector()}
        {this.renderLineColorSelector()}
      </div>
    ) : null;
  }
}

LineMapOptionsSelector.defaultProps = {
  translationScope: 'shared.visualizations.panes.map_layers.fields.line_map_options'
};

LineMapOptionsSelector.propTypes = {
  metadata: PropTypes.object,
  onSetColorByQuantificationMethod: PropTypes.func,
  onSetColorLinesByColumn: PropTypes.func,
  onSetWeighLinesByColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorByQuantificationMethod: setColorByQuantificationMethod,
  onSetColorLinesByColumn: setColorLinesByColumn,
  onSetWeighLinesByColumn: setWeighLinesByColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(LineMapOptionsSelector);
