import _ from 'lodash';
import { checkStatus, defaultHeaders } from 'common/http';
import { removeTransientStateFromVif } from 'common/visualizations/helpers/VifHelpers';

import {
  getVisualizationCanvasName,
  getVisualizationCanvasDescription
} from './selectors';

export const GUIDANCE_LOADED = 'GUIDANCE_LOADED';
export const guidanceLoaded = (guidance) => ({
  type: GUIDANCE_LOADED,
  guidance
});

export const ADD_VISUALIZATION = 'ADD_VISUALIZATION';
export const addVisualization = () => ({
  type: ADD_VISUALIZATION
});

export const EDIT_VISUALIZATION = 'EDIT_VISUALIZATION';
export const editVisualization = (data) => ({
  type: EDIT_VISUALIZATION,
  data
});

export const CANCEL_EDITING_VISUALIZATION = 'CANCEL_EDITING_VISUALIZATION';
export const cancelEditingVisualization = () => ({
  type: CANCEL_EDITING_VISUALIZATION
});

export const UPDATE_VISUALIZATION = 'UPDATE_VISUALIZATION';
export const updateVisualization = (data) =>
  (dispatch, getState) => {
    dispatch({
      type: UPDATE_VISUALIZATION,
      data
    });
    if (!getState().isEphemeral) {
      // eslint-disable-next-line no-use-before-define
      save()(dispatch, getState); // Save a draft immediately when a user inserts a viz.
    }
  };

export const SET_MAP_CENTER_AND_ZOOM = 'SET_MAP_CENTER_AND_ZOOM';
export const setMapCenterAndZoom = (data) => ({
  type: SET_MAP_CENTER_AND_ZOOM,
  data
});

export const SET_CURRENT_DRILL_DOWN_COLUMN_NAME = 'SET_CURRENT_DRILL_DOWN_COLUMN_NAME';
export const setCurrentDrilldownColumnName = (currentDrilldownDimensionColumnName) => ({
  type: SET_CURRENT_DRILL_DOWN_COLUMN_NAME,
  currentDrilldownDimensionColumnName
});

export const SET_CURRENT_DISPLAY_DATE = 'SET_CURRENT_DISPLAY_DATE';
export function setCurrentDisplayDate(currentDisplayDate) {
  return {
    type: SET_CURRENT_DISPLAY_DATE,
    currentDisplayDate
  };
}

export const SET_MAP_PITCH_AND_BEARING = 'SET_MAP_PITCH_AND_BEARING';
export const setMapPitchAndBearing = (data) => ({
  type: SET_MAP_PITCH_AND_BEARING,
  data
});

export const SET_MAP_LAYER_VISIBLE = 'SET_MAP_LAYER_VISIBLE';
export const setMapLayerVisible = (data) => ({
  type: SET_MAP_LAYER_VISIBLE,
  data
});

export const SET_MAP_NOTIFICATION_DISMISSED = 'SET_MAP_NOTIFICATION_DISMISSED';
export const setMapNotificationDismissed = (data) => ({
  type: SET_MAP_NOTIFICATION_DISMISSED,
  data
});

export const ENTER_PREVIEW_MODE = 'ENTER_PREVIEW_MODE';
export const enterPreviewMode = () => ({
  type: ENTER_PREVIEW_MODE
});

export const ENTER_EDIT_MODE = 'ENTER_EDIT_MODE';
export const enterEditMode = () => ({
  type: ENTER_EDIT_MODE
});

export const UPDATE_NAME = 'UPDATE_NAME';
export const updateName = (data) => ({
  type: UPDATE_NAME,
  data
});

export const SET_FILTERS = 'SET_FILTERS';
export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters
});

export const CLEAR_SAVE_STATE = 'CLEAR_SAVE_STATE';
export const clearSaveState = (debounce) => ({
  type: CLEAR_SAVE_STATE,
  meta: { debounce }
});

export const REQUESTED_SAVE = 'REQUESTED_SAVE';
export const requestedSave = () => ({
  type: REQUESTED_SAVE
});

export const HANDLE_SAVE_SUCCESS = 'HANDLE_SAVE_SUCCESS';
export const handleSaveSuccess = (response) => ({
  type: HANDLE_SAVE_SUCCESS,
  response
});

export const HANDLE_SAVE_ERROR = 'HANDLE_SAVE_ERROR';
export const handleSaveError = (error) => ({
  type: HANDLE_SAVE_ERROR,
  error
});

/*
 * Opens the share modal.
 *
 * Expected payload:
 * {
 *   vifIndex (int): Index of viz being shared.
 * }
 */
export const OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL';
export const openShareModal = (data) => ({
  type: OPEN_SHARE_MODAL,
  data
});

/**
 * Closes the share modal.
 */
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';
export const closeShareModal = () => ({
  type: CLOSE_SHARE_MODAL
});

/**
 * Sets embed size of the visualization being shared.
 *
 * Expected parameter:
 * size (String): Size name. See ShareVisualizationModal for set of sizes allowed.
 */
export const SET_EMBED_SIZE = 'SET_EMBED_SIZE';
export const setEmbedSize = (size) => ({
  type: SET_EMBED_SIZE,
  size
});

/**
 * Closes the signin modal.
 */
export const CLOSE_SIGNIN_MODAL = 'CLOSE_SIGNIN_MODAL';
export const closeSigninModal = () => ({
  type: CLOSE_SIGNIN_MODAL
});

/**
 * Redirects the user to sign in
 */
export const SIGNIN_FROM_MODAL = 'SIGNIN_FROM_MODAL';
export const signin = () => ({
  type: SIGNIN_FROM_MODAL
});

/**
 * Copying this vizcan from A2B failed.
 */
export const COPY_FAILED = 'COPY_FAILED';
export const copyFailed = () => ({
  type: COPY_FAILED
});

/**
 * Dismisses alert box
 */
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const dismissAlert = () => ({
  type: DISMISS_ALERT
});

export const save = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { isEphemeral } = state;
    const payload = _.pick(state, 'parentView', 'vifs', 'filters');

    const newVifs = _.map(payload.vifs, removeTransientStateFromVif);
    payload.vifs = newVifs;

    payload.name = getVisualizationCanvasName(state);
    payload.description = getVisualizationCanvasDescription(state);

    const path = isEphemeral ? '' : `/${state.view.id}`;
    const url = `/visualization_canvas${path}`;
    const fetchOptions = {
      method: isEphemeral ? 'POST' : 'PUT',
      headers: defaultHeaders,
      credentials: 'same-origin',
      body: JSON.stringify(payload)
    };

    const parseJSON = response => response.json();

    const onSuccess = _.flowRight(dispatch, handleSaveSuccess);
    const onError = _.flowRight(dispatch, handleSaveError);
    const clearSave = _.flowRight(dispatch, clearSaveState);

    // Cancel any existing clear save timer and show the spinner
    dispatch(clearSaveState({ cancel: true }));
    dispatch(requestedSave());

    // Save the page, update the store, clear the save status after a timer
    fetch(url, fetchOptions).
      then(checkStatus).
      then(parseJSON).
      then(onSuccess).
      catch(onError).
      then(_.partial(clearSave, { time: 3000 }));
  };
};
