import React from 'react';
import { ForgeButtonToggleGroup, ForgeIcon, ForgeButtonToggle } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';

import '../AgGridColumnFormatting/index.scss';

// Constants
const scope = 'shared.dataset_management_ui.format_column';
const alignmentOptions = [
  { value: 'left', title: I18n.t('left', { scope }), iconName: 'format_align_left' },
  { value: 'center', title: I18n.t('center', { scope }), iconName: 'format_align_center' },
  { value: 'right', title: I18n.t('right', { scope }), iconName: 'format_align_right' }
];

interface AlignmentOptionProps {
  value: string;
  title: string;
  iconName: string;
}

export interface AlignmentProps {
  align: string;
  onAlignmentChange: (newAlignment: string) => void;
}

const AgGridAlignment = (props: AlignmentProps) => {
  const { align, onAlignmentChange } = props;
  const alignmentLabel = I18n.t('alignment', { scope });

  const onAlignmentButtonClick = (event: CustomEvent) => {
    onAlignmentChange(event.detail);
  };

  const renderAlignmentOption = ({ value, title, iconName }: AlignmentOptionProps) => {
    return (
      <ForgeButtonToggle value={value} aria-label={title} key={value}>
        <ForgeIcon name={iconName} className={'dense-icon'} />
      </ForgeButtonToggle>
    );
  };

  return (
    <div className="ag-grid-alignment-container authoring-field">
      <BlockLabel htmlFor="ag-grid-alignment" title={alignmentLabel} description={''} />
      <ForgeButtonToggleGroup
        value={align}
        on-forge-button-toggle-group-change={onAlignmentButtonClick}
        className="ag-grid-alignment-group-button"
        dense
      >
        {alignmentOptions.map(renderAlignmentOption)}
      </ForgeButtonToggleGroup>
    </div>
  );
};

export default AgGridAlignment;
