// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setMeasureAxisMaxValue,
  setMeasureAxisMinValue,
  setMeasureAxisScale
} from '../../../actions';
import {
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getMeasureAxisScale,
  isOneHundredPercentStacked,
  isTimelineChart
} from '../../../selectors/vifAuthoring';
import AxisScaleSelector from './AxisScaleSelector';

// Constants
import {
  MEASURE_AXIS_SCALE_CUSTOM,
  MEASURE_AXIS_SCALE_MIN_TO_MAX,
  MEASURE_AXIS_SCALE_ZERO_TO_MAX
} from '../../../constants';

export class AxisScaleSelectorSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      axisScale: this.getAxisScale(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.axisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
      this.setState({
        axisScale: this.getAxisScale(nextProps)
      });
    }
  }

  onRadioButtonChange(event) {
    const {
      onSetMeasureAxisScale,
      onSetMeasureAxisMaxValue,
      onSetMeasureAxisMinValue
    } = this.props;

    const axisScale = event.target.value;
    this.setState({
      axisScale
    });

    switch (axisScale) {
      case MEASURE_AXIS_SCALE_ZERO_TO_MAX:
        onSetMeasureAxisScale();
        onSetMeasureAxisMaxValue();
        onSetMeasureAxisMinValue();
        break;
      case MEASURE_AXIS_SCALE_MIN_TO_MAX:
        onSetMeasureAxisScale(MEASURE_AXIS_SCALE_MIN_TO_MAX);
        onSetMeasureAxisMaxValue();
        onSetMeasureAxisMinValue();
        break;
    }
  }

  getAxisScale(props) {
    const { vifAuthoring } = props;
    const maxValue = getMeasureAxisMaxValue(vifAuthoring);
    const minValue = getMeasureAxisMinValue(vifAuthoring);

    if (!_.isNil(maxValue) || !_.isNil(minValue)) {
      return MEASURE_AXIS_SCALE_CUSTOM;
    } else if (getMeasureAxisScale(vifAuthoring) === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
      return MEASURE_AXIS_SCALE_MIN_TO_MAX;
    } else {
      return MEASURE_AXIS_SCALE_ZERO_TO_MAX;
    }
  }

  render() {
    const {
      onSetMeasureAxisMaxValue,
      onSetMeasureAxisMinValue,
      onSetMeasureAxisScale,
      vifAuthoring
    } = this.props;

    const selectorAttributes = {
      axisScale: this.state.axisScale,
      disabled: isOneHundredPercentStacked(vifAuthoring),
      disableScaleMinToMaxValue: !isTimelineChart(vifAuthoring),
      key: 0,
      maxLimit: getMeasureAxisMaxValue(vifAuthoring) || '',
      minLimit: getMeasureAxisMinValue(vifAuthoring) || '',
      onMaxValueTextboxChange: (event) => {
        onSetMeasureAxisMaxValue(event.target.value);
        onSetMeasureAxisScale();
      },
      onMinValueTextboxChange: (event) => {
        onSetMeasureAxisMinValue(event.target.value);
        onSetMeasureAxisScale();
      },
      onRadioButtonChange: (event) => this.onRadioButtonChange(event)
    };

    return (
      <AxisScaleSelector {...selectorAttributes} />
    );
  }
}

AxisScaleSelectorSingle.propTypes = {
  metadata: PropTypes.object,
  onSetMeasureAxisMaxValue: PropTypes.func,
  onSetMeasureAxisMinValue: PropTypes.func,
  onSetMeasureAxisScale: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetMeasureAxisMaxValue: setMeasureAxisMaxValue,
  onSetMeasureAxisMinValue: setMeasureAxisMinValue,
  onSetMeasureAxisScale: setMeasureAxisScale
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(AxisScaleSelectorSingle);
