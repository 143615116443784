// Vendor Imports
import React from 'react';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';

import { Input } from 'common/components/Forms';


// Constants
const scope = 'shared.visualizations.panes.presentation';
export interface AgGridDisplayNameProps {
  displayName: string;
  onDisplayNameChange: (column: string) => void;
}

const AgGridDisplayName = (props: AgGridDisplayNameProps): JSX.Element => {
  const { displayName, onDisplayNameChange } = props;

  const displayNameLabel = I18n.t('fields.ag_column_display_name.title', { scope });

  const renderDisplayNameTextBox = () => {
    const inputAttributes = {
      type: 'text',
      value: displayName,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => onDisplayNameChange(event.target.value),
    };
    return (
      <div className="display-name-container">
        <BlockLabel
          htmlFor="display-name"
          title={displayNameLabel}
          description={''}
        />
        <Input  {...inputAttributes} />
      </div>
    );
  };
  return (
    <div>
      {renderDisplayNameTextBox()}
    </div>
  );
};

export default AgGridDisplayName;
