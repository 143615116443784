// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setUserActive, setUserIdle } from '../../actions';
import { getInputDebounceMs } from '../../constants';
import { isUserCurrentlyActive } from '../../selectors/vifAuthoring';

export class DebouncedTextArea extends React.Component {
  constructor(props) {
    super(props);

    if (_.isUndefined(props.value)) {
      throw new Error('DebouncedTextArea needs "value" property to be set.');
    }

    this.state = {
      value: props.value || ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.timeoutId = null;
  }

  handleChange(event) {
    event.persist();
    this.setState({ value: event.target.value });

    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    if (!isUserCurrentlyActive(this.props.vifAuthoring)) {
      this.props.onSetUserActive();
    }

    this.timeoutId = setTimeout(() => {
      this.props.onSetUserIdle();
      return this.props.onChange(event);
    }, getInputDebounceMs());
  }

  render() {
    const props = _.omit(this.props, ['onSetUserActive', 'onSetUserIdle', 'vifAuthoring']);
    return <textarea {...props} value={this.state.value} onChange={this.handleChange} />;
  }
}

DebouncedTextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSetUserActive: PropTypes.func,
  onSetUserIdle: PropTypes.func,
  value: PropTypes.any
};

const mapDispatchToProps = {
  onSetUserActive: setUserActive,
  onSetUserIdle: setUserIdle
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(DebouncedTextArea);
