// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setBasemapOpacity,
  setBasemapStyle,
  setClusterRadius,
  setDescription,
  setGeoCoderControl,
  setGeoLocateControl,
  setLayerToggleControl,
  setMapFlyoutPrecision,
  setMapLegendPrecision,
  setMapZoomLevel,
  setMaxClusteringZoomLevel,
  setMaxClusterSize,
  setNavigationControl,
  setShowLegendForMap,
  setShowLegendOpened,
  setShowMultiplePointsSymbolInLegend,
  setStackRadius,
  setTitle,
  setViewSourceDataLink
} from '../../../actions';
import {
  BASE_LAYERS,
  BASEMAP_STYLES,
  DEFAULT_ARTICLE_SUPPORT_LINK,
  DEFAULT_ZOOM_LEVEL_ROUND_OFF_VALUE,
  getMapSliderDebounceMs,
  VIF_CONSTANTS
} from '../../../constants';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedInput from '../../shared/DebouncedInput';
import DebouncedSlider from '../../shared/DebouncedSlider';
import DebouncedTextArea from '../../shared/DebouncedTextArea';
import ShowDataTableControl from '../../shared/ShowDataTableControl';
import PrecisionSelector from '../../shared/PrecisionSelector';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import BlockLabel from 'common/components/BlockLabel';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';

// Constants
const scope = 'shared.visualizations.panes.basemap';

export class BasemapPane extends Component {
  renderBasemapControls = () => {
    const { vifAuthoring } = this.props;
    let controls = [
      this.renderBasemapStyleSelector(),
      this.renderGeneralOptions(),
      this.renderMapControls(),
      this.renderLegends(),
      this.renderZoomLevelOptions()
    ];
    const hasAtleastOnePointMapSeries = selectors.getPointMapSeries(vifAuthoring).length > 0;

    // Show geocode boundary controls only if geocoding is enabled.
    if (selectors.getGeoCoderControl(vifAuthoring)) {
      controls.push(this.renderSearchBoundaryFields());
    }

    if (hasAtleastOnePointMapSeries) {
      controls.push(this.renderClusterControls());
    }

    return controls;
  };

  renderBasemapStyleSelector = () => {
    const { onSetBasemapOpacity, onSetBasemapStyle, vifAuthoring } = this.props;
    const defaultBasemapStyle = selectors.getBasemapStyle(vifAuthoring);
    const defaultBasemapOpacity = selectors.getBasemapOpacity(vifAuthoring);
    const basemapStyleAttributes = {
      id: 'basemap-style',
      onSelection: (option) => onSetBasemapStyle(option.value),
      options: _.map(BASEMAP_STYLES, mapLayer => ({
        title: mapLayer.title,
        value: mapLayer.value
      })),
      value: defaultBasemapStyle
    };
    const disabled = _.findIndex(BASE_LAYERS, ['value', defaultBasemapStyle]) === -1;
    const basemapOpacityFieldClasses = classNames('authoring-field basemap-opacity-container', { disabled });
    const basemapOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      disabled,
      id: 'basemap-opacity',
      onChange: onSetBasemapOpacity,
      rangeMax: 1,
      rangeMin: 0,
      step: 0.1,
      value: defaultBasemapOpacity
    };

    return (
      <AccordionPane key="basemapStyles" title={I18n.t('subheaders.basemap', { scope })}>
        <div className="authoring-field">
          <label className="block-label" htmlFor="basemap-style">
            {I18n.t('fields.basemap_style.title', { scope })}
          </label>
          <div className="basemap-style-dropdown-container">
            <Dropdown {...basemapStyleAttributes} />
          </div>
        </div>

        <div className={basemapOpacityFieldClasses}>
          <label className="block-label" htmlFor="basemap-opacity">
            {I18n.t('fields.basemap_opacity.title', { scope })}
          </label>
          <div id="basemap-opacity-container">
            <DebouncedSlider {...basemapOpacityAttributes} />
          </div>
        </div>
      </AccordionPane>
    );
  };

  renderZoomLevelOptions = () => {
    return (
      <AccordionPane key="zoom-level" title={I18n.t('subheaders.zoom_level', { scope })}>
        {this.renderMapZoomLevelSelector()}
      </AccordionPane>
    );
  };

  renderMapZoomLevelSelector = () => {
    const { onSetMapZoomLevel, vifAuthoring } = this.props;
    const mapZoomLevel = selectors.getMapZoomLevel(vifAuthoring);
    const centerAndZoom = selectors.getCenterAndZoom(vifAuthoring);
    const zoom = _.round(_.get(centerAndZoom, 'zoom'), DEFAULT_ZOOM_LEVEL_ROUND_OFF_VALUE);
    const mapZoomLevelAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'map-min-and-max-zoom-level',
      onChange: onSetMapZoomLevel,
      rangeMax: VIF_CONSTANTS.MAP_ZOOM_LEVEL.MAX,
      rangeMin: VIF_CONSTANTS.MAP_ZOOM_LEVEL.MIN,
      step: VIF_CONSTANTS.MAP_ZOOM_LEVEL.STEP,
      value: mapZoomLevel
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="map-min-and-max-zoom-level">
          {I18n.t('fields.min_and_max_zoom_level.title', { scope })}
        </label>
        <DebouncedSlider {...mapZoomLevelAttributes} />
        <div className="zoom-level-label min">{I18n.t('fields.zoomed_out.title', { scope })}</div>
        <div className="zoom-level-label max">{I18n.t('fields.zoomed_in.title', { scope })}</div>
        <div className="current_zoom_level">
          <i>{I18n.t('fields.current_zoom_level.title', { scope })}:</i> {zoom}
        </div>
      </div>
    );
  };

  renderBoundaryField = (id, value) => {
    const labelAttributes = { className: 'block-label', htmlFor: id };

    // Using an input(disabled) rather than debounced input as DebouncedInput does not
    // respect changes from outside the input field. For example, when the user
    // shift+clicks-and-drags, on release the search boundary gets updated in
    // the vif. But the debounced input wont respect the change from outside and still
    // shows the old value. So using a disabled input field for now.
    const inputAttributes = {
      className: 'text-input',
      disabled: true,
      id,
      type: 'number',
      value
    };

    return (
      <div className="authoring-field">
        <label {...labelAttributes}>{I18n.t(`fields.${id}.title`, { scope })}</label>
        <input {...inputAttributes} />
      </div>
    );
  };

  renderCheckboxControl = (name, checked, onChange) => {
    const id = `${name}_control`;
    const inputAttributes = {
      checked,
      id,
      onChange: (event) => onChange(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor={id}>
          <span className="fake-checkbox"><span className="icon-checkmark3"></span></span>
          {I18n.t(`fields.${name}_control.title`, { scope })}
        </label>
      </div>
    );
  };

  renderShowLegendCheckbox = (onChange, checked, title) => {
    const inputAttributes = {
      defaultChecked: checked,
      id: 'show-legends-options',
      onChange: (event) => onChange(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-legends-options">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {title}
        </label>
      </div>
    );
  };

  renderShowMultiplePointInLegend = () => {
    const { vifAuthoring, onSetShowMultiplePointsSymbolInLegend } = this.props;
    const hasAtleastOnePointMapSeries = selectors.getPointMapSeries(vifAuthoring).length > 0;
    if (!hasAtleastOnePointMapSeries) {
      return null;
    }
    const showMultiplePointsSymbolInLegend = selectors.getShowMultiplePointsSymbolInLegend(vifAuthoring);
    const multiplePointTitle = I18n.t('fields.show_multiple_points_symbol_in_legend.title', { scope });
    const inputAttributes = {
      defaultChecked: showMultiplePointsSymbolInLegend,
      id: 'show-multiple-points-symbol-in-legend',
      onChange: (event) => onSetShowMultiplePointsSymbolInLegend(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-multiple-points-symbol-in-legend">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {multiplePointTitle}
        </label>
      </div>
    );
  };

  renderPrecisionSelector = ({ selectorName, onSetPrecision, label, precision }) => {
    const { onSetMapFlyoutPrecision, vifAuthoring } = this.props;
    const precisionContainerClassName = `map-precision-control ${selectorName}-precision-container`;
    const precisionLableClassName = `${selectorName}-precision-label`;
    const precisionAttributes = {
      precision,
      id: `${selectorName}-precision`,
      className: `${selectorName}-precision-selector`,
      defaultPrecision: VIF_CONSTANTS.DEFAULT_MAP_PRECISION_VALUE,
      onSetPrecision
    };

    return (
      <div className={precisionContainerClassName}>
        <label className={precisionLableClassName}>{label}</label>
        <PrecisionSelector {...precisionAttributes} />
      </div>
    );
  };

  renderMapFlyoutPrecision = () => {
    const { onSetMapFlyoutPrecision, vifAuthoring } = this.props;
    const precision = selectors.getMapFlyoutPrecision(vifAuthoring);

    return this.renderPrecisionSelector({
      label: I18n.t('fields.map_flyout_precision.title', { scope }),
      onSetPrecision: onSetMapFlyoutPrecision,
      precision,
      selectorName: 'map-flyout'
    });
  };

  renderMapLegendPrecision = () => {
    const { onSetMapLegendPrecision, vifAuthoring } = this.props;
    const precision = selectors.getMapLegendPrecision(vifAuthoring);

    return this.renderPrecisionSelector({
      label: I18n.t('fields.map_legend_precision.title', { scope }),
      onSetPrecision: onSetMapLegendPrecision,
      precision,
      selectorName: 'map-legend'
    });
  };

  renderLegends = () => {
    const { onSetShowLegendForMap, onSetShowLegendOpened, vifAuthoring } = this.props;
    const showLegend = selectors.getShowLegendForMap(vifAuthoring);
    const showLegendOpened = selectors.getShowLegendOpened(vifAuthoring);
    const title = I18n.t('fields.show_legend.title', { scope });
    const inputAttributes = {
      defaultChecked: showLegendOpened,
      id: 'show-legends-opened',
      onChange: (event) => onSetShowLegendOpened(event.target.checked),
      type: 'checkbox'
    };

    const showLegendOpenedCheckbox = (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-legends-opened">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.show_legend_opened.title', { scope })}
        </label>
      </div>
    );

    return (
      <AccordionPane key="legends" title={I18n.t('subheaders.legends.title', { scope })}>
        {this.renderShowLegendCheckbox(onSetShowLegendForMap, showLegend, title)}
        {showLegendOpenedCheckbox}
        {this.renderShowMultiplePointInLegend()}
        {this.renderMapFlyoutPrecision()}
        {this.renderMapLegendPrecision()}
      </AccordionPane>
    );
  };

  renderArticleSupportLink = () => {
    return (
      <div className="authoring-field-description article-support-description">
        {I18n.t('fields.article_support_link.description', { scope })}
        <div className="article-support-link">
          <a href={DEFAULT_ARTICLE_SUPPORT_LINK} target="_blank" rel="noreferrer">
            {I18n.t('fields.article_support_link.title', { scope })}
          </a>
        </div>
      </div>
    );
  };

  renderClusterControls = () => {
    const {
      onSetClusterRadius,
      onSetMaxClusteringZoomLevel,
      onSetMaxClusterSize,
      onSetStackRadius,
      vifAuthoring
    } = this.props;
    const maxClusteringZoomLevelSlider = this.renderSliderControl({
      description: I18n.t('fields.max_clustering_zoom_level.description', { scope }),
      minValue: VIF_CONSTANTS.CLUSTERING_ZOOM.MIN,
      maxValue: VIF_CONSTANTS.CLUSTERING_ZOOM.MAX,
      name: 'max_clustering_zoom_level',
      step: VIF_CONSTANTS.CLUSTERING_ZOOM.STEP,
      value: selectors.getMaxClusteringZoomLevel(vifAuthoring),
      onChange: onSetMaxClusteringZoomLevel
    });
    const clusterRadiusSlider = this.renderSliderControl({
      description: null,
      minValue: VIF_CONSTANTS.CLUSTER_RADIUS.MIN,
      maxValue: VIF_CONSTANTS.CLUSTER_RADIUS.MAX,
      name: 'cluster_radius',
      step: VIF_CONSTANTS.CLUSTER_RADIUS.STEP,
      value: selectors.getClusterRadius(vifAuthoring),
      onChange: onSetClusterRadius
    });
    const maxClusterSizeSlider = this.renderSliderControl({
      description: null,
      minValue: VIF_CONSTANTS.CLUSTER_SIZE.MIN,
      maxValue: VIF_CONSTANTS.CLUSTER_SIZE.MAX,
      name: 'max_cluster_size',
      step: VIF_CONSTANTS.CLUSTER_SIZE.STEP,
      value: selectors.getMaxClusterSize(vifAuthoring),
      onChange: onSetMaxClusterSize
    });
    const stackRadiusSlider = this.renderSliderControl({
      description: null,
      minValue: VIF_CONSTANTS.STACK_RADIUS.MIN,
      maxValue: VIF_CONSTANTS.STACK_RADIUS.MAX,
      name: 'stack_radius',
      step: VIF_CONSTANTS.STACK_RADIUS.STEP,
      value: selectors.getStackRadius(vifAuthoring),
      onChange: onSetStackRadius
    });

    return (
      <AccordionPane key="clusterControls" title={I18n.t('subheaders.clusters', { scope })}>
        {this.renderArticleSupportLink()}
        {maxClusteringZoomLevelSlider}
        {/* Defering implementation to spiderifcation story. Hiding the form field for now. */}
        {/* {pointThresholdSlider}*/}
        {clusterRadiusSlider}
        {maxClusterSizeSlider}
        {stackRadiusSlider}
      </AccordionPane>
    );
  };

  renderDescriptionField = () => {
    const { onSetDescription, vifAuthoring } = this.props;
    const textAreaAttributes = {
      className: 'text-input text-area',
      id: 'description',
      onChange: (event) => onSetDescription(event.target.value),
      value: selectors.getDescription(vifAuthoring)
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="description">
          {I18n.t('fields.description.title', { scope })}
        </label>
        <DebouncedTextArea {...textAreaAttributes} />
      </div>
    );
  };

  renderGeneralOptions = () => {
    const viewSourceDataEnabled = FeatureFlags.value('enable_view_source_data_stories');
    return (
      <AccordionPane key="generalOptions" title={I18n.t('subheaders.general', { scope })}>
        {this.renderTitleField()}
        {this.renderDescriptionField()}
        {viewSourceDataEnabled && this.renderShowSourceDataLink()}
        <ShowDataTableControl />
      </AccordionPane>
    );
  };

  renderMapControls = () => {
    const {
      onSetGeoCoderControl,
      onSetGeoLocateControl,
      onSetLayerToggleControl,
      onSetNavigationControl,
      vifAuthoring
    } = this.props;
    const isGeoCoderControlChecked = selectors.getGeoCoderControl(vifAuthoring);
    const isGeoLocateControlChecked = selectors.getGeoLocateControl(vifAuthoring);
    const isNavigationControlChecked = selectors.getNavigationControl(vifAuthoring);
    const isLayerToggleControlChecked = selectors.getLayerToggleControl(vifAuthoring);
    const series = selectors.getSeries(vifAuthoring);

    return (
      <AccordionPane
        title={I18n.t('subheaders.map_controls', { scope })}
        key="basemapControls">
        {this.renderCheckboxControl('geo_coder', isGeoCoderControlChecked, onSetGeoCoderControl)}
        {this.renderCheckboxControl('geo_locate', isGeoLocateControlChecked, onSetGeoLocateControl)}
        {this.renderCheckboxControl('navigation', isNavigationControlChecked, onSetNavigationControl)}
        {series.length > 1 && this.renderCheckboxControl('layer_toggle', isLayerToggleControlChecked, onSetLayerToggleControl)}
      </AccordionPane>
    );
  };

  renderSearchBoundaryFields = () => {
    const { vifAuthoring } = this.props;
    const searchBoundaryUpperLeftLatitude = selectors.getSearchBoundaryUpperLeftLatitude(vifAuthoring);
    const searchBoundaryUpperLeftLongitude = selectors.getSearchBoundaryUpperLeftLongitude(vifAuthoring);
    const searchBoundaryLowerRightLatitude = selectors.getSearchBoundaryLowerRightLatitude(vifAuthoring);
    const searchBoundaryLowerRightLongitude = selectors.getSearchBoundaryLowerRightLongitude(vifAuthoring);

    return (
      <AccordionPane
        title={I18n.t('subheaders.search_boundary', { scope })}
        key="searchBoundaryFields">
        <p className="authoring-field-description search-boundary-description">
          <small>{I18n.t('fields.search_boundary.description', { scope })}</small>
        </p>
        {this.renderBoundaryField('upper_left_latitude', searchBoundaryUpperLeftLatitude)}
        {this.renderBoundaryField('upper_left_longitude', searchBoundaryUpperLeftLongitude)}
        {this.renderBoundaryField('lower_right_latitude', searchBoundaryLowerRightLatitude)}
        {this.renderBoundaryField('lower_right_longitude', searchBoundaryLowerRightLongitude)}
      </AccordionPane>
    );
  };

  renderShowSourceDataLink = () => {
    const { onSetViewSourceDataLink, vifAuthoring } = this.props;
    const inputAttributes = {
      checked: selectors.getViewSourceDataLink(vifAuthoring),
      id: 'show-source-data-link',
      onChange: (event) => onSetViewSourceDataLink(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-source-data-link">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.show_source_data_link.title', { scope })}
        </label>
      </div>
    );
  };

  renderSliderControl = ({
    description,
    maxValue: rangeMax,
    minValue: rangeMin,
    name,
    onChange,
    step,
    value
  }) => {
    const sliderAttributes = {
      delay: getMapSliderDebounceMs(),
      id: name,
      onChange,
      rangeMax,
      rangeMin,
      step,
      value
    };

    return (
      <div className="authoring-field">
        <BlockLabel
          title={I18n.t(`fields.${name}.title`, { scope })}
          htmlFor={name}
          description={description} />
        <div id={`${name}_container`}><DebouncedSlider {...sliderAttributes} /></div>
      </div>
    );
  };

  renderTitleField = () => {
    const { onSetTitle, vifAuthoring } = this.props;
    const inputAttributes = {
      className: 'text-input',
      id: 'title',
      onChange: (event) => onSetTitle(event.target.value),
      type: 'text',
      value: selectors.getTitle(vifAuthoring)
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="title">{I18n.t('fields.title.title', { scope })}</label>
        <DebouncedInput {...inputAttributes} />
      </div>
    );
  };

  render() {
    return (
      <form>
        <AccordionContainer>{this.renderBasemapControls()}</AccordionContainer>
      </form>
    );
  }
}

BasemapPane.propTypes = {
  onSetBasemapOpacity: PropTypes.func,
  onSetBasemapStyle: PropTypes.func,
  onSetClusterRadius: PropTypes.func,
  onSetDescription: PropTypes.func,
  onSetGeoCoderControl: PropTypes.func,
  onSetGeoLocateControl: PropTypes.func,
  onSetLayerToggleControl: PropTypes.func,
  onSetMapZoomLevel: PropTypes.func,
  onSetMapFlyoutPrecision: PropTypes.func,
  onSetMapLegendPrecision: PropTypes.func,
  onSetMaxClusteringZoomLevel: PropTypes.func,
  onSetMaxClusterSize: PropTypes.func,
  onSetNavigationControl: PropTypes.func,
  onSetShowLegendForMap: PropTypes.func,
  onSetShowLegendOpened: PropTypes.func,
  onSetShowMultiplePointsSymbolInLegend: PropTypes.func,
  onSetStackRadius: PropTypes.func,
  onSetTitle: PropTypes.func,
  onSetViewSourceDataLink: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetBasemapOpacity: setBasemapOpacity,
  onSetBasemapStyle: setBasemapStyle,
  onSetClusterRadius: setClusterRadius,
  onSetDescription: setDescription,
  onSetGeoCoderControl: setGeoCoderControl,
  onSetGeoLocateControl: setGeoLocateControl,
  onSetLayerToggleControl: setLayerToggleControl,
  onSetMapFlyoutPrecision: setMapFlyoutPrecision,
  onSetMapLegendPrecision: setMapLegendPrecision,
  onSetMapZoomLevel: setMapZoomLevel,
  onSetMaxClusteringZoomLevel: setMaxClusteringZoomLevel,
  onSetMaxClusterSize: setMaxClusterSize,
  onSetNavigationControl: setNavigationControl,
  onSetShowLegendForMap: setShowLegendForMap,
  onSetShowLegendOpened: setShowLegendOpened,
  onSetShowMultiplePointsSymbolInLegend: setShowMultiplePointsSymbolInLegend,
  onSetStackRadius: setStackRadius,
  onSetTitle: setTitle,
  onSetViewSourceDataLink: setViewSourceDataLink
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(BasemapPane);
