// Vendor Imports
import _ from 'lodash';

// Project Imports
import { findPaletteCanonicalReference } from 'common/visualizations/helpers/SiteAppearanceColors';
import * as actions from '../../actions';
import baseVifReducer from './base';
import { appendSeries } from '../../helpers';
import { SCATTER_CHART } from '../../constants';

// Constants
import vifs from '../../vifs';

export default function scatterChart(state, action) {
  if (_.isUndefined(state)) {
    return vifs().scatterChart;
  }

  state = _.cloneDeep(state);

  switch (action.type) {

    case actions.APPEND_SERIES:
      appendSeries(state, action);
      break;

    case actions.RESET_STATE:
      state = vifs().scatterChart;
      break;

    case actions.SET_PLOT_SIZE:
      _.set(state, 'configuration.plotSize', action.plotSize);
      break;

    case actions.SET_DIMENSION_LABEL_AREA_SIZE:
      _.set(state, 'configuration.dimensionLabelAreaSize', action.width);
      break;

    case actions.SET_X_AXIS_COLUMN:
      _.set(state, `series[${SCATTER_CHART.X_AXIS_SERIES_INDEX}].dataSource.measure.columnName`, action.xAxisColumn);
      break;

    case actions.SET_Y_AXIS_COLUMN:
      _.set(state, `series[${SCATTER_CHART.Y_AXIS_SERIES_INDEX}].dataSource.measure.columnName`, action.yAxisColumn);
      break;

    case actions.SET_RESIZE_BY_COLUMN:
      _.set(state, `series[${SCATTER_CHART.RESIZE_BY_SERIES_INDEX}].dataSource.measure.columnName`, action.resizeByColumn);
      break;

    case actions.SET_COLOR_BY_COLUMN:
      _.set(state, `series[${SCATTER_CHART.COLOR_BY_SERIES_INDEX}].dataSource.measure.columnName`, action.colorByColumn);
      break;

    case actions.SET_MINIMUM_PLOT_SIZE:
      setPathToValue(state, 'chartOptions.minimumPlotSize', action.minimumPlotSize);
      break;

    case actions.SET_MAXIMUM_PLOT_SIZE:
      setPathToValue(state, 'chartOptions.maximumPlotSize', action.maximumPlotSize);
      break;

    case actions.SET_COLOR_PALETTE:
      setPathToValue(state, 'color.palette', findPaletteCanonicalReference(action.colorPalette));
      break;

    case actions.SET_NUMBER_OF_DATA_CLASSES:
      setPathToValue(state, 'chartOptions.numberOfDataClasses', action.numberOfDataClasses);
      break;

    case actions.SET_SHOW_LEGEND:
      setPathToValue(state, 'showLegend', action.showLegend);
      break;

    case actions.SET_SHOW_LEGEND_OPENED:
      _.set(state, 'configuration.showLegendOpened', action.showLegendOpened);
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE:
      const customColorPalette = action.customColorPalette;
      const grouping = action.dimensionGroupingColumnName;

      setPathToValue(state, 'color.customPalette', { [grouping]: customColorPalette });
      break;

    case actions.UPDATE_CUSTOM_COLOR_PALETTE: {
      const { dimensionGroupingColumnName, group, selectedColor } = action;
      const path = `color.customPalette.${dimensionGroupingColumnName}.${group}.color`;

      setPathToValue(state, path, selectedColor);
      break;
    }

    case actions.RECEIVE_METADATA:
    case actions.REMOVE_SERIES:
    case actions.SET_DATASET_UID:
    case actions.SET_DESCRIPTION:
    case actions.SET_DOMAIN:
    case actions.SET_FILTERS:
    case actions.SET_LABEL_TOP:
    case actions.SET_LABEL_BOTTOM:
    case actions.SET_LABEL_LEFT:
    case actions.SET_LABEL_RIGHT:
    case actions.SET_LEGEND_POSITION:
    case actions.SET_MEASURE_COLUMN:
    case actions.SET_PRIMARY_COLOR:
    case actions.SET_SHOW_DATA_TABLE_CONTROL:
    case actions.SET_TITLE:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
    case actions.SET_SHOW_NULLS_AS_FALSE:
    case actions.SWAP_COLOR_PALETTE:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}

function setPathToValue(state, path, value) {
  const series = _.get(state, 'series', []);

  if (series.length > 0) {
    _.forEach(series, (seriesItem, index) => {
      _.set(state, `series[${index}].${path}`, value);
    });
  }
}
