// Vendor Imports
import _ from 'lodash';

// Project Imports
import { findPaletteCanonicalReference } from 'common/visualizations/helpers/SiteAppearanceColors';
import {
  forEachSeries,
  isGroupingOrHasMultipleNonFlyoutSeries,
  removeSeries,
  setBooleanValueOrDefaultValue,
  setBooleanValueOrDeleteProperty,
  setDimensionGroupingColumnName,
  setMeasureAggregation,
  setMeasureColumn,
  setNumericValueOrDeleteProperty,
  setStringValueOrDefaultValue,
  setStringValueOrDeleteProperty,
  setUnits,
  trySetShowLegend,
  tryUnsetShowLegend
} from '../../helpers';

import * as actions from '../../actions';

// Constants
import {
  REFERENCE_LINES_DEFAULT_LINE_COLOR
} from '../../constants';

// This is a helper function that handles actions common to all vif types.
export default function(state, action) {
  function getSeriesIndex() {
    return _.get(state, 'currentMapLayerIndex', 0);
  }

  function isMapLayerSeries(series) {
    return _.get(series, 'type', '') === 'map' && !_.get(series[getSeriesIndex()], 'primary', false);
  }

  const visualizationType = _.get(state, 'series[0].type');
  if (
    !_.isUndefined(action.targetVisualizationType) &&
    action.targetVisualizationType !== visualizationType
  ) {
    // Modify the state only if the state's visualizationType is same as targetVisualizationType or
    // if there is no target VisualizationType. For multi layer maps, we need to switch the
    // dataset and domain for maps/table as we switch layers. But those actions should not affect
    // other visualizations.
    return state;
  }

  switch (action.type) {
    case actions.ADD_DRILL_DOWN_COLUMN:
      const drilldowns = _.get(state, 'series[0].dataSource.dimension.drilldowns', []);

      drilldowns.push({ columnName: action.drilldownColumnName, aggregationFunction: null });

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.drilldowns', drilldowns);
      });
      break;

    case actions.CHANGE_DRILL_DOWN_COLUMN:
      const drilldownColumns = _.get(state, 'series[0].dataSource.dimension.drilldowns', []);

      drilldownColumns[action.index] = { columnName: action.columnName, aggregationFunction: null };

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.drilldowns', drilldownColumns);
      });
      break;

    case actions.RECEIVE_METADATA:
      forEachSeries(state, series => {
        setUnits(series, action);
      });
      break;

    case actions.REMOVE_DRILL_DOWN_COLUMN:
      let drilldownDimensions = _.get(state, 'series[0].dataSource.dimension.drilldowns', []);
      drilldownDimensions.splice(action.index, 1);

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.drilldowns', drilldownDimensions);
      });
      break;

    case actions.ADD_TABLE_COLUMN:
      const columns = _.get(state, 'series[0].dataSource.dimension.columns', []);
      const allColumns = action.columns;

      const columnToBeAdded = allColumns.find((col) => col.fieldName === action.tableColumnName.value);

      columns.push(columnToBeAdded);

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.columns', columns);
      });
      break;


    case actions.REMOVE_TABLE_COLUMN:
      let tableDimensions = _.get(state, 'series[0].dataSource.dimension.columns', []);
      tableDimensions.splice(action.index, 1);

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.columns', tableDimensions);
      });
      break;

    // Should we rename this to REPLACE_ALL_COLUMNS?
    case actions.SELECT_ALL_COLUMNS:
      let allTableColumns = action.columns;

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.columns', allTableColumns);
      });
      break;

    case actions.RESET_ALL_COLUMNS:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.columns', []);
      });
      break;

    case actions.REORDER_TABLE_COLUMNS:
      let updatedCols = action.newOrder;

      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.columns', updatedCols);
      });
      break;

    case actions.REMOVE_SERIES:
      removeSeries(state, action);
      break;

    case actions.SET_COLOR_PALETTE:
      if (isGroupingOrHasMultipleNonFlyoutSeries(state)) {
        forEachSeries(state, series => {
          setStringValueOrDeleteProperty(series, 'color.palette', findPaletteCanonicalReference(action.colorPalette));
        });
      }
      break;

    case actions.SET_CURRENT_DRILL_DOWN_COLUMN_NAME:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.currentDrilldownColumnName', action.currentDrilldownColumnName);
      });
      break;

    case actions.SET_DATASET_UID:
      forEachSeries(state, (series) => {
        // For multilayer maps, we want to update "dataTable" dataset UID so that it shows preview of
        // the layer that is currently selected, or the primary layer if no layer is selected.
        // We trigger this by passing an additional action parameter "forceUpdate" as true.
        if (_.get(action, 'forceUpdate', false)
          || (_.isNil(_.get(series, 'dataSource.datasetUid')))) {
          setStringValueOrDefaultValue(series, 'dataSource.datasetUid', action.datasetUid, null);
        }
      });
      break;

    case actions.SET_DATE_DISPLAY_FORMAT:
      forEachSeries(state, series => {
        setStringValueOrDeleteProperty(series, 'dataSource.dateDisplayFormat', action.format);
      });
      break;

    case actions.SET_DOMAIN:
      forEachSeries(state, series => {
        if (!isMapLayerSeries(series)) {
          setStringValueOrDefaultValue(series, 'dataSource.domain', action.domain, null);
        }
      });
      break;

    case actions.SET_DRILL_DOWNS:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.drilldowns', action.drilldowns);
      });
      break;

    case actions.SET_EVENT_BACKGROUND_COLOR:
      forEachSeries(state, series => {
        _.set(series, 'color.eventBackgroundColor', action.eventBackgroundColor);
      });
      break;

    case actions.SET_EVENT_OUTLINE_COLOR:
      forEachSeries(state, series => {
        _.set(series, 'color.eventOutlineColor', action.eventOutlineColor);
      });
      break;

    case actions.SET_EVENT_TEXT_COLOR:
      forEachSeries(state, series => {
        _.set(series, 'color.eventTextColor', action.eventTextColor);
      });
      break;

    case actions.SET_ERROR_BARS_BAR_COLOR:
      forEachSeries(state, series => {
        _.set(series, 'errorBars.barColor', action.color);
      });
      break;

    case actions.SET_ERROR_BARS_LOWER_BOUND_COLUMN_NAME:
      setStringValueOrDeleteProperty(state, 'series[0].errorBars.lowerBoundColumnName', action.columnName);

      if (_.isNil(action.columnName) && _.isNil(_.get(state, 'series[0].errorBars.upperBoundColumnName'))) {
        _.unset(state, 'series[0].errorBars');
      }
      break;

    case actions.SET_ERROR_BARS_UPPER_BOUND_COLUMN_NAME:
      setStringValueOrDeleteProperty(state, 'series[0].errorBars.upperBoundColumnName', action.columnName);

      if (_.isNil(action.columnName) && _.isNil(_.get(state, 'series[0].errorBars.lowerBoundColumnName'))) {
        _.unset(state, 'series[0].errorBars');
      }
      break;

    case actions.SET_FILTERS:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.filters', action.filters);
      });
      break;

    case actions.SWAP_COLOR_PALETTE:
      const customColorPalette = _.get(
        _.cloneDeep(state),
        ['series', 0, 'color', 'customPalette', action.columnName]
      );

      _.each(customColorPalette, (palette) => {
        if (palette.index === action.toIndex) {
          palette.index = action.fromIndex;
        } else if (palette.index === action.fromIndex) {
          palette.index = action.toIndex;
        }
      });

      forEachSeries(state, series => {
        _.set(series, `color.customPalette.${action.columnName}`, customColorPalette);
      });
      break;

    case actions.SET_DIMENSION:
      forEachSeries(state, series => {
        if (!isMapLayerSeries(series)) {
          setStringValueOrDefaultValue(series, 'dataSource.dimension.columnName', action.dimension, null);
        }
      });

      const groupingDimension = _.get(state, `series[${getSeriesIndex()}].dataSource.dimension.grouping.columnName`);
      if (action.dimension && action.dimension === groupingDimension) {
        setDimensionGroupingColumnName(state, null);
      }
      break;

    case actions.SET_DIMENSION_LABEL_ROTATION_ANGLE:
      if (_.isFinite(action.angle)) {
        _.set(state, 'configuration.dimensionLabelRotationAngle', action.angle);
      } else {
        _.unset(state, 'configuration.dimensionLabelRotationAngle');
      }
      break;

    case actions.SET_GROUPING_ORDER_BY:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.dimension.grouping.orderBy', _.cloneDeep(action.orderBy));
      });
      break;

    case actions.SET_MEASURE_AGGREGATION:
      setMeasureAggregation(state, action);
      break;

    case actions.SET_MEASURE_COLUMN:
      setMeasureColumn(state, action);
      break;

    case actions.SET_ORDER_BY:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.orderBy', _.cloneDeep(action.orderBy));
      });
      break;

    case actions.SET_PRECISION:
      forEachSeries(state, series => {
        _.set(series, 'dataSource.precision', action.precision);
      });
      break;

    case actions.APPEND_REFERENCE_LINE:
      if (!state.referenceLines) {
        state.referenceLines = [];
      }

      state.referenceLines.push({
        color: REFERENCE_LINES_DEFAULT_LINE_COLOR,
        label: '',
        uId: _.uniqueId('reference-line-')
      });
      break;

    case actions.REMOVE_REFERENCE_LINE:
      state.referenceLines.splice(action.referenceLineIndex, 1);

      if (state.referenceLines.length == 0) {
        _.unset(state, 'referenceLines');
        tryUnsetShowLegend(state);
      }
      break;

    case actions.SET_REFERENCE_LINE_COLOR:
      if (action.referenceLineIndex < state.referenceLines.length) {
        const referenceLine = state.referenceLines[action.referenceLineIndex];
        _.set(referenceLine, 'color', action.color);
      }
      break;

    case actions.SET_REFERENCE_LINE_LABEL:
      if (action.referenceLineIndex < state.referenceLines.length) {
        const referenceLine = state.referenceLines[action.referenceLineIndex];
        _.set(referenceLine, 'label', action.label);

        if (_.isEmpty(action.label)) {
          tryUnsetShowLegend(state);
        } else {
          trySetShowLegend(state);
        }
      }
      break;

    case actions.SET_REFERENCE_LINE_VALUE:
      if (action.referenceLineIndex < state.referenceLines.length) {
        const referenceLine = state.referenceLines[action.referenceLineIndex];

        if (_.isFinite(action.value)) {
          _.set(referenceLine, 'value', action.value);
        } else {
          _.unset(referenceLine, 'value');
        }
      }
      break;

    case actions.SET_LINE_STYLE_POINTS:
      forEachSeries(state, series => {
        setStringValueOrDeleteProperty(series, 'lineStyle.points', action.points);
        if (_.isNil(_.get(series, 'lineStyle.points')) && _.isNil(_.get(series, 'lineStyle.pointRadius'))) {
          _.unset(series, 'lineStyle');
        }
      });
      break;

    case actions.SET_LINE_STYLE_POINT_RADIUS:
      forEachSeries(state, series => {
        setNumericValueOrDeleteProperty(series, 'lineStyle.pointRadius', action.pointRadius);
        if (_.isNil(_.get(series, 'lineStyle.points')) && _.isNil(_.get(series, 'lineStyle.pointRadius'))) {
          _.unset(series, 'lineStyle');
        }
      });
      break;

    case actions.SET_STACKED:
      forEachSeries(state, series => {
        if (action.stacked) {
          _.set(series, 'stacked.oneHundredPercent', action.oneHundredPercent);
        } else {
          _.unset(series, 'stacked');
        }
      });

      if (action.oneHundredPercent) {
        _.unset(state, 'configuration.measureAxisMinValue');
        _.unset(state, 'configuration.measureAxisMaxValue');
      }
      break;

    case actions.SET_TITLE:
      setStringValueOrDefaultValue(state, 'title', action.title, '');
      break;

    case actions.SET_SHOW_DATA_TABLE_CONTROL:
      _.set(state, 'configuration.showDataTableControl', action.showDataTableControl);
      break;

    case actions.SET_DESCRIPTION:
      setStringValueOrDefaultValue(state, 'description', action.description, '');
      break;

    case actions.SET_SHOW_LEGEND:
      if (action.showLegend) {
        trySetShowLegend(state);
      } else {
        // Explicitly setting showLegend to false here. Unsetting
        // it is the equivalent of setting no preference which allows
        // the legend to show up again when round-tripping a vif that
        // is multi-series, grouped, or that has a reference line with
        // label.
        _.set(state, 'series[0].showLegend', false);
      }
      break;

    case actions.SET_SHOW_LEGEND_OPENED:
      setBooleanValueOrDeleteProperty(state, 'configuration.showLegendOpened', action.showLegendOpened);
      break;

    case actions.SET_LEGEND_POSITION:
      setStringValueOrDeleteProperty(state, 'configuration.legendPosition', action.position);
      break;

    case actions.SET_SHOW_NULLS_AS_FALSE:
      setBooleanValueOrDeleteProperty(state, 'configuration.showNullsAsFalse', action.showNullsAsFalse);
      break;

    case actions.SET_SHOW_VALUE_LABELS:
      setBooleanValueOrDefaultValue(state, 'configuration.showValueLabels', action.showValueLabels, true);
      break;

    case actions.SET_SHOW_VALUE_LABELS_AS_PERCENT:
      setBooleanValueOrDefaultValue(state, 'configuration.showValueLabelsAsPercent', action.showValueLabelsAsPercent, false);
      break;

    case actions.SET_WRAP_DIMENSION_LABELS:
      setBooleanValueOrDeleteProperty(state, 'configuration.wrapDimensionLabels', action.wrapDimensionLabels);
      break;

    case actions.SET_VIEW_SOURCE_DATA_LINK:
      _.set(state, 'configuration.viewSourceDataLink', action.viewSourceDataLink);
      break;

    case actions.SET_UNIT_ONE:
      if (action.seriesIndex < state.series.length) {
        const series = state.series[action.seriesIndex];
        _.set(series, 'unit.one', action.one);
      }
      break;

    case actions.SET_UNIT_OTHER:
      if (action.seriesIndex < state.series.length) {
        const series = state.series[action.seriesIndex];
        _.set(series, 'unit.other', action.other);
      }
      break;

    case actions.SET_PRIMARY_COLOR:
      if (action.seriesIndex < state.series.length) {
        const series = state.series[action.seriesIndex];
        setStringValueOrDeleteProperty(series, 'color.primary', action.primaryColor);
      }
      break;

    case actions.SET_SECONDARY_COLOR:
      if (action.seriesIndex < state.series.length) {
        const series = state.series[action.seriesIndex];
        setStringValueOrDeleteProperty(series, 'color.secondary', action.secondaryColor);
      }
      break;

    case actions.SET_LABEL_TOP:
      setStringValueOrDeleteProperty(state, 'configuration.axisLabels.top', action.labelTop);
      break;

    case actions.SET_LABEL_BOTTOM:
      setStringValueOrDeleteProperty(state, 'configuration.axisLabels.bottom', action.labelBottom);
      break;

    case actions.SET_LABEL_LEFT:
      setStringValueOrDeleteProperty(state, 'configuration.axisLabels.left', action.labelLeft);
      break;

    case actions.SET_LABEL_RIGHT:
      setStringValueOrDeleteProperty(state, 'configuration.axisLabels.right', action.labelRight);
      break;

    case actions.SET_MEASURE_AXIS_MAX_VALUE:
      setNumericValueOrDeleteProperty(state, 'configuration.measureAxisMaxValue', action.measureAxisMaxValue);
      break;

    case actions.SET_MEASURE_AXIS_MIN_VALUE:
      setNumericValueOrDeleteProperty(state, 'configuration.measureAxisMinValue', action.measureAxisMinValue);
      break;

    case actions.SET_MEASURE_AXIS_PRECISION:
      // Use this instead of setNumericValueOrDeleteProperty because 0 is a permissible precision.
      if (_.isFinite(action.precision)) {
        _.set(state, 'configuration.measureAxisPrecision', action.precision);
      } else {
        _.unset(state, 'configuration.measureAxisPrecision');
      }
      break;

    case actions.SET_MAP_LEGEND_PRECISION:
      // Use this instead of setNumericValueOrDeleteProperty because 0 is a permissible precision.
      if (_.isFinite(action.precision)) {
        _.set(state, 'configuration.mapLegendPrecision', action.precision);
      } else {
        _.unset(state, 'configuration.mapLegendPrecision');
      }
      break;

    case actions.SET_MAP_FLYOUT_PRECISION:
      // Use this instead of setNumericValueOrDeleteProperty because 0 is a permissible precision.
      if (_.isFinite(action.precision)) {
        _.set(state, 'configuration.mapFlyoutPrecision', action.precision);
      } else {
        _.unset(state, 'configuration.mapFlyoutPrecision');
      }
      break;

    case actions.SET_MEASURE_AXIS_SCALE:
      setStringValueOrDeleteProperty(state, 'configuration.measureAxisScale', action.scale);
      break;

    case actions.SET_LOGARITHMIC_SCALE:
      setBooleanValueOrDeleteProperty(state, 'configuration.logarithmicScale', action.logarithmicScale);
      break;

    case actions.SET_TREAT_NULL_VALUES_AS_ZERO:
      setBooleanValueOrDeleteProperty(state, 'configuration.treatNullValuesAsZero', action.treatNullValuesAsZero);
      break;

    case actions.SET_X_AXIS_SCALING_MODE:
      setStringValueOrDeleteProperty(state, 'configuration.xAxisScalingMode', action.xAxisScalingMode);
      break;
  }

  return state;
}
