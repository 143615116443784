import { kebabCase } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import FlyoutFactory from 'common/components/legacy/Flyout';


interface Props {
  /**
   * id is used to identify which tooltip should be triggered by which column on
   * the page
   */
  id: string | React.ReactNode;
  /**
   * (Default: undefined)
   * An object used for populating the flyout attached to the
   * column item. If nothing is specified, a flyout will not appear.
   */
  content: any;
  /**
   * An object where the tooltip attaches to.
   */
  children: React.ReactNode;
}

const TableColumnTooltip: FunctionComponent<Props> = ({
  id,
  content,
  children
}) => {
  const [flyoutElement, setFlyoutElement] = useState<HTMLDivElement | null>(null);
  const flyoutName = id ? `${kebabCase(id.toString())}-flyout` : undefined;

  const childrenWithProps = React.Children.map(children,
    child => React.cloneElement(child as React.ReactElement<any>)
  );

  // setup flyout if applicable
  // checking for [data-flyout] is necessary because on mounting the component
  // goes through multiple renders as the underlying state hooks change.
  if (flyoutElement && flyoutElement.querySelectorAll('[data-flyout]').length > 0) {
    new FlyoutFactory(flyoutElement);
  }

  const renderFlyout = () => {
    if (flyoutElement) {
      return (
        <div id={flyoutName} className="flyout flyout-hidden" aria-hidden>
          <section className="flyout-content">
            {content}
          </section>
        </div>
      );
    }
  };

  const renderColumn = () => {
    if (flyoutElement) {
      return (
        <div
          data-flyout={flyoutName}
          role="tooltip"
          aria-labelledby={`label-${flyoutName}`}
          aria-describedby={flyoutName}
        >
          {childrenWithProps}
        </div>
      );
    }
  };

  return (
    <div
      ref={element => {setFlyoutElement(element);}}
      id={`label-${flyoutName}`}
      className={'table-column-container'}
    >
      {renderColumn()}
      {renderFlyout()}
    </div>
  );
};

TableColumnTooltip.defaultProps = {
  id: 'table-column-item'
};

export default TableColumnTooltip;
