import React from 'react';
import { map } from 'lodash';
import { ForgeSelect } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import { CURRENCY_FORMATS } from 'common/authoring_workflow/constants';

const scope = 'shared.visualizations.panes.presentation';

export interface currencySelectorProps {
  currency: string | undefined;
  onCurrencyStyleChange: (currency: string) => void;
}

const AgGridCurrencySelector = (props: currencySelectorProps) => {
  const { currency, onCurrencyStyleChange } = props;
  const currencyFormatOptions = map(CURRENCY_FORMATS, (currencyFormatItem) =>
    ({ label: currencyFormatItem.title, value: currencyFormatItem.value }));

  const currencySelectorProps = {
    'data-testid': 'ag-currency-selector',
    options: currencyFormatOptions,
    value: currency,
    label: I18n.t('fields.ag_currency_format.title', { scope }),
    floatLabelType: 'always',
    onChange: (event: CustomEvent) => {
      onCurrencyStyleChange(event.detail);
    }
  };

  return (
    <div className="ag-grid-currency-selector authoring-field">
      <ForgeSelect {...currencySelectorProps} />
    </div>
  );
};

export default AgGridCurrencySelector;
