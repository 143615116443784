import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModeStates } from '../lib/constants';
import VisualizationWrapper from './VisualizationWrapper';
import AddVisualizationButton from './AddVisualizationButton';
import {
  setCurrentDrilldownColumnName,
  setCurrentDisplayDate,
  setFilters,
  setMapCenterAndZoom,
  setMapNotificationDismissed,
  setMapPitchAndBearing,
  setMapLayerVisible
} from '../actions';

import { withGuidanceV2 } from 'common/core/approvals/index_new';

export class Visualizations extends Component {
  render() {
    const {
      vifs,
      isEditable,
      isPending,
      mapNotificationDismissed,
      mode,
      onSetCurrentDrilldownColumnName,
      onSetCurrentDisplayDate,
      onMapCenterAndZoomChange,
      onMapNotificationDismiss,
      onMapPitchAndBearingChange,
      onSetFilters,
      onToggleMapLayerVisible
    } = this.props;

    // This functionality is deprecated.
    if (_.isEmpty(vifs)) {
      return (mode === ModeStates.EDIT) && <AddVisualizationButton />;
    }

    const visualizations = _.map(vifs, (vif, vifIndex) => {
      // EN-22002 - including the `origin` (which includes `url`) will render the "View source data" link
      // as a link to this viz-canvas. We remove it so that it defaults to pointing to the underlying dataset.
      vif = _.omit(vif, 'origin');

      const props = {
        key: vifIndex,
        vif,
        vifIndex,
        isEditable,
        isPending,
        mapNotificationDismissed: mapNotificationDismissed[vifIndex],
        onSetCurrentDrilldownColumnName,
        onMapCenterAndZoomChange,
        onMapNotificationDismiss,
        onMapPitchAndBearingChange,
        onSetFilters,
        onSetCurrentDisplayDate,
        onToggleMapLayerVisible
      };

      return <VisualizationWrapper {...props} />;
    });

    return (
      <div className="visualizations">
        {visualizations}
      </div>
    );
  }
}

Visualizations.propTypes = {
  vifs: PropTypes.array.isRequired,
  isEditable: PropTypes.bool,
  isPending: PropTypes.bool,
  mapNotificationDismissed: PropTypes.array,
  mode: PropTypes.oneOf(_.values(ModeStates)).isRequired,
  onMapCenterAndZoomChange: PropTypes.func,
  onMapNotificationDismiss: PropTypes.func,
  onMapPitchAndBearingChange: PropTypes.func,
  onToggleMapLayerVisible: PropTypes.func
};

Visualizations.defaultProps = {
  isEditable: false,
  isPending: false,
  mapNotificationDismissed: [],
  onSetCurrentDrilldownColumnName: _.noop,
  onSetCurrentDisplayDate: _.noop,
  onMapCenterAndZoomChange: _.noop,
  onMapNotificationDismiss: _.noop,
  onMapPitchAndBearingChange: _.noop,
  onSetFilters: _.noop,
  onToggleMapLayerVisible: _.noop
};

function mapStateToProps(state) {
  const approvalsGuidance = _.get(state, 'approvalsGuidance', null);
  return {
    isPending: approvalsGuidance ? withGuidanceV2(approvalsGuidance).isPending() : false,
    ..._.cloneDeep(_.pick(state, 'vifs', 'mapNotificationDismissed', 'mode'))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSetCurrentDrilldownColumnName: setCurrentDrilldownColumnName,
    onMapCenterAndZoomChange: setMapCenterAndZoom,
    onMapNotificationDismiss: setMapNotificationDismissed,
    onMapPitchAndBearingChange: setMapPitchAndBearing,
    onSetFilters: setFilters,
    onSetCurrentDisplayDate: setCurrentDisplayDate,
    onToggleMapLayerVisible: setMapLayerVisible
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Visualizations);
