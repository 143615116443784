import _ from 'lodash';
import { fetchJsonWithDefaultHeaders, urlParams } from 'common/http';

const createVizCanCoreView = (name, vizCan, parentView) => {
  const deletedAt = urlParams().deleted_at;
  const deletedAtParam = (deletedAt ? `?deleted_at=${encodeURIComponent(deletedAt)}` : '');
  const filters = vizCan.displayFormat.visualizationCanvasMetadata.filters;
  const parentViewId = _.get(vizCan, 'permissions.parent.viewId') || _.get(parentView, 'id');
  return fetchJsonWithDefaultHeaders(`/visualization_canvas${deletedAtParam}`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      ...(filters && { filters }),
      displayType: vizCan.displayType,
      metadata: {
        availableDisplayTypes: vizCan.metadata.availableDisplayTypes
      },
      vifs: vizCan.displayFormat.visualizationCanvasMetadata.vifs,
      parentView: { id: parentViewId },
      name
    })
  });
};

export const copyVizCan = async (name, vizCan, parentView) => {
  const { id } = await createVizCanCoreView(name, vizCan, parentView);
  return id;
};
