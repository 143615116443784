import React, { useEffect, useMemo, useState } from 'react';
import I18n from 'common/i18n';

import {
  TableColumnFormat,
  FormatStyle,
  FontStyleTypes,
  ConditionalConfig,
  Expression
} from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import { isComplexConditionalFormattingEnabled } from 'common/visualizations/helpers/VifSelectors';
import { TIME_FORMATS } from 'common/DataTypeFormatter';
import {
  ForgeDateRangePicker,
  ForgeTextField,
  ForgeIconButton,
  ForgeIcon,
  ForgeButton
} from '@tylertech/forge-react';
import { COLORS } from 'common/authoring_workflow/constants';
import BlockLabel from 'common/components/BlockLabel';
import ColorPickerWithLabel from 'common/components/ColorPickerWithLabel';
import { SoQLType } from 'common/types/soql';
import { get, isEqual, isNull, isEmpty } from 'lodash';
import moment from 'moment';
import OperandFields from './OperandFields';
import OperatorSelector from './OperatorSelector';
import { defaultConditionalConfig, defaultFontStyle } from './helpers';
import classNames from 'classnames';
import DragDropContainer, { DragDropContainerType } from 'common/components/DragDropContainer';

const scope = 'shared.visualizations.panes.presentation.fields.ag_updated_column_format.conditional_options';

interface ConditionalOptionsProps {
  tableColumnFormat: TableColumnFormat;
  viewColumn: ViewColumn;
  onUpdateColumnFormat: (newColumnFormat: TableColumnFormat, columnName: string) => void;
}

const ConditionalOptions = (props: ConditionalOptionsProps) => {
  const { tableColumnFormat, viewColumn, onUpdateColumnFormat } = props;

  const headerTitleText = I18n.t('config_title', { scope });

  const accordionTitle = I18n.t('title', { scope });

  const datePickerLabel = I18n.t('fields.ag_date_range.title', {
    scope: 'shared.visualizations.panes.presentation'
  });
  const backgroundColorLabel = I18n.t('background_label', { scope });
  const textColorLabel = I18n.t('text_label', { scope });
  const addButtonLabel = I18n.t('add_button_label', { scope });

  const [conditionalConfig, setConditionalConfig] = useState<ConditionalConfig[]>(
    tableColumnFormat.conditionalConfig || []
  );

  useEffect(() => {
    setConditionalConfig(tableColumnFormat.conditionalConfig || []);
  }, [tableColumnFormat]);

  const { dataType, isNumberRenderType, isBooleanRenderType, isDateRenderType } = useMemo(() => {
    const dataTypeName = get(viewColumn, 'dataTypeName', SoQLType.SoQLTextT);

    return {
      dataType: dataTypeName,
      isNumberRenderType: isEqual(dataTypeName, SoQLType.SoQLNumberT),
      isBooleanRenderType: isEqual(dataTypeName, SoQLType.SoQLBooleanT),
      isDateRenderType: [
        SoQLType.SoQLFixedTimestampT,
        SoQLType.SoQLFixedTimestampAltT,
        SoQLType.SoQLFloatingTimestampT,
        SoQLType.SoQLFloatingTimestampAltT
      ].includes(dataTypeName)
    };
  }, [tableColumnFormat, viewColumn]);

  const handleUpdateColumnFormat = (updatedConditionalConfig: ConditionalConfig[]) => {
    onUpdateColumnFormat(
      {
        ...tableColumnFormat,
        conditionalConfig: updatedConditionalConfig
      },
      viewColumn.fieldName
    );
  };

  const handleUpdateComplexStyle = (index: number, newStyle: FormatStyle) => {
    const updatedConditionalConfig = conditionalConfig.map((config, i) => {
      if (index === i) {
        return {
          ...config,
          style: newStyle
        };
      }
      return config;
    });
    handleUpdateColumnFormat(updatedConditionalConfig);
  };

  const handleUpdateComplexExpression = (index: number, newExpression: Expression) => {
    const updatedConditionalConfig = conditionalConfig.map((config, i) => {
      if (index === i) {
        return {
          ...config,
          expression: newExpression
        };
      }
      return config;
    });
    handleUpdateColumnFormat(updatedConditionalConfig);
  };

  const handleTextStyleChange = (index: number, textStyle: FontStyleTypes) => {
    const currentConditionalConfig = conditionalConfig[index];
    const style = currentConditionalConfig.style;
    const currentFontStyle = style.fontStyle || defaultFontStyle();
    if (!(textStyle in currentFontStyle)) currentFontStyle[textStyle] = false;
    const updatedStyle = {
      ...style,
      fontStyle: {
        ...currentFontStyle,
        [textStyle]: !currentFontStyle[textStyle]
      }
    } as FormatStyle;
    handleUpdateComplexStyle(index, updatedStyle);
  };

  const handleColorChange = (index: number, key: string, newColor: string) => {
    const currentConditionalConfig = conditionalConfig[index];
    const style = currentConditionalConfig.style;
    const updatedStyle = {
      ...style,
      [key]: newColor
    } as FormatStyle;
    handleUpdateComplexStyle(index, updatedStyle);
  };

  const onConditionSelect = (newCondition: string, index?: number) => {
    index = index || 0;
    const currentConditionalConfig = conditionalConfig[index];
    const expression = currentConditionalConfig.expression;
    const updatedExpression = {
      ...expression,
      function: newCondition,
      arguments: {
        ...expression.arguments
      }
    };
    handleUpdateComplexExpression(index, updatedExpression);
  };

  const onConditionValueChange = (value: string | number, key: string, index?: number) => {
    index = index || 0;
    const currentConditionalConfig = conditionalConfig[index];
    const expression = currentConditionalConfig.expression;
    const updatedExpression = {
      ...expression,
      arguments: {
        ...expression.arguments,
        [key]: value
      }
    };
    handleUpdateComplexExpression(index, updatedExpression);
  };

  const onDateValueChange = (index: number, from: string, to: string) => {
    const currentConditionalConfig = conditionalConfig[index];
    const expression = currentConditionalConfig.expression;
    const updatedExpression = {
      ...expression,
      arguments: {
        ...expression.arguments,
        start: from,
        end: to
      }
    };
    handleUpdateComplexExpression(index, updatedExpression);
  };

  const renderDateRangePicker = (index: number, startValue: any, endValue: any) => {
    const datePickerProps = {
      from: startValue ? startValue : null,
      to: endValue ? endValue : null,
      'on-forge-date-range-picker-change': (event: CustomEvent) => {
        const isRange = !isNull(event.detail.from) && !isNull(event.detail.to);
        if (isRange) {
          // Before formatting, here's a sample format of the data for
          // event.detail.from and event.detail.to: 2004-12-31T16:00:00.000Z.
          // There's a time zone included from the in ForgeDateRangePicker, so
          // we need to format the user input so we only get the date from the
          // timestamp.

          const start = moment(event.detail.from).format(TIME_FORMATS.date);
          const end = moment(event.detail.to).format(TIME_FORMATS.date);
          onDateValueChange(index, start, end);
        }
      },
      'data-testid': `conditional-date-range-picker-${index}`,
      valueMode: 'iso-string'
    };

    return (
      <>
        <BlockLabel htmlFor="conditional-value" title={datePickerLabel} description={''} />
        <ForgeDateRangePicker {...datePickerProps}>
          <ForgeTextField>
            <input
              type="text"
              id={`start-${index}`}
              autoComplete="off"
              placeholder="mm/dd/yyyy"
              data-testid={`start-date-picker-${index}`}
            />
            <input
              type="text"
              id={`end-${index}`}
              autoComplete="off"
              placeholder="mm/dd/yyyy"
              data-testid={`end-date-picker-${index}`}
            />
          </ForgeTextField>
        </ForgeDateRangePicker>
      </>
    );
  };

  const handleAddCondition = () => {
    const updatedConditionalConfig = [...conditionalConfig, defaultConditionalConfig(isDateRenderType)];
    setConditionalConfig(updatedConditionalConfig);
    const updatedColumnFormat = {
      ...tableColumnFormat,
      conditionalConfig: updatedConditionalConfig
    };
    onUpdateColumnFormat(updatedColumnFormat, viewColumn.fieldName);
  };

  const handleDeleteCondition = (index: number) => {
    const updatedConditionalConfig = conditionalConfig.filter((_config, i) => i !== index);
    setConditionalConfig(updatedConditionalConfig);
    const updatedColumnFormat = {
      ...tableColumnFormat,
      conditionalConfig: updatedConditionalConfig
    };
    onUpdateColumnFormat(updatedColumnFormat, viewColumn.fieldName);
  };

  const handleRearrangeConditions = (newConditionalConfig: ConditionalConfig[]) => {
    setConditionalConfig(newConditionalConfig);
    onUpdateColumnFormat(
      {
        ...tableColumnFormat,
        conditionalConfig: newConditionalConfig
      },
      viewColumn.fieldName
    );
  };

  const renderConditionalOptions = () => {
    return conditionalConfig.map((config, index) => {
      // Config -- cell style
      const { fontStyle, backgroundColor, textColor } = config.style;
      // Config -- operators & operands
      const { function: operator } = config.expression;
      const { start: startValue, end: endValue } = config.expression.arguments;

      return (
        <div key={index} className="conditional-config">
          <div className="conditional-config-header">
            <ForgeIconButton densityLevel={3} slot="end" className="delete-condition" dense>
              <button
                type="button"
                data-testid="delete-condition-btn"
                onClick={() => {
                  handleDeleteCondition(index);
                }}
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </div>
          <div className="conditional-config-content">
            {!isEmpty(viewColumn.fieldName) && !isDateRenderType && (
              <>
                <OperatorSelector
                  index={index}
                  condition={operator}
                  dataTypeName={dataType}
                  onConditionSelect={onConditionSelect}
                />
                {!isBooleanRenderType && (
                  <OperandFields
                    index={index}
                    conditionFormat={config.expression}
                    onConditionValueChange={onConditionValueChange}
                    isNumberRenderType={isNumberRenderType}
                  />
                )}
              </>
            )}
            {isDateRenderType && renderDateRangePicker(index, startValue, endValue)}
          </div>
          <div className="cell-style-wrapper">
            <ColorPickerWithLabel
              keyId="fill"
              color={backgroundColor}
              onChangeColor={(newColor: string) => {
                handleColorChange(index, 'backgroundColor', newColor);
              }}
              label={backgroundColorLabel}
              palette={COLORS}
            />
            <ColorPickerWithLabel
              keyId="text"
              color={textColor}
              onChangeColor={(newColor: string) => {
                handleColorChange(index, 'textColor', newColor);
              }}
              label={textColorLabel}
              palette={COLORS}
            />
            <ForgeIconButton dense densityLevel={2} toggle isOn={!!fontStyle?.isBold}>
              <button
                type="button"
                aria-label="bold"
                onClick={() => handleTextStyleChange(index, FontStyleTypes.isBold)}
              >
                <ForgeIcon name="format_bold" forge-icon-button-on />
                <ForgeIcon name="format_bold" />
              </button>
            </ForgeIconButton>
            <ForgeIconButton dense densityLevel={2} toggle isOn={!!fontStyle?.isItalic}>
              <button
                type="button"
                aria-label="italic"
                onClick={() => handleTextStyleChange(index, FontStyleTypes.isItalic)}
              >
                <ForgeIcon name="format_italic" forge-icon-button-on />
                <ForgeIcon name="format_italic" />
              </button>
            </ForgeIconButton>
          </div>
        </div>
      );
    });
  };

  const renderDraggableConditionalOptions = () => {
    const wrappedConditionalElements = conditionalConfig.map((config, index, conditionalConfigArray) => {
      const headerClassName = classNames('conditional-config-title-wrapper', {
        'single-condition': conditionalConfigArray.length === 1
      });
      // Config -- cell style
      const { fontStyle, backgroundColor, textColor } = config.style;
      // Config -- operators & operands
      const { function: operator } = config.expression;
      const { start: startValue, end: endValue } = config.expression.arguments;

      const titleElement = (
        <div className={headerClassName}>
          {conditionalConfigArray.length > 1 && (
            <span className="conditional-config-header-title" slot="leading">
              {headerTitleText} {index + 1}
            </span>
          )}
          {isComplexConditionalFormattingEnabled() && (
            <ForgeIconButton densityLevel={3} slot="trailing" className="delete-condition" dense>
              <button
                type="button"
                onClick={() => {
                  handleDeleteCondition(index);
                }}
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          )}
        </div>
      );

      const mainElement = (
        <div className="conditional-config-content-wrapper">
          <div className="conditional-config-content">
            {!isEmpty(viewColumn.fieldName) && !isDateRenderType && (
              <>
                <OperatorSelector
                  index={index}
                  condition={operator}
                  dataTypeName={dataType}
                  onConditionSelect={onConditionSelect}
                />
                {!isBooleanRenderType && (
                  <OperandFields
                    index={index}
                    conditionFormat={config.expression}
                    onConditionValueChange={onConditionValueChange}
                    isNumberRenderType={isNumberRenderType}
                  />
                )}
              </>
            )}
            {isDateRenderType && renderDateRangePicker(index, startValue, endValue)}
          </div>
          <div className="cell-style-wrapper">
            <ColorPickerWithLabel
              keyId="fill"
              color={backgroundColor}
              onChangeColor={(newColor: string) => {
                handleColorChange(index, 'backgroundColor', newColor);
              }}
              label={backgroundColorLabel}
              palette={COLORS}
            />
            <ColorPickerWithLabel
              keyId="text"
              color={textColor}
              onChangeColor={(newColor: string) => {
                handleColorChange(index, 'textColor', newColor);
              }}
              label={textColorLabel}
              palette={COLORS}
            />
            <ForgeIconButton dense densityLevel={2} toggle isOn={!!fontStyle?.isBold}>
              <button
                type="button"
                aria-label="bold"
                onClick={() => handleTextStyleChange(index, FontStyleTypes.isBold)}
              >
                <ForgeIcon name="format_bold" forge-icon-button-on />
                <ForgeIcon name="format_bold" />
              </button>
            </ForgeIconButton>
            <ForgeIconButton dense densityLevel={2} toggle isOn={!!fontStyle?.isItalic}>
              <button
                type="button"
                aria-label="italic"
                onClick={() => handleTextStyleChange(index, FontStyleTypes.isItalic)}
              >
                <ForgeIcon name="format_italic" forge-icon-button-on />
                <ForgeIcon name="format_italic" />
              </button>
            </ForgeIconButton>
          </div>
        </div>
      );

      return {
        dragAndDroppable: true,
        element: titleElement,
        mainElement: mainElement,
        additionalItemProps: {
          dataTestId: `conditional-element-${index}`
        }
      };
    });

    return (
      <DragDropContainer
        className={'conditional-config'}
        type={DragDropContainerType.CONDITIONS}
        items={conditionalConfig}
        onDrop={handleRearrangeConditions}
        childElements={wrappedConditionalElements}
        useForgeIcon={true}
      />
    );
  };

  return (
    <div className="control-wrapper">
      <AccordionContainer>
        <AccordionPane title={accordionTitle}>
          <div className="conditional-config-container">
            {isComplexConditionalFormattingEnabled()
              ? renderDraggableConditionalOptions()
              : renderConditionalOptions()}
            {isComplexConditionalFormattingEnabled() && (
              <ForgeButton type="outlined" className="add-condition-button">
                <button aria-label={addButtonLabel} onClick={handleAddCondition}>
                  <ForgeIcon name="add" />
                  <span>{addButtonLabel}</span>
                </button>
              </ForgeButton>
            )}
          </div>
        </AccordionPane>
      </AccordionContainer>
    </div>
  );
};

export default ConditionalOptions;
