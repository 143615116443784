// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setColorPalette,
  setColorPaletteProperties,
  swapColorPalette,
  updateCustomColorPalette
} from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';
import ColorPalettePicker from 'common/components/ColorPalettePicker';
import ColorPicker from 'common/components/ColorPicker';
import SwapControls from '../../shared/SwapControls';
import FeatureFlags from 'common/feature_flags';

// Constants
import {
  COLOR_PALETTES,
  COLORS,
  DEFAULT_COLOR_PALETTE,
  NULL_CATEGORY_VALUE
} from '../../../constants';

const scope = 'shared.visualizations.panes.presentation';

export class ScatterChartColorPaletteSelector extends Component {
  renderColorSelectors = () => {
    const { onUpdateCustomColorPalette, onSwapColorPalette, vifAuthoring } = this.props;
    const colorPaletteGroupingColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const customColorPalette = selectors.getCustomColorPalettes(vifAuthoring);
    const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');
    const customPaletteIndexs = _.map(customColorPalette[colorPaletteGroupingColumnName], 'index');
    const maximumIndex = _.max(customPaletteIndexs);

    return _.chain(customColorPalette[colorPaletteGroupingColumnName]).
      filter((palette) => palette.index > -1).
      sortBy('index').
      map((paletteItemValue, key) => {
        const { color, label, id } = paletteItemValue;
        const newLabel = id === NULL_CATEGORY_VALUE ? noValueLabel : label;
        const customColorPaletteAttributes = {
          handleColorChange: (selectedColor) => {
            onUpdateCustomColorPalette(selectedColor, newLabel, colorPaletteGroupingColumnName);
          },
          value: color,
          palette: COLORS
        };
        const swapControlAttributes = {
          className: 'custom-color-picker-swap-button',
          onClick: (fromIndex, toIndex) => {
            onSwapColorPalette(colorPaletteGroupingColumnName, fromIndex, toIndex);
          },
          index: paletteItemValue.index,
          showUpIcon: (paletteItemValue.index !== 0),
          showDownIcon: (paletteItemValue.index !== maximumIndex)
        };

        return (
          <div className="custom-color-container color-palette-selector" key={`${label}-${key}`}>
            <ColorPicker {...customColorPaletteAttributes} />
            <label className="color-value">{label}</label>
            <SwapControls {...swapControlAttributes} />
          </div>
        );
      }).
      value();
  };

  renderColorPaletteSelector = () => {
    const { onSetColorPalette, onSetColorPaletteProperties, vifAuthoring } = this.props;
    const colorPaletteValue = selectors.getColorPalette(DEFAULT_COLOR_PALETTE)(vifAuthoring);

    const hasCustomColorPalette = selectors.hasCustomColorPalette(vifAuthoring);
    const colorPalettesWithCustomOption = [
      ...COLOR_PALETTES,
      { title: I18n.t('shared.visualizations.color_palettes.custom'), value: 'custom' }
    ];
    const colorPaletteAttributes = {
      options: colorPalettesWithCustomOption,
      value: colorPaletteValue,
      showCharm: false,
      onSelectColorPalette: (colorPalette) => {
        onSetColorPalette(colorPalette);
        onSetColorPaletteProperties();
      }
    };

    const colorSelectorMarkup = (
      <div className="custom-palette-container">
        {this.renderColorSelectors()}
      </div>
    );

    return (
      <div>
        <label className="block-label" htmlFor="color-palette">
          {I18n.t('fields.color_palette.title', { scope })}
        </label>
        <ColorPalettePicker {...colorPaletteAttributes} />
        {hasCustomColorPalette && colorSelectorMarkup}
      </div>
    );
  };

  render() {
    return this.renderColorPaletteSelector();
  }
}

ScatterChartColorPaletteSelector.propTypes = {
  onSetColorPalette: PropTypes.func,
  onSetColorPaletteProperties: PropTypes.func,
  onSwapColorPalette: PropTypes.func,
  onUpdateCustomColorPalette: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorPalette: setColorPalette,
  onSwapColorPalette: swapColorPalette,
  onSetColorPaletteProperties: setColorPaletteProperties,
  onUpdateCustomColorPalette: updateCustomColorPalette
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(ScatterChartColorPaletteSelector);
