// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setBaseLayer,
  setBaseLayerOpacity
} from '../../../actions';
import {
  BASE_LAYERS,
  getMapSliderDebounceMs
} from '../../../constants';
import {
  getBaseLayer,
  getBaseLayerOpacity
} from '../../../selectors/vifAuthoring';
import DebouncedSlider from '../../shared/DebouncedSlider';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.presentation';

export class MapLayerSelector extends Component {
  render() {
    const {
      baseLayers,
      onSetBaseLayer,
      onSetBaseLayerOpacity,
      vifAuthoring
    } = this.props;
    const defaultBaseLayer = getBaseLayer(vifAuthoring);
    const defaultBaseLayerOpacity = getBaseLayerOpacity(vifAuthoring);

    const baseLayerAttributes = {
      id: 'base-layer',
      onSelection: (option) => onSetBaseLayer(option.value),
      options: _.map(baseLayers, baseLayer => ({
        title: baseLayer.title, value: baseLayer.legacyMapsTileUrlValue || baseLayer.value })
      ),
      value: defaultBaseLayer
    };

    const baseLayerOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'base-layer-opacity',
      onChange: (baseLayerOpacity) => onSetBaseLayerOpacity(_.round(baseLayerOpacity, 2)),
      rangeMin: 0,
      rangeMax: 1,
      step: 0.1,
      value: defaultBaseLayerOpacity / 100
    };

    return (
      <div>
        <div className="authoring-field">
          <label className="block-label" htmlFor="base-layer">
            {I18n.t('fields.base_layer.title', { scope })}
          </label>
          <div id="base-layer-slider-container" className="base-layer-dropdown-container">
            <Dropdown {...baseLayerAttributes} />
          </div>
        </div>
        <div className="authoring-field">
          <label className="block-label" htmlFor="base-layer-opacity">
            {I18n.t('fields.base_layer_opacity.title', { scope })}
          </label>
          <div id="base-layer-opacity-slider">
            <DebouncedSlider {...baseLayerOpacityAttributes} />
          </div>
        </div>
      </div>
    );
  }
}

MapLayerSelector.defaultProps = {
  baseLayers: BASE_LAYERS
};

MapLayerSelector.propTypes = {
  onSetBaseLayer: PropTypes.func,
  onSetBaseLayerOpacity: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetBaseLayer: setBaseLayer,
  onSetBaseLayerOpacity: setBaseLayerOpacity
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(MapLayerSelector);
