// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setPointOpacity, setPointSize, setPrimaryColor } from '../../../actions';
import { COLORS, getMapSliderDebounceMs } from '../../../constants';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedSlider from '../../shared/DebouncedSlider';
import ColorPicker from 'common/components/ColorPicker';
import I18n from 'common/i18n';

const scope = 'shared.visualizations.panes.presentation';

export class FeatureMapSelector extends Component {

  render() {
    const { onSetPointOpacity, onSetPointSize, onSetPrimaryColor, vifAuthoring } = this.props;
    const pointColor = selectors.getPrimaryColor(vifAuthoring);
    const pointOpacity = selectors.getPointOpacity(vifAuthoring);
    const pointSize = selectors.getPointSize(vifAuthoring);

    const pointColorAttributes = {
      palette: COLORS,
      value: pointColor,
      handleColorChange: (primaryColor) => onSetPrimaryColor(0, primaryColor)
    };

    const pointOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'point-opacity',
      onChange: (pointOpacity) => onSetPointOpacity(_.round(pointOpacity, 2)),
      rangeMax: 1,
      rangeMin: 0,
      step: 0.1,
      value: pointOpacity / 100
    };

    const pointSizeAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'point-size',
      onChange: (pointSize) => onSetPointSize(_.round(pointSize, 2)),
      rangeMax: 3.2,
      rangeMin: 1,
      step: 0.1,
      value: pointSize
    };

    return (
      <div>
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="point-color">
            {I18n.t('fields.point_color.title', { scope })}
          </label>
          <ColorPicker {...pointColorAttributes} />
        </div>
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="point-opacity">
            {I18n.t('fields.point_opacity.title', { scope })}
          </label>
          <div id="point-opacity-slider-container">
            <DebouncedSlider {...pointOpacityAttributes} />
          </div>
        </div>
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="point-size">
            {I18n.t('fields.point_size.title', { scope })}
          </label>
          <div id="point-size-slider-container">
            <DebouncedSlider {...pointSizeAttributes} />
          </div>
        </div>
      </div>
    );
  }
}

FeatureMapSelector.propTypes = {
  onSetPointOpacity: PropTypes.func,
  onSetPointSize: PropTypes.func,
  onSetPrimaryColor: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetPointOpacity: setPointOpacity,
  onSetPointSize: setPointSize,
  onSetPrimaryColor: setPrimaryColor
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(FeatureMapSelector);
