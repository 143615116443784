import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createLogger } from 'redux-logger';

import visualizationCanvas from './reducer';
import { addVisualization } from './actions';

import { ModeStates } from './lib/constants';

const middleware = [thunk, createDebounce()];

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'Visualization Canvas'
    })) ||
  compose;

if (window.serverConfig.environment === 'development') {


  middleware.push(createLogger({
    duration: true,
    timestamp: false,
    collapsed: true
  }));
}

const store = createStore(visualizationCanvas, composeEnhancers(applyMiddleware(...middleware)));

const state = store.getState();
if (state.mode === ModeStates.EDIT && _.isEmpty(state.vifs)) {
  store.dispatch(addVisualization());
}

export default store;
