// Project Imports
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { HierarchyColumnConfig } from 'common/visualizations/vif';

// components
import AddHierarchyLink from './components/AddHierarchyLink';
import { TableHierarchyGroupings } from '../TableHierarchyGroupings';
import { MAX_HIERARCHIES } from 'common/authoring_workflow/constants';
import { AuthoringWorkflowState, VifAuthoring } from 'common/authoring_workflow/reducers/types';
import {
  duplicateTableHierarchy,
  newTableHierarchy,
  removeTableHierarchy,
  resetTableHierarchy,
  setActiveHierarchyId,
  showGrandTotal,
  showSubTotal,
  updateTableHierarchy,
  updateTableHierarchyName
} from 'common/authoring_workflow/actions';

// hooks
import useTableHierarchySelectorHooks from './hooks';
import { getTableColumns } from 'common/authoring_workflow/selectors/vifAuthoring';

export interface TableHierarchySelectorProps {
  vifAuthoring: VifAuthoring;
  setActiveHierarchyId: (hierarchyId: string) => void;
  newTableHierarchy: () => void;
  updateTableHierarchy: (
    existingHierarchyColumnConfigs: HierarchyColumnConfig[],
    hierarchyIndex: number,
    currentColumnConfig?: HierarchyColumnConfig
  ) => void;
  updateTableHierarchyName: (hierarchyIndex: number, name: string) => void;
  duplicateTableHierarchy: (hierarchyIndex: number) => void;
  removeTableHierarchy: (hierarchyIndex: number) => void;
  resetTableHierarchy: (hierarchyIndex: number) => void;
  showGrandTotal: (hierarchyIndex: number, showGrandTotal: boolean) => void;
  showSubTotal: (hierarchyIndex: number, showSubTotal: boolean) => void;
}

export const TableHierarchySelector = (props: TableHierarchySelectorProps): JSX.Element => {
  const { state, functions } = useTableHierarchySelectorHooks(props);

  const { tableHierarchies } = state;
  const {
    handleOnNewHierarchy,
    handleOnUpdateHierarchy,
    handleOnUpdateHierarchyName,
    handleOnDuplicateHierarchy,
    handleOnRearrangeHierarchy,
    handleOnRemoveHierarchy,
    handleOnResetHierarchy,
    handleOnShowGrandTotal,
    handleOnShowSubTotal
  } = functions;
  const tableColumns = getTableColumns(props.vifAuthoring);
  const tableColumnsDontExist = _.isEmpty(tableColumns);
  const disabled = tableHierarchies.length >= MAX_HIERARCHIES || tableColumnsDontExist;
  return (
    <div className="table-hierarchies-container">
      {tableHierarchies.map((_null: any, idx: number) => (
        <TableHierarchyGroupings
          key={idx}
          hierarchyIndex={idx}
          vifAuthoring={props.vifAuthoring}
          setActiveHierarchyId={props.setActiveHierarchyId}
          handleOnDuplicateHierarchy={handleOnDuplicateHierarchy}
          handleOnUpdateHierarchy={handleOnUpdateHierarchy}
          handleOnRearrangeHierarchy={handleOnRearrangeHierarchy}
          handleOnUpdateHierarchyName={handleOnUpdateHierarchyName}
          handleOnRemoveHierarchy={handleOnRemoveHierarchy}
          handleOnResetHierarchy={handleOnResetHierarchy}
          handleOnShowGrandTotal={handleOnShowGrandTotal}
          handleOnShowSubTotal={handleOnShowSubTotal}
        />
      ))}
      <div className="add-table-hierarchy-link">
        <AddHierarchyLink
          tableHierarchies={tableHierarchies}
          disabled={disabled}
          handleOnNewHierarchy={handleOnNewHierarchy}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setActiveHierarchyId,
  newTableHierarchy,
  duplicateTableHierarchy,
  updateTableHierarchy,
  updateTableHierarchyName,
  removeTableHierarchy,
  resetTableHierarchy,
  showGrandTotal,
  showSubTotal
};

const mapStateToProps = (state: AuthoringWorkflowState) => ({
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHierarchySelector);
