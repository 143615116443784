import {
  assign,
  clone,
  cloneDeep,
  each,
  map,
  set,
  isNil,
  findIndex,
  get,
  unset,
  isUndefined,
  uniqueId
} from 'lodash';
import uuidv4 from 'uuid/v4';

import airbrake from 'common/airbrake';
import { assign as windowLocationAssign, pathname as windowLocationPathname } from 'common/window_location';
import getLocalePrefix from 'common/js_utils/getLocalePrefix';

import * as actions from './actions';
import { ModeStates, SaveStates } from './lib/constants';

import { currentUserIsLoggedIn } from 'common/current_user';
import optionallyLocalizeUrls from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import FeatureFlags from 'common/feature_flags';

const AUTHORING_WORKFLOW_INITIAL_STATE = {
  isActive: false
};

const SHARE_MODAL_INITIAL_STATE = {
  isActive: false
};

const SIGNIN_MODAL_CLOSED_STATE = {
  isActive: false
};

const SIGNIN_MODAL_OPEN_STATE = {
  isActive: true
};

const EDIT_PATH_REGEX = /\/edit\/?$/;

// Update the vif at the position currently being edited.
const updateVifs = (state, newVif, index = state.authoringWorkflow.vifIndex) => {
  const updatedVifs = clone(state.vifs);
  const currentVif = updatedVifs[index];

  newVif.id = currentVif && currentVif.id ? currentVif.id : uuidv4();

  updatedVifs[index] = newVif;

  return updatedVifs;
};

// Apply a set of filters to each series of each vif in an array.
const filterVifs = (vifs, filters) => {
  return map(vifs, (vif) => ({
    ...vif,
    series: map(vif.series, (series) => {
      if (series.type === 'map' && !series.primary) {
        // If new gl map type, update filters only for the primary series.
        // In new maps, each series can be from different datasets and the
        // filters are applicable only for the primary series(series with the
        // dataset from which the viz was created/called).
        return series;
      } else {
        return {
          ...series,
          dataSource: {
            ...series.dataSource,
            filters
          }
        };
      }
    })
  }));
};

// Apply a set of currentDrilldownColumnName to each series of each vif in an array.
const currentDrilldownDimensionColumnNameVifs = (vifs, currentDrilldownColumnName) => {
  return map(vifs, (vif) => ({
    ...vif,
    series: map(vif.series, (series) => {
      set(series, 'dataSource.dimension.currentDrilldownColumnName', currentDrilldownColumnName);

      return series;
    })
  }));
};

const currentDisplayDateVifs = (vifs, currentDisplayDate) => {
  return map(vifs, (vif) => ({
    ...vif,
    configuration: {
      ...vif.configuration,
      currentDisplayDate
    }
  }));
};

const setMapNotification = (state, index) => {
  const updatedNotifications = clone(state.mapNotificationDismissed);
  updatedNotifications[index] = true;

  return updatedNotifications;
};

const initialState = () => {
  const state = window.initialState;
  const isEphemeral = isNil(state.view);
  const signinModalState = currentUserIsLoggedIn() ? SIGNIN_MODAL_CLOSED_STATE : SIGNIN_MODAL_OPEN_STATE;

  // We need to retrieve the filter for the primary layer in multi-layer maps, as we have the ability to switch the order of layers.
  let primaryIndex = findIndex(state.vifs[0]?.series, (item) => item.primary);
  // Setting seriesIndex to the old implementation (0) just in case there are no matching primary index.
  primaryIndex = primaryIndex < 0 ? 0 : primaryIndex;
  let vif = cloneDeep(get(state, 'vifs[0]'));
  if (vif) {
    // If there is a vif, migrate it to the latest version.
    vif = migrateVif(vif);
  }
  const vifFilters = get(vif, `series[${primaryIndex}].dataSource.filters`, []);
  // set reactRefIds on the filters
  vifFilters.forEach((filter) => {
    filter.reactKey = uniqueId();
  });
  assign(state, {
    authoringWorkflow: AUTHORING_WORKFLOW_INITIAL_STATE,
    shareModal: SHARE_MODAL_INITIAL_STATE,
    signinModal: signinModalState,
    mode: isEphemeral || EDIT_PATH_REGEX.test(window.location.pathname) ? ModeStates.EDIT : ModeStates.VIEW,
    isEphemeral,
    isDirty: isEphemeral,
    filters: vifFilters,
    saveState: SaveStates.IDLE,
    mapNotificationDismissed: [],
    visualizationUrl: isEphemeral
      ? null
      : `https://${window.location.host}${getLocalePrefix()}/d/${state.view.id}`,
    dataSourceUrl: `https://${window.location.host}${state.parentViewPath}`
  });

  // We used to save the domain in each data source, but that lead to bad behavior. Now we remove the domain
  // in case it was set before we changed the saving behavior.
  each(get(state, 'vifs[0].series'), (series) => {
    unset(series, 'dataSource.domain');
  });

  return state;
};

export default (state = initialState(), action) => {
  if (isUndefined(action)) {
    return state;
  }

  switch (action.type) {
    case actions.GUIDANCE_LOADED:
      return {
        ...state,
        approvalsGuidance: action.guidance
      };
    case actions.ADD_VISUALIZATION: {
      // EN-68996: update this to use parentView.id once we do the db migration
      const parentViewId = FeatureFlags.valueOrDefault('use_vif_dataset_uid_for_vizcan_filters', true)
        ? get(state, 'vifs[0].series[0].dataSource.datasetUid', state.parentView.id)
        : state.parentView.id;
      return {
        ...state,
        authoringWorkflow: {
          isActive: true,
          vifIndex: state.vifs.length,
          // Default starter VIF
          vif: {
            format: {
              type: 'visualization_interchange_format',
              version: 3
            },
            series: [
              {
                dataSource: {
                  datasetUid: parentViewId // EN-68996: update this to use parentView.id once we do the db migration
                }
              }
            ]
          }
        }
      };
    }
    case actions.EDIT_VISUALIZATION:
      return {
        ...state,
        nameModalOpen: false,
        authoringWorkflow: {
          isActive: true,
          vifIndex: action.data.vifIndex,
          vif: state.vifs[action.data.vifIndex]
        }
      };

    case actions.CANCEL_EDITING_VISUALIZATION: {
      if (state.isEphemeral) {
        windowLocationAssign(state.dataSourceUrl || '/profile');
        return {
          ...state,
          navigatingAway: true
        };
      } else {
        return {
          ...state,
          authoringWorkflow: AUTHORING_WORKFLOW_INITIAL_STATE
        };
      }
    }

    case actions.UPDATE_VISUALIZATION: {
      return {
        ...state,
        nameModalOpen: state.isEphemeral, // If ephemeral, ask user to set title and save.
        authoringWorkflow: AUTHORING_WORKFLOW_INITIAL_STATE,
        vifs: updateVifs(state, action.data.vif),
        filters: action.data.filters,
        isDirty: true
      };
    }

    case actions.ENTER_EDIT_MODE: {
      const pathname = windowLocationPathname();
      const hasEditPath = EDIT_PATH_REGEX.test(pathname);

      // Only push /edit onto path for a saved visualization that's not already in edit mode
      if (!state.isEphemeral && !hasEditPath) {
        windowLocationAssign(`${pathname}/edit`);
        return state;
      }

      return {
        ...state,
        mode: ModeStates.EDIT
      };
    }

    case actions.ENTER_PREVIEW_MODE:
      return {
        ...state,
        mode: ModeStates.PREVIEW
      };

    case actions.UPDATE_NAME:
      return {
        ...state,
        view: {
          ...state.view,
          name: action.data.name
        },
        isDirty: true
      };

    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
        vifs: filterVifs(state.vifs, action.filters),
        isDirty: true
      };

    case actions.SET_CURRENT_DRILL_DOWN_COLUMN_NAME:
      return {
        ...state,
        isDirty: true,
        vifs: currentDrilldownDimensionColumnNameVifs(state.vifs, action.currentDrilldownDimensionColumnName)
      };

    case actions.SET_CURRENT_DISPLAY_DATE:
      return {
        ...state,
        vifs: currentDisplayDateVifs(state.vifs, action.currentDisplayDate)
      };

    case actions.SET_MAP_CENTER_AND_ZOOM:
      return {
        ...state,
        isDirty: true,
        vifs: updateVifs(
          state,
          set(state.vifs[action.data.vifIndex], 'configuration.mapCenterAndZoom', action.data.centerAndZoom),
          action.data.vifIndex
        )
      };

    case actions.SET_MAP_PITCH_AND_BEARING:
      return {
        ...state,
        isDirty: true,
        vifs: updateVifs(
          state,
          set(
            state.vifs[action.data.vifIndex],
            'configuration.mapPitchAndBearing',
            action.data.pitchAndBearing
          ),
          action.data.vifIndex
        )
      };

    case actions.SET_MAP_LAYER_VISIBLE:
      return {
        ...state,
        isDirty: true,
        vifs: updateVifs(
          state,
          set(
            state.vifs[action.data.vifIndex],
            `series[${action.data.relativeIndex}].visible`,
            action.data.visible
          ),
          action.data.vifIndex
        )
      };

    case actions.SET_MAP_NOTIFICATION_DISMISSED:
      return {
        ...state,
        mapNotificationDismissed: setMapNotification(state, action.data.vifIndex)
      };

    case actions.REQUESTED_SAVE:
      return {
        ...state,
        navigatingAway: state.isEphemeral,
        saveState: SaveStates.SAVING
      };

    case actions.HANDLE_SAVE_SUCCESS: {
      set(window, 'onbeforeunload', null);
      if (state.isEphemeral) {
        // Handle both ephemeral and saved view case.
        const uidToRedirectTo = get(action, 'response.id') || state.view.id;
        windowLocationAssign(optionallyLocalizeUrls(`/d/${uidToRedirectTo}`));
      }

      return {
        ...state,
        nameModalOpen: false,
        isDirty: false,
        navigatingAway: state.isEphemeral,
        saveState: SaveStates.SAVED
      };
    }

    case actions.HANDLE_SAVE_ERROR:
      airbrake.notify({
        error: action.error,
        context: { component: 'Visualization Canvas Save' }
      });
      return {
        ...state,
        navigatingAway: false,
        saveState: SaveStates.ERRORED
      };

    case actions.CLEAR_SAVE_STATE:
      return {
        ...state,
        navigatingAway: false,
        saveState: SaveStates.IDLE
      };

    case actions.OPEN_SHARE_MODAL:
      return {
        ...state,
        shareModal: {
          isActive: true,
          vifIndex: action.data.vifIndex,
          vif: state.vifs[action.data.vifIndex],
          embedSize: 'large'
        }
      };

    case actions.CLOSE_SHARE_MODAL:
      return {
        ...state,
        shareModal: SHARE_MODAL_INITIAL_STATE
      };

    case actions.CLOSE_SIGNIN_MODAL:
      return {
        ...state,
        signinModal: SIGNIN_MODAL_CLOSED_STATE,
        alert: {
          isActive: true,
          translationKey: 'visualization_canvas.alert_not_signed_in',
          type: 'warning'
        }
      };

    case actions.SIGNIN_FROM_MODAL:
      set(window, 'onbeforeunload', null);
      windowLocationAssign(
        `${getLocalePrefix()}/login?return_to=${encodeURIComponent(windowLocationPathname())}`
      );

      return {
        ...state,
        isDirty: false
      };

    case actions.COPY_FAILED:
      return {
        ...state,
        alert: {
          isActive: true,
          translationKey: 'visualization_canvas.alert_copy_failed',
          type: 'warning'
        }
      };

    case actions.DISMISS_ALERT:
      return {
        ...state,
        alert: null
      };

    case actions.SET_EMBED_SIZE:
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          embedSize: action.size
        }
      };

    default:
      return state;
  }
};
