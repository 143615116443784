import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import Card from './Card';
import { primaryAPICall, removeJsonCodeBlock } from './AIHelpers';

/***********************************************************/
/* This file defines a VizWhizPane component that renders a set
/* of Card components. Each Card represents a different chart
/* with specific chart details such as chart type, dimension,
/* measure, and styling. The onChartSelect prop is passed down
/* to each Card.
/***********************************************************/
export const VizWhizPane = ({ onChartSelect, aiDomainData }) => {
  const [charts, setCharts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const datasetMetadata = {
      'fourfour': aiDomainData ? aiDomainData.fourfour : '',
      'domain': aiDomainData ? aiDomainData.domain : '',
      'type': 0,
      'question': '',
      'chat_history': []
    };

    const getSuggestedPrompts = async () => {
      setLoading(true);
      const response = await primaryAPICall(datasetMetadata);
      const answers = removeJsonCodeBlock(response.answer);
      const jsonAnswers = JSON.parse(answers).questions;
      setLoading(false);
      setCharts(jsonAnswers);
    };

    getSuggestedPrompts();
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: '#e5e5e5', // Adjusted to a light gray to contrast with the card color

      zIndex: 9999
    }}>
      <h1 style={{ textAlign: 'center', paddingBottom: '10px' }}>Recommended Visualizations</h1>
      <p style={{ textAlign: 'center', paddingBottom: '10px', width: '650px' }}>Based on the data within your chosen dataset, we've chosen a selection of visualization that provide interesting views into your data</p>
      <a href='#' style={{paddingBottom: '20px'}} onClick={onChartSelect}>No thanks, I'll make my own</a>
      <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '40px',
          width: '70%',
      }}>
      {charts.map((data, i) => {
          return <Card key={i} requestData={data} onChartSelect={onChartSelect} aiDomainData={aiDomainData}/>;
          })};
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    aiDomainData: {
      fourfour: state.metadataCollection[0].datasetUid,
      domain: window.location.hostname,
      /* Leaving commented for ease of development */
      // 'fourfour': 'hdvc-cgbi',
      // 'domain': 'markwstaging.test-socrata.com'
    },
    vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps)(VizWhizPane);
