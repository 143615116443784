// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FeatureFlags from 'common/feature_flags';

// Project Imports
import { setTreatNullValuesAsZero, setXAxisScalingMode } from '../../../actions';
import {
  getCurrentMetadata,
  hasError,
  isDimensionTypeCalendarDate,
  isLoading,
  isUpdating
} from '../../../selectors/metadata';
import {
  getAnyDimension,
  getDimensionGroupingColumnName,
  getDrilldowns,
  getErrorBarsLowerBoundColumnName,
  getErrorBarsUpperBoundColumnName,
  getNonFlyoutSeries,
  getTableHierarchies,
  getTreatNullValuesAsZero,
  getVisualizationType,
  getXAxisScalingMode,
  hasErrorBars,
  hasMultipleNonFlyoutSeries,
  isBarChart,
  isCalendar,
  isColumnChart,
  isComboChart,
  isGrouping,
  isGroupingOrHasMultipleNonFlyoutSeries,
  isLogarithmicScale,
  isPieChart,
  isScatterChart,
  isTable,
  isTimelineChart
} from 'common/authoring_workflow/selectors/vifAuthoring';
import CalendarOptionsSelector from './CalendarOptionsSelector';
import ComboChartMeasureSelector from './ComboChartMeasureSelector';
import DimensionGroupingColumnNameSelector from './DimensionGroupingColumnNameSelector';
import DimensionGroupingOrderBySelector from './DimensionGroupingOrderBySelector';
import DimensionSelector from '../../shared/DimensionSelector';
import DisplayOptions from './DisplayOptions';
import ErrorBarsOptions from './ErrorBarsOptions';
import MeasureSelector from '../../shared/MeasureSelector';
import RegionSelector from '../../shared/RegionSelector';
import ScatterChartOptionsSelector from './ScatterChartOptionsSelector';
import DrilldownHierarchySelector from './DrilldownHierarchySelector';
import TimelinePrecisionSelector from '../../shared/TimelinePrecisionSelector';
import TableColumnsSelector from './TableColumnsSelector';
import TableHierarchySelector from './TableHierarchySelector';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import BlockLabel from 'common/components/BlockLabel';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.data';

export class DataPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treatNullValuesAsZeroChecked: getTreatNullValuesAsZero(this.props.vifAuthoring)
    };

    this.toggleTreatNullValuesAsZero = this.toggleTreatNullValuesAsZero.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.treatNullValuesAsZero !== prevProps.treatNullValuesAsZero) {
      this.toggleTreatNullValuesAsZero();
    }
  }

  renderMetadataLoading = () => {
    const metadataI18nKey = isUpdating(this.props.metadata) ? 'updating_metadata' : 'loading_metadata';

    return (
      <div className="alert">
        <div className="metadata-loading">
          <span className="spinner-default metadata-loading-spinner"></span>{' '}
          {I18n.t(metadataI18nKey, { scope })}
        </div>
      </div>
    );
  };

  renderMetadataError = () => {
    return (
      <div className="metadata-error alert error">
        <strong>{I18n.t('uhoh', { scope })}</strong> {I18n.t('loading_metadata_error', { scope })}
      </div>
    );
  };

  toggleTreatNullValuesAsZero() {
    this.setState({
      treatNullValuesAsZeroChecked: getTreatNullValuesAsZero(this.props.vifAuthoring) ? true : false
    });
  }

  renderTreatNullValuesAsZero = () => {
    const { onSetTreatNullValuesAsZero, vifAuthoring } = this.props;
    const disabled = isLogarithmicScale(vifAuthoring);
    const containerAttributes = {
      className: classNames('authoring-field checkbox', { disabled }),
      id: 'treat-null-values-as-zero-container'
    };

    const inputAttributes = {
      checked: this.state.treatNullValuesAsZeroChecked,
      id: 'treat-null-values-as-zero',
      onChange: (event) => onSetTreatNullValuesAsZero(event.target.checked),
      type: 'checkbox',
      disabled: disabled
    };

    return (
      <div {...containerAttributes}>
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="treat-null-values-as-zero">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.treat_null_values_as_zero.title', { scope })}
        </label>
      </div>
    );
  };

  renderXAxisScalingMode = () => {
    const { metadata, onSetXAxisScalingMode, vifAuthoring } = this.props;
    const column = getAnyDimension(vifAuthoring);
    const disabled = !isDimensionTypeCalendarDate(metadata, column);
    const checked = getXAxisScalingMode(vifAuthoring) === 'fit';
    const containerAttributes = {
      className: classNames('authoring-field checkbox', { disabled }),
      id: 'x-axis-scaling-mode-container'
    };
    const inputAttributes = {
      checked,
      disabled,
      id: 'x-axis-scaling-mode',
      onChange: (event) => onSetXAxisScalingMode({ shouldFit: event.target.checked }),
      type: 'checkbox'
    };

    return (
      <div {...containerAttributes}>
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="x-axis-scaling-mode">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.x_axis_scaling_mode.title', { scope })}
        </label>
      </div>
    );
  };

  renderGroupingOptions = () => {
    const { vifAuthoring } = this.props;
    const shouldRender =
      !hasMultipleNonFlyoutSeries(vifAuthoring) &&
      (isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) || isTimelineChart(vifAuthoring)) &&
      !hasErrorBars(vifAuthoring);

    const shouldRenderOrderBy = !_.isNil(getDimensionGroupingColumnName(vifAuthoring));
    const orderBySelector = shouldRenderOrderBy ? <DimensionGroupingOrderBySelector /> : null;

    return shouldRender ? (
      <AccordionPane title={I18n.t('fields.dimension_grouping_column_name.title', { scope })}>
        <DimensionGroupingColumnNameSelector />
        {orderBySelector}
      </AccordionPane>
    ) : null;
  };

  renderTimelineOptions = () => {
    const { vifAuthoring } = this.props;
    const shouldRender = isTimelineChart(vifAuthoring);

    return shouldRender ? (
      <AccordionPane title={I18n.t('subheaders.timeline_options', { scope })}>
        <TimelinePrecisionSelector />
        {this.renderTreatNullValuesAsZero()}
        {this.renderXAxisScalingMode()}
      </AccordionPane>
    ) : null;
  };

  renderDisplayOptions = () => {
    const { vifAuthoring } = this.props;
    const shouldRender =
      isBarChart(vifAuthoring) ||
      isPieChart(vifAuthoring) ||
      isColumnChart(vifAuthoring) ||
      isComboChart(vifAuthoring);
    const visualizationType = getVisualizationType(vifAuthoring);
    const translationKeys = {
      barChart: 'bar_chart_limit',
      pieChart: 'pie_chart_limit',
      columnChart: 'column_chart_limit',
      comboChart: 'combo_chart_limit'
    };
    const translationKey = translationKeys[visualizationType];

    return shouldRender ? (
      <AccordionPane title={I18n.t(`fields.${translationKey}.title`, { scope })}>
        <DisplayOptions />
      </AccordionPane>
    ) : null;
  };

  renderErrorBarsOptions = () => {
    const { vifAuthoring } = this.props;
    const shouldRender =
      (isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) || isComboChart(vifAuthoring)) &&
      !isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring);

    return shouldRender ? (
      <AccordionPane title={I18n.t('subheaders.error_bars', { scope })}>
        <ErrorBarsOptions />
      </AccordionPane>
    ) : null;
  };

  renderMeasureSelector = () => {
    const { vifAuthoring } = this.props;
    const nonFlyoutSeries = getNonFlyoutSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });
    const shouldRenderAddMeasureLink =
      isBarChart(vifAuthoring) ||
      isColumnChart(vifAuthoring) ||
      isComboChart(vifAuthoring) ||
      isTimelineChart(vifAuthoring);
    const attributes = {
      isFlyoutSeries: false,
      listItemKeyPrefix: 'DataPane',
      series: nonFlyoutSeries,
      shouldRenderAddMeasureLink,
      showAlreadySelectedColumns: true,
      shouldRenderDeleteMeasureLink: nonFlyoutSeries.length > 1
    };
    const measureSelector = isComboChart(vifAuthoring) ? (
      <ComboChartMeasureSelector {...attributes} />
    ) : (
      <MeasureSelector {...attributes} />
    );

    return (
      <div className="authoring-field">
        <div className="measure-list-container">
          <BlockLabel
            title={I18n.translate('fields.combo_chart_measure_selector.title', { scope })}
            description={I18n.translate('fields.combo_chart_measure_selector.description', { scope })}
          />
          {measureSelector}
        </div>
      </div>
    );
  };

  renderRegionSelector = () => {
    return (
      <div className="authoring-field">
        <RegionSelector />
      </div>
    );
  };

  renderDrilldownHierarchySelector = () => {
    const { vifAuthoring } = this.props;

    if (
      isGrouping(vifAuthoring) ||
      getErrorBarsUpperBoundColumnName(vifAuthoring) ||
      getErrorBarsLowerBoundColumnName(vifAuthoring)
    ) {
      return null;
    }

    return <DrilldownHierarchySelector />;
  };

  renderDimensionSelector = () => {
    const { onChangeVisualizationType } = this.props;
    const selectedDimensionIndicator = (
      <div className="authoring-field" key="selectedDimensionIndicator">
        <BlockLabel
          htmlFor="dimension-selection"
          title={I18n.t('fields.dimension.title', { scope })}
          description={I18n.t('fields.dimension.description', { scope })}
        />
      </div>
    );
    const dimensionSelectorProps = { onChangeVisualizationType };
    const dimensionSelector = (
      <div className="authoring-field" key="dimensionSelector">
        <DimensionSelector {...dimensionSelectorProps} />
        {this.renderDrilldownHierarchySelector()}
      </div>
    );

    return [selectedDimensionIndicator, dimensionSelector];
  };

  renderHierarchyOptions = () => {
    const { vifAuthoring } = this.props;
    const hierarchiesExist = !_.isEmpty(getTableHierarchies(vifAuthoring));
    const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault(
      'enable_flexible_table_hierarchies',
      false
    );

    const nonFlyoutSeries = getNonFlyoutSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });

    const attributes = {
      series: nonFlyoutSeries
    };

    const title = flexibleHierarchiesEnabled
      ? I18n.t('fields.table_hierarchies.flexible_title', { scope })
      : I18n.t('fields.table_hierarchies.title', { scope });

    return (
      <AccordionPane title={title} isOpen={hierarchiesExist}>
        <TableHierarchySelector {...attributes} />
      </AccordionPane>
    );
  };

  render() {
    const { metadata, vifAuthoring } = this.props;
    let metadataInfo;

    if (hasError(metadata)) {
      metadataInfo = this.renderMetadataError();
    } else if (isLoading(metadata)) {
      metadataInfo = this.renderMetadataLoading();
    }
    const shouldRenderChartOptions =
      _.isEmpty(getDrilldowns(vifAuthoring)) &&
      (isBarChart(vifAuthoring) ||
        isColumnChart(vifAuthoring) ||
        isPieChart(vifAuthoring) ||
        isTimelineChart(vifAuthoring));
    const isScatterChartCalendarOrTable =
      isScatterChart(vifAuthoring) || isCalendar(vifAuthoring) || isTable(vifAuthoring);

    const sections = (
      <AccordionContainer>
        <AccordionPane title={I18n.t('subheaders.data_selection', { scope })}>
          {!isScatterChartCalendarOrTable && this.renderDimensionSelector()}
          {!isScatterChartCalendarOrTable && this.renderMeasureSelector()}
          {isScatterChart(vifAuthoring) && <ScatterChartOptionsSelector />}
          {isCalendar(vifAuthoring) && <CalendarOptionsSelector />}
          {!isScatterChartCalendarOrTable && this.renderRegionSelector()}
          {isTable(vifAuthoring) && <TableColumnsSelector />}
        </AccordionPane>
        {!isScatterChartCalendarOrTable && shouldRenderChartOptions && this.renderGroupingOptions()}
        {!isScatterChartCalendarOrTable && this.renderTimelineOptions()}
        {!isScatterChartCalendarOrTable && this.renderDisplayOptions()}
        {!isScatterChartCalendarOrTable && shouldRenderChartOptions && this.renderErrorBarsOptions()}
        {isTable(vifAuthoring) && this.renderHierarchyOptions()}
      </AccordionContainer>
    );

    return <form>{metadataInfo ? metadataInfo : sections}</form>;
  }
}

DataPane.propTypes = {
  metadata: PropTypes.object,
  onChangeVisualizationType: PropTypes.func,
  onSetTreatNullValuesAsZero: PropTypes.func,
  onSetXAxisScalingMode: PropTypes.func,
  vifAuthoring: PropTypes.object,
  treatNullValuesAsZero: PropTypes.bool
};

const mapDispatchToProps = {
  onSetTreatNullValuesAsZero: setTreatNullValuesAsZero,
  onSetXAxisScalingMode: setXAxisScalingMode
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring,
  treatNullValuesAsZero: state.vifAuthoring.vifs.timelineChart.configuration.treatNullValuesAsZero
});

export default connect(mapStateToProps, mapDispatchToProps)(DataPane);
