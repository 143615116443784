import { defaultHeaders, fetchJson } from 'common/http';

/***********************************************************/
/* This function, `removeJsonCodeBlock`, is designed to format a string by removing specific characters.
/*
/* @param string {any} - The string to be formatted.
/*
/* The function performs the following steps:
/* 1. Replaces all occurrences of '```soql' in the string with an empty string.
/* 2. Replaces all occurrences of '```' in the string with an empty string.
/* 3. Replaces all occurrences of three spaces in the string with '",\n  '.
/* 4. Returns the modified string.
/***********************************************************/
export const removeJsonCodeBlock = (string) => {
  return string.replace(/```soql/g, '').replace(/```/g, '')
    .replace(/[ ]{3}/g, '",\n  ');
};

/***********************************************************/
/* This function, `primaryAPICall`, is designed to make an API call to the azure endpoint.
/*
/* @param data {any} - The data to be sent to the API in the request body.
/*
/* The function performs the following steps:
/* 1. Calls the `fetchJson` function with the URL of the API endpoint and an options object.
/* 2. The options object includes the HTTP method ('POST'), headers (including default headers,
/*    'Content-Type' set to 'application/json'), credentials set
/*    to 'same-origin', and the body of the request (which is the stringified `data`).
/* 3. Returns the result of the `fetchJson` call, which should be a Promise resolving to the
/*    JSON response from the API.
/***********************************************************/
export const primaryAPICall = async (data) => {
  return fetchJson('/azure_score_proxy/soql', {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });
};
