import React, { useMemo } from 'react';

import { ForgeIconButton, ForgeIcon, ForgeSwitch } from '@tylertech/forge-react';
import { COLORS } from 'common/authoring_workflow/constants';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import { get, isEmpty } from 'lodash';
import AgGridAlignment from '../AgGridComponents/AgGridAlignment';
import {
  FontStyle,
  FontStyleTypes,
  FormatStyle,
  TableColumnFormat
} from 'common/authoring_workflow/reducers/types';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';
import ColorPickerWithLabel from 'common/components/ColorPickerWithLabel';
import { isColumnStylingInTablesEnabled } from 'common/visualizations/helpers/VifSelectors';

const scope = 'shared.visualizations.panes.presentation.fields.ag_updated_column_format.style_options';

interface StyleOptionsProps {
  tableColumnFormat: TableColumnFormat;
  viewColumn: ViewColumn;
  onUpdateColumnFormat: (newColumnFormat: TableColumnFormat, columnName: string) => void;
  onUpdateColumnFormatStyle: (newColumnFormat: FormatStyle, columnName: string) => void;
}

const StyleOptions = (props: StyleOptionsProps) => {
  const { tableColumnFormat, viewColumn, onUpdateColumnFormat, onUpdateColumnFormatStyle } = props;
  const tableColumnAlignment = useMemo(() => get(tableColumnFormat, 'format'), [tableColumnFormat]);
  const tableColumnFormatStyle = useMemo(() => get(tableColumnFormat, 'style'), [tableColumnFormat]);
  const tableColumnFontStyle = useMemo(
    () => get(tableColumnFormatStyle, 'fontStyle'),
    [tableColumnFormatStyle]
  );

  const backgroundColorLabel = I18n.t('background_label', { scope });
  const textColorLabel = I18n.t('text_label', { scope });
  const accordionTitle = I18n.t('section_title', { scope });
  const togglesTitle = I18n.t('toggles_title', { scope });

  // EN-65689 commented this part because it is out of scope.
  // const wrappedTextTranslation = I18n.t('wrapped_text', { scope });

  const backgroundColor = get(tableColumnFormatStyle, 'backgroundColor', '');
  const textColor = get(tableColumnFormatStyle, 'textColor', '');

  const onApplyFormatChange = (type: string, value: boolean) => {
    onUpdateColumnFormat({ ...tableColumnFormat, [type]: value }, viewColumn.fieldName);
  };

  const onAlignmentChange = (newFormat: ColumnFormat) => {
    onUpdateColumnFormat({ ...tableColumnFormat, format: newFormat }, viewColumn.fieldName);
  };

  const handleTextStyleChange = (textStyle: string) => {
    const currentFontStyle = get(tableColumnFormatStyle, 'fontStyle')!;
    onUpdateColumnFormatStyle(
      {
        ...tableColumnFormatStyle,
        fontStyle: {
          ...currentFontStyle,
          [textStyle]: !currentFontStyle[textStyle]
        }
      },
      viewColumn.fieldName
    );
  };

  const renderToggle = (value: boolean, applyType: string, applyLabel: string) => {
    return (
      <div key={applyType}>
        <ForgeSwitch selected={value} on-forge-switch-select={() => onApplyFormatChange(applyType, !value)}>
          <span className="ms-1">{applyLabel}</span>
        </ForgeSwitch>
      </div>
    );
  };

  const renderToggles = () => {
    const alignmentDefaults = {
      isFormatValue: { value: true, label: I18n.t('toggle.values', { scope }) },
      isAlignHeader: { value: false, label: I18n.t('toggle.headers', { scope }) },
      isFormatTotal: { value: false, label: I18n.t('toggle.totals', { scope }) }
      // EN-65690 - commented this part because it is out of scope
      // uncomment this line when there's a finished discussion regarding this feature.
      // isFormatSubtotal: { value: false, label: I18n.t('toggle.subtotals', { scope }) }
    };

    return (
      <>
        <BlockLabel title={togglesTitle} description={''} />
        <div className="ag-grid-apply-format">
          {Object.entries(alignmentDefaults).map(([key, alignmentDefault]) =>
            renderToggle(get(tableColumnFormat, key, alignmentDefault.value), key, alignmentDefault.label)
          )}
        </div>
      </>
    );
  };

  const renderColumnStyling = () => {
    if (isColumnStylingInTablesEnabled()) {
      return (
        <>
          <ColorPickerWithLabel
            keyId="fill"
            color={backgroundColor}
            onChangeColor={(newColor: string) => {
              onUpdateColumnFormatStyle(
                {
                  ...tableColumnFormatStyle,
                  backgroundColor: newColor
                },
                viewColumn.fieldName
              );
            }}
            label={backgroundColorLabel}
            palette={COLORS}
          />
          <ColorPickerWithLabel
            keyId="text"
            color={textColor}
            onChangeColor={(newColor: string) => {
              onUpdateColumnFormatStyle(
                {
                  ...tableColumnFormatStyle,
                  textColor: newColor
                },
                viewColumn.fieldName
              );
            }}
            label={textColorLabel}
            palette={COLORS}
          />
          <ForgeIconButton dense densityLevel={2} toggle isOn={!!tableColumnFontStyle?.isBold}>
            <button
              type="button"
              aria-label="bold"
              onClick={() => handleTextStyleChange(FontStyleTypes.isBold)}
            >
              <ForgeIcon name="format_bold" forge-icon-button-on />
              <ForgeIcon name="format_bold" />
            </button>
          </ForgeIconButton>
          <ForgeIconButton dense densityLevel={2} toggle isOn={!!tableColumnFontStyle?.isItalic}>
            <button
              type="button"
              aria-label="italic"
              onClick={() => handleTextStyleChange(FontStyleTypes.isItalic)}
            >
              <ForgeIcon name="format_italic" forge-icon-button-on />
              <ForgeIcon name="format_italic" />
            </button>
          </ForgeIconButton>
        </>
      );
    }
  };

  return (
    <div className="control-wrapper">
      <AccordionContainer>
        <AccordionPane key="styleOptions" title={accordionTitle}>
          {renderToggles()}
          <AgGridAlignment
            align={isEmpty(viewColumn.fieldName) ? 'left' : get(tableColumnAlignment, 'align', 'left')}
            onAlignmentChange={(newAlign: string) =>
              onAlignmentChange({ ...tableColumnAlignment, align: newAlign })
            }
          />
          <div className="cell-style-wrapper">
            {renderColumnStyling()}
            {/* EN-65689 commented this part because it is out of scope.
                uncomment these when there's a finished discussion regarding this feature.
            <div className="wrap-switch-container">
              <ForgeSwitch
                selected={!!tableColumnFontStyle?.isWrapped}
                on-forge-switch-select={() => handleTextStyleChange('isWrapped')}
              >
                <span className="ms-1">{wrappedTextTranslation}</span>
              </ForgeSwitch>
            </div> */}
          </div>
        </AccordionPane>
      </AccordionContainer>
    </div>
  );
};

export default StyleOptions;
