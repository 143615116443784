import React from 'react';
import { ForgeSelect } from '@tylertech/forge-react';
import { includes, map } from 'lodash';

import { TIME_FORMAT_TITLES } from 'common/DataTypeFormatter';
import { AgGridColumnDataFormat } from 'common/authoring_workflow/constants';
import { ColumnFormat } from 'common/types/viewColumn';
import I18n from 'common/i18n';

//constants
const scope = 'shared.visualizations.panes.presentation';

export interface AgGridDateFormatProps {
  dataType: string;
  format: ColumnFormat;
  onDateFormatChange: (newColumnFormat: ColumnFormat) => void;
}

const AgGridDateFormat = (props: AgGridDateFormatProps): JSX.Element => {
  const { dataType, format, onDateFormatChange } = props;
  const dateFormat = [AgGridColumnDataFormat.DATE, AgGridColumnDataFormat.CALENDAR];
  const isDateColumn = includes(dateFormat, dataType);
  const dateFormatLabel = I18n.t('fields.ag_column_date_format.title', { scope });

  const utc_subtitles = {
    'iso_8601_date': "ISO8601 date in the user's local timezone",
    'iso_8601_date_time': "ISO8601 date and time in the user's local timezone",
    'iso_8601_date_utc': 'ISO8601 date in UTC',
    'iso_8601_date_time_utc': 'ISO8601 date and time in UTC',
  };

  const getSubTitles = (key: string) => {
    return utc_subtitles[key];
  };

  const optionBuilder = (option: { label: string, value: string }) => {
    return `<forge-list-item>
                <span slot="title">${option.label}</span>
                <span slot="subtitle">${ getSubTitles(option.value) || '' }</span>
            </forge-list-item>`;
  };

  const renderDataFormat = () => {
    const options = map(TIME_FORMAT_TITLES, (type) => ({ label: type.title, value: type.value }));
    const dropdownProps = {
      onChange: (event: CustomEvent) => {
        onDateFormatChange({ ...format, view: event.detail });
      },
      options: options,
      label: dateFormatLabel,
      className: 'forge-select-date-format',
      value: format.view || null,
      optionBuilder: optionBuilder
    };
    return (
      <ForgeSelect {...dropdownProps} />
    );
  };

  return (
    <div className='date-format authoring-field'>
      {isDateColumn && renderDataFormat()}
    </div>
  );
};

export default AgGridDateFormat;
