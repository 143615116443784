import React, { useState, useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { VisualizationRenderer } from 'common/visualizations/VisualizationRenderer';
import { setFilters } from '../../../actions';
import { load } from 'common/authoring_workflow/vifs/loader';
import { getFilters } from 'common/visualizations/helpers/VifSelectors';

/***********************************************************/
/* This Card component fetches data from an API endpoint when
/* requestData prop changes, handles loading and error states,
/* and renders the fetched data using a VisualizationRenderer. It
/* also provides a button to select a Visualization Information
/* Format (VIF) and dispatches actions to load the VIF and set
/* filters based on it.
/***********************************************************/
const Card = ({ requestData, onChartSelect, aiDomainData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [error, setError] = useState(null);
  const [vifText, setVifText] = useState(null);

  /***********************************************************/
  /* This is a React useEffect hook that runs whenever the `requestData` changes.
  /*
  /* The hook performs the following steps:
  /* 1. Defines the endpoint URL and the payload for the API call.
  /* 2. The payload includes the 'fourfour' code, the 'question' (which is the `requestData`), the 'domain', an empty
  /*    'chat_history' array, and 'return_suggestions' set to false.
  /* 3. Defines an asynchronous function, `fetchData`, to make the API call.
  /* 4. Inside `fetchData`, it tries to make a POST request to the endpoint with the headers and the stringified payload.
  /* 5. If the response is not OK (status is not 200), it throws an error with the status.
  /* 6. If the response is OK, it parses the response as JSON and sets the `data` state with the result.
  /* 7. If there's an error during the API call or the response parsing, it sets the `error` state with the error message.
  /* 8. Finally, it sets the `loading` state to false, indicating that the API call is finished.
  /* 9. Calls `fetchData` to initiate the API call.
  /***********************************************************/
  useEffect(() => {
    const endpoint = '/azure_score_proxy/vif';
    const payload = {
      'fourfour': aiDomainData ? aiDomainData.fourfour : '',
      'domain': aiDomainData ? aiDomainData.domain : '',
      'question': requestData,
      'chat_history': [],
      'return_suggestions': false};
    let isMounted = true; // this is to stop a "Can't perform a React state update on an unmounted component" error

    const fetchData = async () => {
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'azureml-model-deployment': 'vif-4'
            // Add any other headers here
          },
          body: JSON.stringify(payload) // Make sure to stringify your payload
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (isMounted) setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    fetchData();
    return () => { isMounted = false; };
  }, [requestData]);

  const handleRef = (domElement) => {
    if (domElement) {
      const vizRenderer = new VisualizationRenderer(data.answer, domElement);
      setVifText(data.answer);
    }
  };

  const dispatch = useDispatch();

  const selectVif = () => {
    const vif = data.answer;
    load(dispatch,vif);
    const filters = getFilters(vif);
    dispatch(setFilters(filters));
    onChartSelect();
  };

  if (loading) {
    return (
      <div style={{
        // Adjust styles as needed
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        height: '350px',
        position: 'relative'
      }}>
        <h2>Loading...</h2>
        <span className="spinner-default" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{
        // Adjust styles as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '290px',
      }}>
        <p>Error: {error}</p>
      </div>
    );
  }
  return (
    <div style={{
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      backgroundColor: '#ffffff',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '350px',
      position: 'relative'
    }}>
      <div ref={handleRef} >
        {/* VisualizationRenderer will render the visualization into this div */}
      </div>
      <button
      onClick={selectVif}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1000,
      }}
      >
        Choose
      </button>
    </div>
  );
};

export default Card;
