import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import getLocalePrefix from 'common/js_utils/getLocalePrefix';
import { closeShareModal, setEmbedSize } from '../actions';
import Button, { VARIANTS } from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import I18n from 'common/i18n';
import { components as SocrataVisualizations } from 'common/visualizations';
import generateEmbedCode, { generateIframeEmbedCode } from '../../visualization_embed/embedCodeGenerator';
import { EMBED_SIZES } from 'common/visualizations/views/SvgConstants';

const EMBED_TYPES = {
  IFRAME: 'embed',
  JS: 'js'
};

const SOURCE_DATA_TYPES = {
  VIEW: 'View Source Data',
  EXPORT: 'Export Data',
  NONE: 'None'
};

const selectOnFocus = (event) => {
  // We need to call select() in a setTimeout because of a bug in Edge.  Other
  // browsers cause the selection to appear by calling select() immediately.
  // See https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8229660/
  const textarea = event.target;
  return setTimeout(() => {
    textarea.select();
  }, 1);
};

export class ShareVisualizationModal extends Component {
  state = { currentEmbedType: EMBED_TYPES.IFRAME, sourceDataOption: SOURCE_DATA_TYPES.VIEW };

  componentDidUpdate() {
    $(this.visualizationContainer)
      .find('.socrata-visualization')
      .trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }

  renderLinkField() {
    const { copyableLinkUrl } = this.props;

    const linkProps = {
      id: 'share-link-field',
      type: 'text',
      value: copyableLinkUrl,
      onFocus: selectOnFocus,
      readOnly: true
    };

    const field = <input {...linkProps} />;

    const notAvailableMessage = (
      <div className="alert info">{I18n.t('visualization_canvas.share_modal.no_web_link_available')}</div>
    );

    return (
      <div>
        <label htmlFor="share-link-field" className="block-label">
          {I18n.t('visualization_canvas.share_modal.web_link')}
        </label>
        {copyableLinkUrl ? field : notAvailableMessage}
      </div>
    );
  }

  renderEmbedCodeField() {
    const { jsEmbedCode, iframeEmbedCode } = this.props;
    const { currentEmbedType } = this.state;

    const textareaProps = {
      id: 'share-embed-code-field',
      value: currentEmbedType === EMBED_TYPES.JS ? jsEmbedCode : iframeEmbedCode,
      onFocus: selectOnFocus,
      readOnly: true
    };

    return (
      <div role="tabpanel">
        {this.renderEmbedTabs()}
        <label htmlFor="share-embed-code-field" className="block-label">
          {I18n.t('visualization_canvas.share_modal.embed_code')}
        </label>
        <textarea {...textareaProps} />
      </div>
    );
  }

  renderEmbedTabs() {
    const { currentEmbedType } = this.state;

    const tabs = [
      {
        classNames: classNames('tab-link', 'iframe-embed-tab', {
          current: currentEmbedType === EMBED_TYPES.IFRAME
        }),
        key: 'iframe-embed',
        onClick: () => this.setState({ currentEmbedType: EMBED_TYPES.IFRAME }),
        tabName: I18n.t('visualization_canvas.share_modal.iframe_embed_tab')
      },
      {
        classNames: classNames('tab-link', 'js-embed-tab', { current: currentEmbedType === EMBED_TYPES.JS }),
        key: 'js-embed',
        onClick: () => this.setState({ currentEmbedType: EMBED_TYPES.JS }),
        tabName: I18n.t('visualization_canvas.share_modal.js_embed_tab')
      }
    ];

    return (
      <div role="tablist" className="nav-tabs">
        {tabs.map((tab) => (
          <div key={tab.key} className={tab.classNames}>
            <Button variant={VARIANTS.LINK} onClick={tab.onClick} role="tab">
              {tab.tabName}
            </Button>
          </div>
        ))}
      </div>
    );
  }

  renderSizeField() {
    const options = EMBED_SIZES.map(({ name, width, height }) => ({
      title: `${I18n.t(`visualization_canvas.share_modal.sizes.${name}`)} (${width}x${height})`,
      value: name
    }));

    const dropdownProps = {
      onSelection: this.props.onChooseEmbedSize,
      options,
      value: this.props.embedSize
    };

    return (
      <div>
        <div>{I18n.t('visualization_canvas.share_modal.size_options')}</div>
        <Dropdown {...dropdownProps} />
      </div>
    );
  }

  renderPreview() {
    const { embedSize, vif } = this.props;
    const { width, height, className } = _.find(EMBED_SIZES, { name: embedSize });
    const previewContainerClassName = classNames(className, 'socrata-visualization-embed');

    const props = {
      options: {
        displayFilterBar: true,
        toggleMapLayersInternally: true
      },
      vif
    };

    return (
      <div>
        {I18n.t('visualization_canvas.share_modal.preview')}
        <div
          className={previewContainerClassName}
          style={{ width, height }}
          ref={(vis) => (this.visualizationContainer = vis)}
        >
          <SocrataVisualizations.Visualization {...props} />
        </div>
      </div>
    );
  }

  render() {
    const { isActive, onDismiss } = this.props;

    if (!isActive) {
      return null;
    }

    return (
      <Modal className="share-modal" onDismiss={onDismiss}>
        <ModalHeader title={I18n.t('visualization_canvas.share_modal.title')} onDismiss={onDismiss} />

        <ModalContent>
          <form>
            {this.renderLinkField()}
            {this.renderEmbedCodeField()}
            <div className="config-options">{this.renderSizeField()}</div>
          </form>
          {this.renderPreview()}
        </ModalContent>

        <ModalFooter>
          <button className="btn btn-sm btn-default" onClick={onDismiss}>
            {I18n.t('visualization_canvas.share_modal.close')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ShareVisualizationModal.propTypes = {
  // Whether or not the modal is active.
  // If false, nothing is rendered.
  isActive: PropTypes.bool.isRequired,

  // Embed code to display to the user.
  jsEmbedCode: PropTypes.string.isRequired,

  // Embed code to display to the user.
  iframeEmbedCode: PropTypes.string.isRequired,

  // Link to display in the "Web Link" box. If not provided, explanatory info
  // text will be rendered.
  copyableLinkUrl: PropTypes.string,

  // Size name to select in the dropdown. See EMBED_SIZES.
  embedSize: PropTypes.oneOf(_.map(EMBED_SIZES, 'name')),

  // Called when the modal is dismissed.
  onDismiss: PropTypes.func.isRequired,

  // Called when the user selects a new embed size.
  onChooseEmbedSize: PropTypes.func.isRequired,

  // VIF to share.
  vif: PropTypes.object
};

function mapStateToProps(state) {
  const { isActive, embedSize, vif } = state.shareModal;
  const { width, height } = _.find(EMBED_SIZES, { name: embedSize }) || {};

  // state.view can be null if the vizcan hasn't been saved yet
  const vizcanUid = _.get(state, 'view.id');

  const jsEmbedCode = generateEmbedCode(vif, {
    width,
    height,
    vizcanUid,
    sourceHref: `${state.dataSourceUrl}?referrer=embed`,
    fallbackSourceLinkText: I18n.t('visualization_canvas.share_modal.fallback_link_text')
  });
  const iframeEmbedCode = generateIframeEmbedCode(
    window.location.host,
    vizcanUid,
    _.get(state, 'view.name'),
    { width, height }
  );

  return {
    jsEmbedCode,
    iframeEmbedCode,
    embedSize,
    isActive,
    copyableLinkUrl: state.visualizationUrl,
    vif
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onDismiss: closeShareModal,
      onChooseEmbedSize: (option) => setEmbedSize(option.value)
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareVisualizationModal);
