// Vendor Imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setErrorBarsBarColor,
  setErrorBarsLowerBoundColumnName,
  setErrorBarsUpperBoundColumnName
} from '../../../actions';
import { getCurrentMetadata, getValidMeasures, hasData } from '../../../selectors/metadata';
import {
  getErrorBarsColor,
  getErrorBarsLowerBoundColumnName,
  getErrorBarsUpperBoundColumnName
} from '../../../selectors/vifAuthoring';
import ColorPicker from 'common/components/ColorPicker';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

// Constants
import { COLORS } from '../../../constants';

export class ErrorBarsOptions extends Component {
  renderErrorBarsSelector() {
    const {
      onSetErrorBarsBarColor,
      onSetErrorBarsLowerBoundColumnName,
      onSetErrorBarsUpperBoundColumnName,
      vifAuthoring
    } = this.props;

    const lowerBoundColumnName = getErrorBarsLowerBoundColumnName(vifAuthoring);
    const upperBoundColumnName = getErrorBarsUpperBoundColumnName(vifAuthoring);

    const lowerBoundDropdown = this.renderErrorBarsDropdown(
      'error-bars-lower-bound-column-selection',
      lowerBoundColumnName,
      onSetErrorBarsLowerBoundColumnName
    );

    const upperBoundDropdown = this.renderErrorBarsDropdown(
      'error-bars-upper-bound-column-selection',
      upperBoundColumnName,
      onSetErrorBarsUpperBoundColumnName
    );

    const color = getErrorBarsColor(vifAuthoring);

    const colorPickerAttributes = {
      handleColorChange: onSetErrorBarsBarColor,
      id: 'error-bars-bar-color-picker',
      palette: COLORS,
      value: color
    };

    return (
      <div>
        <div className="authoring-field">
          <label className="block-label" htmlFor="error-bar-lower-bound">
            {I18n.t('shared.visualizations.panes.data.fields.error_bars.lower_bound_column')}
          </label>
          <div id="error-bar-lower-bound" className="authoring-field">
            {lowerBoundDropdown}
          </div>
        </div>
        <div className="authoring-field">
          <label className="block-label" htmlFor="error-bar-upper-bound">
            {I18n.t('shared.visualizations.panes.data.fields.error_bars.upper_bound_column')}
          </label>
          <div id="error-bar-upper-bound" className="authoring-field">
            {upperBoundDropdown}
          </div>
        </div>
        <div className="authoring-field">
          <label className="block-label" htmlFor="error-bars-bar-color-picker">
            {I18n.t('shared.visualizations.panes.data.fields.error_bars.bar_color')}
          </label>
          <ColorPicker {...colorPickerAttributes} />
        </div>
      </div>
    );
  }

  renderErrorBarsDropdown(id, value, onSelection) {
    const { metadata } = this.props;
    const measures = getValidMeasures(metadata);

    const attributes = {
      disabled: measures.length == 0,
      id,
      onSelection: (option) => onSelection(option.value),
      options: [
        { title: I18n.t('shared.visualizations.panes.data.fields.error_bars.none_selected'), value: null },
        ...measures.map((measure) => ({
          title: measure.name,
          value: measure.fieldName
        }))
      ],
      placeholder: I18n.translate('shared.visualizations.panes.data.fields.error_bars.select_column'),
      value
    };

    return <Dropdown {...attributes} />;
  }

  render() {
    return hasData(this.props.metadata) ? this.renderErrorBarsSelector() : null;
  }
}

ErrorBarsOptions.propTypes = {
  metadata: PropTypes.object,
  onSetErrorBarsBarColor: PropTypes.func,
  onSetErrorBarsLowerBoundColumnName: PropTypes.func,
  onSetErrorBarsUpperBoundColumnName: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetErrorBarsBarColor: setErrorBarsBarColor,
  onSetErrorBarsLowerBoundColumnName: setErrorBarsLowerBoundColumnName,
  onSetErrorBarsUpperBoundColumnName: setErrorBarsUpperBoundColumnName
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBarsOptions);
