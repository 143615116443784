// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setStacked } from '../../../actions';
import { getCurrentMetadata } from '../../../selectors/metadata';
import {
  hasDimensionGroupingColumnName,
  hasMultipleNonFlyoutSeries,
  isComboChart,
  isOneHundredPercentStacked,
  isStacked
} from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';

export class GroupedStackedSelector extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'renderGroupedContainer',
      'renderStackedContainer',
      'renderOneHundredPercentStackedContainer'
    ]);
  }

  render() {
    const { vifAuthoring } = this.props;

    const stacked = isStacked(vifAuthoring);
    const oneHundredPercentStacked = isOneHundredPercentStacked(vifAuthoring);
    const disabled = !hasDimensionGroupingColumnName(vifAuthoring) && !hasMultipleNonFlyoutSeries(vifAuthoring);

    const groupedContainer = this.renderGroupedContainer({ checked: !stacked, disabled });
    const stackedContainer = this.renderStackedContainer({ checked: stacked && !oneHundredPercentStacked, disabled });
    const oneHundredPercentStackedContainer = this.renderOneHundredPercentStackedContainer({ checked: oneHundredPercentStacked, disabled });

    return (
      <div className="grouped-stacked-selector-container">
        <div className="authoring-field">
          <span>{I18n.t('shared.visualizations.panes.data.fields.dimension_grouping_options.title')}</span>
          <div className="radiobutton">
            {groupedContainer}
            {stackedContainer}
            {oneHundredPercentStackedContainer}
          </div>
        </div>
      </div>
    );
  }

  renderGroupedContainer({ checked, disabled }) {
    const containerAttributes = {
      id: 'grouping-display-grouped-container',
      className: `${disabled ? 'disabled' : null}`
    };

    const inputAttributes = {
      id: 'display-grouped',
      type: 'radio',
      name: 'display-grouped-radio',
      onChange: () => this.props.onSetStacked({ stacked: false, oneHundredPercent: false }),
      checked,
      disabled
    };

    return (
      <div {...containerAttributes}>
        <input {...inputAttributes} />
        <label htmlFor="display-grouped">
          <span className="fake-radiobutton" />
        </label>
        {I18n.t('shared.visualizations.panes.data.fields.dimension_grouping_options.grouped')}
      </div>
    );
  }

  renderStackedContainer({ checked, disabled }) {
    const containerAttributes = {
      id: 'grouping-display-stacked-container',
      className: `${disabled ? 'disabled' : null}`
    };

    const inputAttributes = {
      id: 'display-stacked',
      type: 'radio',
      name: 'display-grouped-radio',
      onChange: () => this.props.onSetStacked({ stacked: true, oneHundredPercent: false }),
      checked,
      disabled
    };

    return (
      <div {...containerAttributes}>
        <input {...inputAttributes} />
        <label htmlFor="display-stacked">
          <span className="fake-radiobutton" />
        </label>
        {I18n.t('shared.visualizations.panes.data.fields.dimension_grouping_options.stacked')}
      </div>
    );
  }

  renderOneHundredPercentStackedContainer({ checked, disabled }) {
    const shouldRender = !isComboChart(this.props.vifAuthoring);

    if (!shouldRender) {
      return null;
    }

    const containerAttributes = {
      id: 'grouping-display-100-percent-stacked-container',
      className: `${disabled ? 'disabled' : ''}`
    };

    const inputAttributes = {
      id: 'display-100-percent-stacked',
      type: 'radio',
      name: 'display-grouped-radio',
      onChange: () => this.props.onSetStacked({ stacked: true, oneHundredPercent: true }),
      checked,
      disabled
    };

    return (
      <div {...containerAttributes}>
        <input {...inputAttributes} />
        <label htmlFor="display-100-percent-stacked">
          <span className="fake-radiobutton" />
        </label>
        {I18n.t('shared.visualizations.panes.data.fields.dimension_grouping_options.one_hundred_percent_stacked')}
      </div>
    );
  }
}

GroupedStackedSelector.propTypes = {
  metadata: PropTypes.object,
  onSetStacked: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetStacked: setStacked
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedStackedSelector);
