// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setUnitsOne, setUnitsOther } from '../../actions';
import { getMeasureTitle } from '../../helpers';
import { VIF_CONSTANTS } from '../../constants';
import {
  getCurrentSeriesIndex,
  getNonFlyoutSeries,
  getSeries,
  isNewGLMap,
  isScatterChart
} from '../../selectors/vifAuthoring';
import DebouncedInput from './DebouncedInput';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.legends_and_flyouts.fields';

export class FlyoutUnitsSelector extends Component {
  getUnitOneAttributes(index, value, placeholder) {
    return {
      className: 'text-input',
      id: `units-one-${index}`,
      placeholder,
      type: 'text',
      value,
      onChange: (event) => this.props.onSetUnitsOne(index, event.target.value)
    };
  }

  getUnitOtherAttributes(index, value, placeholder) {
    return {
      className: 'text-input',
      id: `units-other-${index}`,
      placeholder,
      type: 'text',
      value,
      onChange: (event) => this.props.onSetUnitsOther(index, event.target.value)
    };
  }

  renderFlyoutUnits() {
    const { vifAuthoring, metadata } = this.props;
    let nonFlyoutSeries = getNonFlyoutSeries(vifAuthoring);
    if (isScatterChart(vifAuthoring)) {
      nonFlyoutSeries = _.take(nonFlyoutSeries, VIF_CONSTANTS.DEFAULT_SCATTER_CHART_FLYOUT_SERIES);
    }

    const unitControls = nonFlyoutSeries.map((item, index) => {
      const unitOne = _.get(item, 'unit.one', '');
      const unitOther = _.get(item, 'unit.other', '');
      const measureTitle = getMeasureTitle(metadata, item);

      return this.renderFlyoutUnitsForSeries(
        index,
        measureTitle,
        this.getUnitOneAttributes(index, unitOne, ''),
        this.getUnitOtherAttributes(index, unitOther, '')
      );
    });

    return unitControls;
  }

  renderFlyoutUnitsForNewGLMaps() {
    const { vifAuthoring } = this.props;
    const index = getCurrentSeriesIndex(vifAuthoring);
    const item = getSeries(vifAuthoring)[index];
    const unitOne = _.get(item, 'unit.one', '');
    const unitOther = _.get(item, 'unit.other', '');

    return this.renderFlyoutUnitsForSeries(
      index,
      null,
      this.getUnitOneAttributes(index, unitOne, I18n.t('placeholders.row', { scope })),
      this.getUnitOtherAttributes(index, unitOther, I18n.t('placeholders.rows', { scope }))
    );
  }

  renderFlyoutUnitsForSeries(seriesIndex, measureTitle, unitOneAttributes, unitOtherAttributes) {
    const containerAttributes = {
      id: `units-container-${seriesIndex}`,
      className: 'units-container',
      key: seriesIndex
    };
    const unitsOneLabelAttributes = {
      className: 'block-label',
      htmlFor: `units-one-${seriesIndex}`
    };
    const unitsOtherLabelAttributes = {
      className: 'block-label',
      htmlFor: `units-other-${seriesIndex}`
    };

    return (
      <div {...containerAttributes}>
        {measureTitle && <p>{measureTitle}</p>}
        <div className="authoring-field unit-container">
          <label {...unitsOneLabelAttributes}>{I18n.t('units_one.title', { scope })}</label>
          <DebouncedInput {...unitOneAttributes} />
        </div>
        <div className="authoring-field unit-container">
          <label {...unitsOtherLabelAttributes}>{I18n.t('units_other.title', { scope })}</label>
          <DebouncedInput {...unitOtherAttributes} />
        </div>
      </div>
    );
  }

  render() {
    const flyoutUnits = isNewGLMap(this.props.vifAuthoring) ?
      this.renderFlyoutUnitsForNewGLMaps() :
      this.renderFlyoutUnits();

    return (
      <div className="flyout-units-selectors">
        {flyoutUnits}
      </div>
    );
  }
}

FlyoutUnitsSelector.propTypes = {
  metadata: PropTypes.object,
  onSetUnitsOne: PropTypes.func,
  onSetUnitsOther: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetUnitsOne: setUnitsOne,
  onSetUnitsOther: setUnitsOther
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(FlyoutUnitsSelector);
