// Vendor Imports
import _ from 'lodash';
import React from 'react';

// Project Imports
import I18n from 'common/i18n';
import { SoQLType } from 'common/types/soql';
import Button, { VARIANTS } from 'common/components/Button';
import { ForgeAutocomplete, ForgeTextField, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';
import { IListItemComponent, IAutocompleteOptionGroup } from '@tylertech/forge';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata.js';

const DeleteColumnLink: React.FC<{
  [prop: string]: any;
}> = (props) => {
  return (
    <div className="delete-link-container">
      <ForgeIconButton slot="end" dense={true} densityLevel={6} className="delete-selected-column-button">
        <Button
          className="delete-link"
          variant={VARIANTS.LINK}
          {...props}
          aria-label={I18n.translate('shared.visualizations.panes.data.fields.columns.remove')}
        >
          <ForgeIcon name="clear" />
        </Button>
      </ForgeIconButton>
    </div>
  );
};

export interface DropdownOption {
  title: string;
  value: string;
  type: SoQLType;
  group?: string;
  label?: string;
  render: (option: { type: string; title: string }) => JSX.Element;
}

export interface Props {
  /** Force disable the selector even if there are options */
  disabled?: boolean;
  /** DOM ID to assign to the dropdown */
  id?: string;
  /** Accessibility label */
  label: string;
  /** Called when the user clicks the Delete link */
  onDelete?: (index: number) => void;
  /** Called when the user makes a selection */
  onSelection: (option: DropdownOption, index: number) => void;
  /** Options to select from, in the format expected by Dropdown/Picklist */
  options: DropdownOption[];
  /** String to display when no column is selected */
  placeholder?: string;
  /** Used to differentiate multiple column selectors, e.g. when selecting for a hierarchy */
  relativeIndex?: number;
  /** Column names that have been selected in other selectors, and thus shouldn't be shown in this one */
  selectedValues?: string[];
  /** Allow user to request deletion of this column selector */
  shouldRenderDeleteColumnLink: boolean;
  /** If undefined, will render a "pending" column UI, i.e. with no column selected */
  value?: string;
}

export const getFilteredGroupedOptions = (
  iOptions: DropdownOption[],
  filterText: string
): IAutocompleteOptionGroup[] | DropdownOption[] => {
  if (iOptions.some((option) => !option.hasOwnProperty('group'))) {
    return iOptions.filter((item) => {
      if (item.label) {
        // If any option is missing a group, we'll just return the options without grouping
        return item.label.toLowerCase().includes(filterText.toLowerCase());
      }
    });
  }

  const scope = 'shared.visualizations.panes.data.fields.dimension.groups';
  const allColumnsName = I18n.translate('all_columns', { scope });
  const recommendedColumnsName = I18n.translate('recommended_columns', { scope });

  const groupNames = iOptions.some((obj) => obj.group === recommendedColumnsName)
    ? [recommendedColumnsName, allColumnsName]
    : [allColumnsName];

  // Make sure options only return for specific group names aka recommended vs all
  // if we don't have a visualization selected make sure we only return allColumns not recommended
  const filteredOptions = (listOptions: any, filterTextInput: string, groupName: string) => {
    // Find which options that satisfy the filter && also have the group that matches a groupName
    const filteredOptionsByGroup = listOptions.filter((option: { group: string }) => {
      return option.group === groupName;
    });

    if (_.isEmpty(filterTextInput)) {
      return filteredOptionsByGroup;
    } else {
      return filteredOptionsByGroup.filter((item: { label: string }) =>
        item.label.toLowerCase().includes(filterTextInput.toLowerCase())
      );
    }
  };

  const optionGroups = groupNames.map((name) => {
    return {
      text: name,
      options: filteredOptions(iOptions, filterText, name)
    };
  });

  return optionGroups;
};

// Remove selected columns from the available columns list, for example so that
// you can't select the same column as the dimension AND as the drilldown/hierarchy column
export const withCurrentColumnOptions = (
  options: DropdownOption[],
  selectedValues: string[],
  value: string | undefined
) => {
  return _.filter(options, (option) => {
    return !_.includes(selectedValues, option.value) || value === option.value;
  });
};

const AutocompleteColumnSelector: React.FC<Props> = (props) => {
  const {
    relativeIndex = 0,
    id = `column-selection-${relativeIndex}`,
    disabled = false,
    options,
    value,
    label,
    shouldRenderDeleteColumnLink,
    selectedValues = [],
    placeholder = I18n.translate(
      'shared.visualizations.panes.legends_and_flyouts.fields.additional_flyout_values.select_column'
    ),
    onSelection,
    onDelete
  } = props;

  const currentColumnOptions = withCurrentColumnOptions(options, selectedValues, value);

  const iOptions = currentColumnOptions.map((option) => ({
    ...option,
    label: option.title
  }));

  let deleteColumnLink = null;
  if (onDelete && shouldRenderDeleteColumnLink) {
    deleteColumnLink = (
      <DeleteColumnLink
        id={`column-delete-link-${relativeIndex}`}
        onClick={() => {
          onDelete(relativeIndex);
        }}
      />
    );
  }

  const optionBuilder = (
    option: { type: string; label: string },
    filterText: string,
    listItem: IListItemComponent
  ) => {
    return `
      <forge-icon slot="leading" name=${getForgeIconNameForDataType(option.type)}></forge-icon>
      <span slot="title">${_.escape(option.label)}</span>
      <forge-tooltip position="bottom">
        <div class="tooltip-content">
          ${_.escape(option.label)}
        </div>  
      </forge-tooltip>
    `;
  };

  const onSelectOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption: DropdownOption | undefined = options.find(
      (option) => option.value === event.currentTarget.value
    );
    if (selectedOption && selectedOption.value !== value) {
      onSelection(selectedOption, relativeIndex);
    }
  };

  return (
    <>
      <div className="primary-dropdown-container">
        <ForgeAutocomplete
          filter={(filterText: string) => {
            return getFilteredGroupedOptions(iOptions, filterText);
          }}
          optionBuilder={optionBuilder}
          onSelect={onSelectOption}
          value={value}
          label={label}
          syncPopupWidth
        >
          <ForgeTextField>
            <input type="text" id={id} />
            {!value && <label htmlFor={id}>{placeholder}</label>}
            <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down" />
          </ForgeTextField>
        </ForgeAutocomplete>
      </div>
      {deleteColumnLink}
    </>
  );
};

export default AutocompleteColumnSelector;
