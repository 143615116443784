import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { currentUserIsLoggedIn } from 'common/current_user';
import { hasRights } from 'common/views/has_rights';
import AuthoringWorkflowIndex from 'common/authoring_workflow';

import {
  cancelEditingVisualization,
  updateVisualization
} from '../actions';

export class AuthoringWorkflowModal extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'renderAuthoringWorkflow',
      'destroyAuthoringWorkflow'
    ]);

    this.state = {
      isModalOpen: !props.config.isActive || _.isEmpty(props.config.vif)
    };
  }

  renderAuthoringWorkflow() {
    const {
      config,
      filters,
      isEphemeral,
      onCancel,
      onComplete,
      userCanCreateNewAsset,
      userCanUpdateCurrentAsset
    } = this.props;

    const configuration = {
      vif: config.vif,
      onCancel: _.flow(onCancel, this.destroyAuthoringWorkflow),
      onComplete: _.flow(onComplete, this.destroyAuthoringWorkflow),
      enableFiltering: true,
      filters,
      useLogger: _.get(window, 'serverConfig.environment') === 'development',
      insertButtonPresent: userCanCreateNewAsset || userCanUpdateCurrentAsset,
      insertButtonText: I18n.t('visualization_canvas.save_draft'),
      ...(isEphemeral && { closeButtonText: I18n.t('visualization_canvas.back_to_dataset') })
    };

    return <AuthoringWorkflowIndex {...configuration} />;
  }

  destroyAuthoringWorkflow() {
    this.setState({isModalOpen: false});
  }

  render() {
    const { config } = this.props;

    if (!config.isActive || _.isEmpty(config.vif)) {
      return null;
    }

    return (
      <div className="authoring-workflow-modal">
        {this.renderAuthoringWorkflow()}
      </div>
    );
  }
}

AuthoringWorkflowModal.propTypes = {
  config: PropTypes.shape({
    vifIndex: PropTypes.number,
    vif: PropTypes.object
  }).isRequired,
  filters: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  userCanCreateNewAsset: PropTypes.bool,
  userCanUpdateCurrentAsset: PropTypes.bool,
  isEphemeral: PropTypes.bool
};

AuthoringWorkflowModal.defaultProps = {
  onCancel: _.noop,
  onComplete: _.noop
};

function mapStateToProps(state) {
  return {
    isEphemeral: state.isEphemeral,
    config: state.authoringWorkflow,
    filters: state.filters,
    userCanCreateNewAsset: currentUserIsLoggedIn(), // All signed in users can create derived assets.
    userCanUpdateCurrentAsset: state.view && hasRights(state.view, 'update_view')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onCancel: cancelEditingVisualization,
    onComplete: updateVisualization
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthoringWorkflowModal);
