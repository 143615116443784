// Vendor Imports
import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ForgeTooltip } from '@tylertech/forge-react';

import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/AccessibleModal';
import { currentUserCanMakeAssets } from 'common/current_user';
import Button from 'common/components/Button';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

// Project Imports
import { migrateOldMapToNewGLMap, resetState } from '../actions';
import AxisPane from './panes/AxisPane';
import BasemapPane from './panes/BasemapPane';
import { UPGRADE_TO_NEW_GL_MAPS_SUPPORT_LINK, VIZ_PANE_CSS_ANIMATION_DELAY } from '../constants';
import CustomizationTabs from './CustomizationTabs/index';
import { CustomizationTabPanes } from './CustomizationTabPanes/index';
import DataPane from './panes/DataPane';
import FilterBar from './FilterBar';
import LegendsAndFlyoutsPane from './panes/LegendsAndFlyoutsPane';
import MapLayersPane from './panes/MapLayersPane';
import PresentationPane from './panes/PresentationPane';
import VizWhizPane from './panes/VizWhizPane';
import TableView from './TableView';
import {
  getCurrentVif,
  getCurrentMapLayerEditView,
  getShowDataTableControl,
  getVisualizationType,
  hasMadeChangesToVifs,
  isCurrentMapLayerPrimary,
  isFeatureMap,
  isInsertableVisualization,
  isNewGLMap,
  isScatterChart,
  isCalendar,
  isRegionMap,
  isTable,
  isUserCurrentlyActive
} from '../selectors/vifAuthoring';
import { getCurrentMetadata } from '../selectors/metadata';
import VisualizationPreviewContainer from './VisualizationPreviewContainer';
import VisualizationTypeSelector from './VisualizationTypeSelector';
import { removeTransientStateFromVif } from 'common/visualizations/helpers/VifHelpers';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { fetchAIFourfourWhitelist } from 'common/core/configurations';

// Constants
const scope = 'shared.visualizations';

export class AuthoringWorkflow extends Component {
  constructor() {
    super();

    this._visualizationPreviewContainerRef = React.createRef();
  }

  state = {
    isVerticalFilterBarExpanded: false,
    aiFourFoursWhitelist: [],
    userCanCreateMoreAssets: false
  };

  UNSAFE_componentWillMount() {
    let currentTabSelection = 'authoring-data';
    let showAuthoringModal = true;

    if (FeatureFlags.value('enable_new_maps')) {
      const { vifAuthoring } = this.props;

      if (isFeatureMap(vifAuthoring) || isRegionMap(vifAuthoring)) {
        showAuthoringModal = false;
      }

      if (getVisualizationType(vifAuthoring) === 'map') {
        currentTabSelection = 'authoring-map-layers';
      }
    }

    this.setState({ currentTabSelection, showAuthoringModal });
  }

  componentDidMount() {
    const modalElement = ReactDOM.findDOMNode(this.modal);
    // Prevents the form from submitting and refreshing the page.
    $(modalElement).on('submit', _.constant(false));
    $(window).on('resize', this.onWindowResize);
    this.fetchConfigs();
  }

  componentWillUnmount() {
    $(window).off('resize', this.onWindowResize);
  }

  fetchConfigs = async () => {
    const aiFourFoursWhitelist = await fetchAIFourfourWhitelist(window.location.hostname).then((response) => {
      return _.get(response, '[0].properties[0].value', []);
    });
    const userCanCreateMoreAssets = await currentUserCanMakeAssets();

    this.setState({
      aiFourFoursWhitelist,
      userCanCreateMoreAssets
    });
  };

  invalidateVizSize = () => {
    setTimeout(() => {
      $(this._visualizationPreviewContainerRef)
        .find('.socrata-visualization')
        .trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }, VIZ_PANE_CSS_ANIMATION_DELAY);
  };

  onWindowResize = () => {
    this.invalidateVizSize();
  };

  onVerticalFilterBarToggle = (isVerticalFilterBarExpanded) => {
    this.setState({ isVerticalFilterBarExpanded });
    this.invalidateVizSize();
  };

  onInEditFilterChange = (filter) => {
    $(this._visualizationPreviewContainerRef)
      .find('.socrata-visualization-renderer')
      .trigger('SOCRATA_VISUALIZATION_IN_EDIT_FILTER_CHANGED', filter);
  };

  onUpdate = (filters) => {
    $(this._visualizationPreviewContainerRef)
      .find('.socrata-visualization-renderer')
      .trigger('SOCRATA_VISUALIZATION_FILTER_CHANGED', { filters });
  };

  onComplete = () => {
    const { vifAuthoring, vif } = this.props;
    const newVif = removeTransientStateFromVif(vif);

    this.props.onComplete({
      vif: newVif,
      filters: vifAuthoring.authoring.filters
    });
  };

  onCancel = () => {
    const { onCancel } = this.props;

    if (this.confirmUserCanEscape()) {
      onCancel();
    }
  };

  onContinueMigration = () => {
    const { onMigrateOldMapToNewGLMap, vifAuthoring } = this.props;

    onMigrateOldMapToNewGLMap(getVisualizationType(vifAuthoring));

    this.setState({ currentTabSelection: 'authoring-map-layers', showAuthoringModal: true });
  };

  onChartSelect = () => {
    this.setState({ currentTabSelection: 'authoring-data', showAuthoringModal: true });
  };

  onBack = () => {
    const { onBack } = this.props;

    if (this.confirmUserCanEscape()) {
      onBack();
    }
  };

  onTabNavigation = (tabId) => {
    const currentTabSelection = tabId;
    const switchToListViewLink = document.getElementById('switch-to-list-view-link');

    this.setState({ currentTabSelection });

    if (currentTabSelection === 'authoring-map-layers' && !_.isNull(switchToListViewLink)) {
      switchToListViewLink.click();
    }
  };

  switchBackToRelevantPane = () => {
    const { vifAuthoring } = this.props;
    const { currentTabSelection } = this.state;
    const tabsForMaps = ['authoring-map-layers', 'authoring-basemap'];
    const isNewGLMapEnabled = FeatureFlags.value('enable_new_maps');
    const isCurrentTabForMaps = _.includes(tabsForMaps, currentTabSelection);

    if (isNewGLMapEnabled && isNewGLMap(vifAuthoring)) {
      if (!isCurrentTabForMaps) {
        this.setState({ currentTabSelection: 'authoring-map-layers' });
      }
    } else if (isCurrentTabForMaps) {
      this.setState({ currentTabSelection: 'authoring-data' });
    }
  };

  confirmUserCanEscape = () => {
    const { vifAuthoring } = this.props;
    const message = I18n.t('modal.changes_made_confirmation', { scope });
    const changesMade = hasMadeChangesToVifs(vifAuthoring);

    // eslint-disable-next-line no-alert
    return !changesMade || window.confirm(message);
  };

  renderBackButton = () => {
    const { backButtonText } = this.props;

    return _.isString(backButtonText) ? (
      <button className="authoring-back-button" onClick={this.onBack}>
        <span className="icon-arrow-left" />
        {backButtonText}
      </button>
    ) : null;
  };

  renderResetButton = () => {
    const { backButtonText, onReset } = this.props;
    const confirmDialog = () => {
      // eslint-disable-next-line no-alert
      if (confirm(I18n.t('common.reset_confirm', { scope }))) {
        onReset();
      }
    };
    const className = classNames('authoring-reset-button', {
      'with-back-button': _.isString(backButtonText)
    });

    return (
      <button
        className={className}
        onClick={confirmDialog}
        aria-label={I18n.t('common.reset_button_aria_label', { scope })}
      >
        <span className="icon-undo" />
        {I18n.t('common.reset_button_label', { scope })}
      </button>
    );
  };

  renderNewGLMapsMigrationModal = () => {
    const cancelText = I18n.t('upgrade_to_new_gl_maps_modal.cancel', { scope });
    return (
      <Modal
        className="authoring-modal migration-modal-content"
        onDismiss={this.props.onCancel}
        ref={(ref) => (this.modal = ref)}
        contentLabel={I18n.t('upgrade_to_new_gl_maps_modal.title', { scope })}
      >
        <ModalHeader
          title={I18n.t('upgrade_to_new_gl_maps_modal.title', { scope })}
          onDismiss={this.props.onCancel}
          closeButtonLabel={cancelText}
        />
        <ModalContent className="authoring-modal-content">
          <p className="new-gl-maps-migration-message">
            {I18n.t('upgrade_to_new_gl_maps_modal.confirmation_message_part_one', { scope })}
            <span className="support-link">
              <a href={UPGRADE_TO_NEW_GL_MAPS_SUPPORT_LINK} target="_blank" rel="noreferrer">
                {I18n.t('upgrade_to_new_gl_maps_modal.click_here', { scope })}
              </a>
              .
            </span>
            {I18n.t('upgrade_to_new_gl_maps_modal.confirmation_message_part_two', { scope })}
          </p>
        </ModalContent>
        <ModalFooter className="authoring-modal-footer">
          <div className="authoring-actions">
            <Button className="cancel" size="sm" onClick={this.props.onCancel}>
              {cancelText}
            </Button>
            <Button className="continue" size="sm" variant="primary" onClick={this.onContinueMigration}>
              {I18n.t('upgrade_to_new_gl_maps_modal.continue', { scope })}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  renderFilterBar = () => {
    let { enableFiltering, vifAuthoring } = this.props;

    if (isNewGLMap(vifAuthoring)) {
      // Currently we are disabling filtering of data for non-primary layer and in in map layers list view
      if (
        !isCurrentMapLayerPrimary(vifAuthoring) ||
        getCurrentMapLayerEditView(vifAuthoring) === 'list_view'
      ) {
        enableFiltering = false;
      }
    }

    if (!enableFiltering) {
      return null;
    }

    return (
      <FilterBar.Vertical
        onInEditFilterChange={this.onInEditFilterChange}
        onUpdate={this.onUpdate}
        onToggle={this.onVerticalFilterBarToggle}
      />
    );
  };

  renderAuthoringWorkflow = () => {
    const { closeButtonText, insertButtonPresent, insertButtonText, metadata, tabsForMaps, vifAuthoring } =
      this.props;
    const getParentDatasetPermissionsScope = (metadata) => {
      if (metadata.data && metadata.data.permissions) {
        return metadata.data.permissions.scope;
      }
      return 'not loaded';
    };

    let { tabsForCharts } = this.props;

    const { userCanCreateMoreAssets } = this.state;

    // if the washington_state_ai_demo feature flag is enabled, we want to show the VizWhizPane tab
    if (
      FeatureFlags.valueOrDefault('washington_state_ai_demo', false) &&
      getParentDatasetPermissionsScope(metadata) == 'public' &&
      this.state.aiFourFoursWhitelist.includes(metadata.data.id) &&
      tabsForCharts.filter((tab) => tab.id === 'authoring-viz-whiz').length === 0
    ) {
      tabsForCharts.splice(1, 0, {
        id: 'authoring-viz-whiz',
        title: 'Viz Whiz Pane',
        paneComponent: VizWhizPane,
        icon: 'cards'
      });
    } else if (getParentDatasetPermissionsScope(metadata) == 'private') {
      tabsForCharts = tabsForCharts.filter((tab) => tab.id !== 'authoring-viz-whiz');
    }

    const shouldShowDataTableControl = getShowDataTableControl(vifAuthoring);
    const isNotInsertable =
      !isInsertableVisualization(vifAuthoring) ||
      isUserCurrentlyActive(vifAuthoring) ||
      !userCanCreateMoreAssets;
    let tabs = tabsForCharts;

    // Due to the change in structure of AX for NewGLMaps and rest of the visualization types
    // we need to maintain two different set of tab groups
    if (isNewGLMap(vifAuthoring)) {
      tabs = tabsForMaps;
    } else if (isScatterChart(vifAuthoring) || isCalendar(vifAuthoring)) {
      tabs = _.filter(tabsForCharts, (tabItem) => tabItem.id !== 'authoring-axis-and-scale');
    } else if (isTable(vifAuthoring)) {
      tabs = _.filter(
        tabsForCharts,
        (tabItem) => tabItem.id === 'authoring-data' || tabItem.id === 'authoring-colors-and-style'
      );
    }

    const authoringControlsClasses = classNames('authoring-controls', {
      'vertical-filter-expanded': this.state.isVerticalFilterBarExpanded,
      'show-data-table': shouldShowDataTableControl,
      'table-visualization-preview': isTable(vifAuthoring)
    });

    const stylesContentContainerOverrides = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'initial',
      transform: 0
    };

    const cancelText = closeButtonText || I18n.t('modal.cancel', { scope });

    const showVizWhizPane = this.state.currentTabSelection == 'authoring-viz-whiz';

    return (
      <Modal
        className="authoring-modal"
        fullScreen
        onDismiss={this.onCancel}
        ref={(ref) => (this.modal = ref)}
        contentLabel={I18n.t('modal.title', { scope })}
        stylesContentContainerOverrides={stylesContentContainerOverrides}
      >
        <ModalHeader
          title={I18n.t('modal.title', { scope })}
          onDismiss={this.onCancel}
          closeButtonLabel={cancelText}
        />
        <ModalContent className="authoring-modal-content">
          <div className={authoringControlsClasses}>
            <div className="authoring-editor">
              <CustomizationTabs
                onTabNavigation={this.onTabNavigation}
                selection={this.state.currentTabSelection}
                tabs={tabs}
              />
              {!showVizWhizPane ? (
                <CustomizationTabPanes
                  onChangeVisualizationType={this.switchBackToRelevantPane}
                  selection={this.state.currentTabSelection}
                  tabs={tabs}
                />
              ) : (
                <div></div>
              )}
            </div>
            {showVizWhizPane ? (
              <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999 }}>
                <VizWhizPane onChartSelect={this.onChartSelect}></VizWhizPane>
              </div>
            ) : (
              <div className="authoring-preview-container">
                {this.renderFilterBar()}
                <div className="visualization-pane">
                  <VisualizationTypeSelector onChangeVisualizationType={this.switchBackToRelevantPane} />
                  <div
                    className="visualization-pane-content"
                    ref={(ref) => {
                      this._visualizationPreviewContainerRef = ref;
                    }}
                  >
                    <VisualizationPreviewContainer />
                  </div>
                  {shouldShowDataTableControl && <TableView metadata={metadata} />}
                </div>
              </div>
            )}
          </div>
        </ModalContent>
        <ModalFooter className="authoring-modal-footer">
          <div className="authoring-footer-buttons">
            {this.renderBackButton()}
            {this.renderResetButton()}
          </div>
          <div className="authoring-actions">
            <Button className="cancel" size="sm" onClick={this.onCancel}>
              {cancelText}
            </Button>
            {insertButtonPresent && (
              <Button
                className="done"
                size="sm"
                variant="primary"
                onClick={this.onComplete}
                onKeyUp={this.onInsertKeyup}
                disabled={isNotInsertable}
              >
                {insertButtonText || I18n.t('modal.insert', { scope })}
              </Button>
            )}
            {!userCanCreateMoreAssets && (
              <ForgeTooltip id="create-view-tooltip" position={'bottom'}>
                {I18n.t(
                  'shared.components.asset_action_bar.button_disabled_limited_community_assets_tooltip'
                )}
              </ForgeTooltip>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return this.state.showAuthoringModal
      ? this.renderAuthoringWorkflow()
      : this.renderNewGLMapsMigrationModal();
  }
}

function mapStateToProps(state) {
  return {
    vif: getCurrentVif(state.vifAuthoring),
    vifAuthoring: state.vifAuthoring,
    metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onReset: () => dispatch(resetState()),
    onMigrateOldMapToNewGLMap: (currentMapType) => dispatch(migrateOldMapToNewGLMap(currentMapType))
  };
}

AuthoringWorkflow.propTypes = {
  enableFiltering: PropTypes.bool,
  insertButtonText: PropTypes.string,
  insertButtonPresent: PropTypes.bool,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onReset: PropTypes.func,
  tabs: PropTypes.array,
  vif: PropTypes.object
};

AuthoringWorkflow.defaultProps = {
  enableFiltering: true,
  insertButtonPresent: true,
  onComplete: _.noop,
  onBack: _.noop,
  onReset: _.noop,
  onCancel: _.noop,
  tabsForCharts: [
    {
      id: 'authoring-data',
      title: I18n.t('panes.data.title', { scope }),
      paneComponent: DataPane,
      icon: 'database'
    },
    {
      id: 'authoring-axis-and-scale',
      title: I18n.t('panes.axis.title', { scope }),
      paneComponent: AxisPane,
      icon: 'sort'
    },
    {
      id: 'authoring-colors-and-style',
      title: I18n.t('panes.presentation.title', { scope }),
      paneComponent: PresentationPane,
      icon: 'format_paint'
    },
    {
      id: 'authoring-legends-and-flyouts',
      title: I18n.t('panes.legends_and_flyouts.title', { scope }),
      paneComponent: LegendsAndFlyoutsPane,
      icon: 'tooltip_edit'
    }
  ],
  tabsForMaps: [
    {
      id: 'authoring-map-layers',
      title: I18n.t('panes.map_layers.title', { scope }),
      paneComponent: MapLayersPane,
      icon: 'layers'
    },
    {
      id: 'authoring-basemap',
      title: I18n.t('panes.basemap.title', { scope }),
      paneComponent: BasemapPane,
      icon: 'settings'
    }
  ]
};

export default connect(mapStateToProps, mapDispatchToProps)(DragDropContext(HTML5Backend)(AuthoringWorkflow));
