// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import SingleColumnSelector from './SingleColumnSelector';
import AutocompleteColumnSelector from './AutocompleteColumnSelector';
import { getCurrentMetadata, hasData } from '../../selectors/metadata.js';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import HelperTooltip from 'common/components/HelperTooltip';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import { ViewColumn } from 'common/types/viewColumn';
import { FeatureFlags } from 'common/feature_flags';

function getListItemKey(listItemKeyPrefix: string, relativeIndex: number) {
  return `${listItemKeyPrefix}:${relativeIndex}`;
}

interface Props {
  columns: ViewColumn[];
  metadata: any;
  labelFromIndex: (index: number) => string;

  onUpdate: (relativeIndex: number, value: string) => void;
  onAdd: (value: string) => void;
  onDelete: (idx: number) => void;

  // related to multiple columns
  listItemKeyPrefix: string;
  selectedValues: string[];
  addColumnLinkTitle: string;
  additionalFlyoutColumns: any[];
  disableAddColumnLink: boolean;

  flyoutDescription: string;

  shouldRenderAddColumnLink: boolean;
  shouldRenderDeleteColumnLink: boolean;
}

interface ColumnSelectorState {
  isColumnPending: boolean;
}

export class MultiColumnSelector extends Component<Props, ColumnSelectorState> {
  static defaultProps = {
    columns: [],
    disableAddColumnLink: false,
    flyoutDescription: '',
    labelFromIndex: (index: number) => '',
    selectedValues: [],
    shouldRenderAddColumnLink: true, // this is never set to false
    shouldRenderDeleteColumnLink: true // this is never set to false
  };

  selector: HTMLElement | null;

  constructor(props: Props) {
    super(props);

    this.state = { isColumnPending: false };
  }

  handleOnClickAddColumn = () => {
    this.setState({ isColumnPending: true });
  };

  handleOnClickDeleteColumn(relativeIndex: number) {
    const { onDelete } = this.props;

    onDelete(relativeIndex);
  }

  handleOnClickDeletePendingColumn() {
    this.setState({ isColumnPending: false });
  }

  handleOnSelectionColumn(relativeIndex: number, option: { value: string }) {
    const { onUpdate } = this.props;

    onUpdate(relativeIndex, option.value);
  }

  handleOnSelectionPendingColumn(option: { value: string }) {
    const { onAdd } = this.props;

    onAdd(option.value);
    this.setState({ isColumnPending: false });
  }

  getColumnOptions = (columns: ViewColumn[]) => {
    return columns.map((column) => ({
      title: column.name!,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderColumnOption
    }));
  };

  renderColumnSelectors() {
    const { additionalFlyoutColumns, columns, selectedValues, labelFromIndex, listItemKeyPrefix } =
      this.props;
    const { isColumnPending } = this.state;

    const options = this.getColumnOptions(columns);
    let columnSelectors = null;
    let relativeIndex = 0;

    if (additionalFlyoutColumns.length > 0) {
      columnSelectors = additionalFlyoutColumns.map((columnName, columnIndex) => {
        const SelectorComponent =
          listItemKeyPrefix === 'drill-down' ? AutocompleteColumnSelector : SingleColumnSelector;
        return (
          <li className="list-item" key={getListItemKey(listItemKeyPrefix, columnIndex)}>
            <SelectorComponent
              relativeIndex={columnIndex}
              options={options}
              value={columnName}
              label={labelFromIndex(columnIndex)}
              shouldRenderDeleteColumnLink={this.props.shouldRenderDeleteColumnLink}
              selectedValues={selectedValues}
              onSelection={(option, idx) => {
                this.handleOnSelectionColumn(idx, option);
              }}
              onDelete={(idx) => {
                this.handleOnClickDeleteColumn(idx);
              }}
            />
          </li>
        );
      });
      relativeIndex = columnSelectors.length;
    }

    const pendingColumnSelector = () => {
      if (!isColumnPending) {
        return null;
      }

      const SelectorComponent =
        listItemKeyPrefix === 'drill-down' ? AutocompleteColumnSelector : SingleColumnSelector;
      return (
        <li className="list-item" key={getListItemKey(listItemKeyPrefix, relativeIndex)}>
          <SelectorComponent
            relativeIndex={relativeIndex}
            options={options}
            label={labelFromIndex(relativeIndex)}
            selectedValues={selectedValues}
            shouldRenderDeleteColumnLink
            onSelection={(option) => this.handleOnSelectionPendingColumn(option)}
            onDelete={() => this.handleOnClickDeletePendingColumn()}
          />
        </li>
      );
    };

    const addColumnLink = this.renderAddColumnLink();
    return (
      <div
        id="column-selectors-container"
        ref={(selector) => {
          this.selector = selector;
        }}
      >
        <ul className="dropdowns-list">
          {columnSelectors}
          {pendingColumnSelector()}
        </ul>
        {addColumnLink}
      </div>
    );
  }

  renderColumnOption(option: { type: string; title: string }) {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  }

  renderAddColumnLink() {
    const { addColumnLinkTitle, disableAddColumnLink, flyoutDescription, shouldRenderAddColumnLink } =
      this.props;
    const { isColumnPending } = this.state;
    const addFlyoutDisabled = isColumnPending || disableAddColumnLink;
    const addFlyoutButtonAttributes = {
      id: 'column-add-flyout-link',
      disabled: addFlyoutDisabled,
      onClick: this.handleOnClickAddColumn,
      variant: VARIANTS.LINK,
      size: SIZES.SMALL
    };
    const renderIconAndFlyout = flyoutDescription && (
      <div className="add-column-flyout-and-icon">
        <HelperTooltip id={'column-add-flyout-link'} content={flyoutDescription} />
      </div>
    );

    return shouldRenderAddColumnLink ? (
      <div className="add-link-container">
        <div>
          <Button {...addFlyoutButtonAttributes}>
            <SocrataIcon name={IconName.Add} />
            {addColumnLinkTitle}
          </Button>
        </div>
        {renderIconAndFlyout}
      </div>
    ) : null;
  }

  render() {
    const { metadata } = this.props;
    return hasData(metadata) ? this.renderColumnSelectors() : null;
  }
}

const mapStateToProps = (state: any /* would need the redux store to be typed */) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring)
});

export default connect(mapStateToProps)(MultiColumnSelector);
