import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from 'common/i18n';
import _ from 'lodash';
import { setLogarithmicScale, setTreatNullValuesAsZero, setMeasureAxisPrecision } from '../../../actions';
import { isLogarithmicScale, getTreatNullValuesAsZero, getMeasureAxisPrecision } from '../../../selectors/vifAuthoring';

const scope = 'shared.visualizations.panes.axis.fields.scale';

export class LogarithmicScaleSelector extends Component {
  constructor(props) {
    super(props);
  }

  setLogScale(isChecked) {
    const {
      vifAuthoring,
      onSetLogarithmicScale,
      onSetTreatNullValuesAsZero,
      onSetMeasureAxisPrecision
    } = this.props;

    onSetLogarithmicScale(isChecked);

    // Enabling log scale does not support treat null values as zero
    // or custom measure axis precision. Reset configuration for those values.
    if (getTreatNullValuesAsZero(vifAuthoring)) {
      onSetTreatNullValuesAsZero(false);
    }
    if (_.isInteger(getMeasureAxisPrecision(vifAuthoring))) {
      onSetMeasureAxisPrecision();
    }
  }

  render() {
    const { vifAuthoring } = this.props;
    const inputAttributes = {
      defaultChecked: isLogarithmicScale(vifAuthoring),
      id: 'logarithmic-scale',
      onChange: (event) => this.setLogScale(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="logarithmic-scale">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('logarithmic_scale', { scope })}
        </label>
      </div>
    );
  }
}

LogarithmicScaleSelector.propTypes = {
  vifAuthoring: PropTypes.object,
  onSetLogarithmicScale: PropTypes.func,
  onSetTreatNullValuesAsZero: PropTypes.func,
  onSetMeasureAxisPrecision: PropTypes.func
};

const mapDispatchToProps = {
  onSetLogarithmicScale: setLogarithmicScale,
  onSetTreatNullValuesAsZero: setTreatNullValuesAsZero,
  onSetMeasureAxisPrecision: setMeasureAxisPrecision
};

const mapStateToProps = (state) => ({
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(LogarithmicScaleSelector);
