import React from 'react';
import classNames from 'classnames';

interface ICustomizationTabPaneProps {
  id: string;
  show?: boolean;
}

export default class CustomizationTabPane extends React.Component<ICustomizationTabPaneProps> {
  public static defaultProps: Partial<ICustomizationTabPaneProps> = {
    show: false
  };

  render() {
    const isHidden = !this.props.show;
    const attributes = {
      key: this.props.id,
      id: `${this.props.id}-panel`,
      className: classNames('customization-tab-pane', { 'customization-tab-pane_hidden': isHidden }),
      role: 'tabpanel',
      'aria-hidden': isHidden,
      'aria-labelledby': `${this.props.id}-link`
    };

    return <div {...attributes}>{this.props.children}</div>;
  }
}
