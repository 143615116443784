// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  appendReferenceLine,
  removeReferenceLine,
  setReferenceLineColor,
  setReferenceLineLabel,
  setReferenceLineValue,
  setOrderBy
} from '../../../actions';
import {
  getDimension,
  getReferenceLines,
  getOrderBy,
  isBarChart,
  isColumnChart,
  isComboChart,
  isHistogram,
  isOneHundredPercentStacked,
  isTimelineChart,
  isPieChart
} from '../../../selectors/vifAuthoring';
import {
  getCurrentMetadata,
  isDimensionTypeCalendarDate
} from '../../../selectors/metadata';
import DualAxisOptions from './DualAxisOptions';
import AxisPrecisionSelectorSingle from './AxisPrecisionSelectorSingle';
import AxisPrecisionSelectorDual from './AxisPrecisionSelectorDual';
import AxisScaleSelectorDual from './AxisScaleSelectorDual';
import AxisScaleSelectorSingle from './AxisScaleSelectorSingle';
import LogarithmicScaleSelector from './LogarithmicScaleSelector';
import DebouncedInput from '../../shared/DebouncedInput';
import TextInputButton from '../../shared/TextInputButton';
import EmptyPane from '../EmptyPane';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import ColorPicker from 'common/components/ColorPicker';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';

// Constants
import { CHART_SORTING, COLORS } from '../../../constants';

export class AxisPane extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'getExpandedStateKey',
      'renderAddReferenceLineLink',
      'renderBarChartControls',
      'renderColumnChartControls',
      'renderComboChartControls',
      'renderChartSortingOption',
      'renderChartSorting',
      'renderEmptyPane',
      'renderHistogramControls',
      'renderPieChartControls',
      'renderReferenceLinesControls',
      'renderReferenceLinesControlsAtIndex',
      'renderReferenceLinesLabelTextInputButton',
      'renderReferenceLinesLabelInput',
      'renderTimelineChartControls'
    ]);
  }

  renderChartSortingOption(option) {
    return (
      <div>
        <span className={option.icon}></span> {option.title}
      </div>
    );
  }

  renderChartSorting() {
    const { onSetOrderBy, chartSorting, vifAuthoring } = this.props;
    const defaultChartSort = getOrderBy(vifAuthoring) || { parameter: 'measure', sort: 'desc' };
    const options = _.map(chartSorting, (option) => {
      option.value = `${option.orderBy.parameter}-${option.orderBy.sort}`;
      option.render = this.renderChartSortingOption;

      return option;
    });

    const attributes = {
      options,
      onSelection: (option) => onSetOrderBy(option.orderBy),
      id: 'chart-sorting-selection',
      value: `${defaultChartSort.parameter}-${defaultChartSort.sort}`
    };

    return (
      <AccordionPane title={I18n.t('shared.visualizations.panes.axis.subheaders.chart_sorting')}>
        <div className="authoring-field">
          <Dropdown {...attributes} />
        </div>
      </AccordionPane>
    );
  }

  renderAxisScaleSelector() {
    const { vifAuthoring } = this.props;
    const axisScaleSelector = isComboChart(vifAuthoring) ?
      <AxisScaleSelectorDual /> :
      <AxisScaleSelectorSingle />;
    const axisPrecisionSelector = isComboChart(vifAuthoring) ?
      <AxisPrecisionSelectorDual /> :
      <AxisPrecisionSelectorSingle />;
    const logarithmicScaleSelector = (isTimelineChart(vifAuthoring)) ?
      <LogarithmicScaleSelector /> : null;
    return (
      <AccordionPane title={I18n.t('shared.visualizations.panes.axis.subheaders.scale')}>
        <div className="measure-axis-container">
          <label className="measure-axis-bounds-label">
            {I18n.t('shared.visualizations.panes.axis.fields.scale.title')}
          </label>
          {axisScaleSelector}
          {logarithmicScaleSelector}
        </div>
        <div className="measure-precision-container">
          <label className="measure-axis-precision-label">
            {I18n.t('shared.visualizations.panes.axis.fields.precision.title')}
          </label>
          {axisPrecisionSelector}
        </div>
      </AccordionPane>
    );
  }

  renderDualAxisControls() {
    return (
      <AccordionPane title={I18n.t('shared.visualizations.panes.dual_axis_options.subheaders.dual_axis_options')}>
        <DualAxisOptions />
      </AccordionPane>
    );
  }

  renderReferenceLinesControls() {
    const { vifAuthoring } = this.props;
    const controls = getReferenceLines(vifAuthoring).map(this.renderReferenceLinesControlsAtIndex);
    const link = this.renderAddReferenceLineLink();

    return (
      <AccordionPane title={I18n.t('shared.visualizations.panes.reference_lines.subheaders.reference_lines')}>
        {controls}
        {link}
      </AccordionPane>
    );
  }

  renderReferenceLinesControlsAtIndex(referenceLine, referenceLineIndex) {
    const {
      onRemoveReferenceLine,
      onSetReferenceLineColor,
      onSetReferenceLineValue,
      vifAuthoring
    } = this.props;

    const containerAttributes = {
      className: 'reference-lines-reference-line-container',
      id: `reference-lines-reference-line-container-${referenceLineIndex}`,
      key: referenceLine.uId
    };

    const headerLabel = formatString(
      I18n.t('shared.visualizations.panes.reference_lines.fields.reference_line_placeholder'),
      (referenceLineIndex + 1)
    );

    const valueInputAttributes = {
      className: 'text-input',
      id: `reference-lines-value-input-${referenceLineIndex}`,
      onChange: (event) => {
        const i = parseFloat(event.target.value);
        const value = isNaN(i) ? null : i;

        onSetReferenceLineValue({ referenceLineIndex, value });
      },
      placeholder: I18n.t('shared.visualizations.panes.reference_lines.fields.add_value'),
      step: 1,
      type: 'number',
      value: _.isFinite(referenceLine.value) ? referenceLine.value.toString() : ''
    };

    if (isOneHundredPercentStacked(vifAuthoring)) {
      valueInputAttributes.max = 100;
      valueInputAttributes.min = -100;
    }

    const textInputButton = this.renderReferenceLinesLabelTextInputButton(referenceLine, referenceLineIndex);

    const colorPickerAttributes = {
      handleColorChange: (color) => onSetReferenceLineColor({ referenceLineIndex, color }),
      palette: COLORS,
      value: referenceLine.color
    };

    const removeLinkAttributes = {
      onClick: () => {
        const key = this.getExpandedStateKey(referenceLineIndex);
        this.setState({ [key]: false });

        onRemoveReferenceLine(referenceLineIndex);
      }
    };

    return (
      <div {...containerAttributes}>
        <label className="block-label">
          {headerLabel}
        </label>
        <div className="reference-lines-controls-container">
          <DebouncedInput {...valueInputAttributes} />
          {textInputButton}
          <ColorPicker {...colorPickerAttributes} />
          <a {...removeLinkAttributes}>
            <span className="socrata-icon-close" />
          </a>
        </div>
      </div>
    );
  }

  renderReferenceLinesLabelTextInputButton(referenceLine, referenceLineIndex) {
    const buttonAttributes = {
      onChange: (event) => {
        this.props.onSetReferenceLineLabel({
          referenceLineIndex,
          label: event.target.value
        });
      },
      placeholder: I18n.t('shared.visualizations.panes.reference_lines.fields.add_label'),
      textInputId: `reference-lines-label-input-${referenceLineIndex}`,
      textInputValue: referenceLine.label
    };

    return <TextInputButton {...buttonAttributes} />;
  }

  renderReferenceLinesLabelInput(referenceLine, referenceLineIndex) {
    const key = this.getExpandedStateKey(referenceLineIndex);
    const expanded = _.get(this.state, key, false);

    if (!expanded) {
      return null;
    }

    const inputAttributes = {
      className: 'text-input',
      onChange: (event) => {
        this.props.onSetReferenceLineLabel({
          referenceLineIndex,
          label: event.target.value
        });
      },
      placeholder: I18n.t('shared.visualizations.panes.reference_lines.fields.add_label'),
      value: referenceLine.label
    };

    return <DebouncedInput {...inputAttributes} />;
  }

  getExpandedStateKey(referenceLineIndex) {
    return `text-input-button-expanded-${referenceLineIndex}`;
  }

  renderAddReferenceLineLink() {
    const { onAppendReferenceLine, vifAuthoring } = this.props;
    const lines = getReferenceLines(vifAuthoring);
    const linesWithoutValues = _.filter(lines, (line) => _.isUndefined(line.value));
    const isDisabled = (linesWithoutValues.length > 0);

    const linkAttributes = {
      className: isDisabled ? 'disabled' : null,
      id: 'reference-lines-add-reference-line-link',
      onClick: isDisabled ? null : onAppendReferenceLine
    };

    return (
      <div className="reference-lines-add-reference-line-link-container">
        <a {...linkAttributes}>
          <span className="socrata-icon-add" />
          {I18n.translate('shared.visualizations.panes.reference_lines.fields.add_reference_line')}
        </a>
      </div>);
  }

  renderBarChartControls() {
    const chartSorting = this.renderChartSorting();
    const axisScaleSelector = this.renderAxisScaleSelector();
    const referenceLinesControls = this.renderReferenceLinesControls();

    return (
      <AccordionContainer>
        {axisScaleSelector}
        {chartSorting}
        {referenceLinesControls}
      </AccordionContainer>
    );
  }

  renderColumnChartControls() {
    const chartSorting = this.renderChartSorting();
    const axisScaleSelector = this.renderAxisScaleSelector();
    const referenceLinesControls = this.renderReferenceLinesControls();

    return (
      <AccordionContainer>
        {axisScaleSelector}
        {chartSorting}
        {referenceLinesControls}
      </AccordionContainer>
    );
  }

  renderComboChartControls() {
    const chartSorting = this.renderChartSorting();
    const axisScaleSelector = this.renderAxisScaleSelector();
    const referenceLinesControls = this.renderReferenceLinesControls();
    const dualAxisControls = this.renderDualAxisControls();

    return (
      <AccordionContainer>
        {dualAxisControls}
        {axisScaleSelector}
        {chartSorting}
        {referenceLinesControls}
      </AccordionContainer>
    );
  }

  renderHistogramControls() {
    const axisScaleSelector = this.renderAxisScaleSelector();
    const referenceLinesControls = this.renderReferenceLinesControls();

    return (
      <AccordionContainer>
        {axisScaleSelector}
        {referenceLinesControls}
      </AccordionContainer>
    );
  }

  renderTimelineChartControls() {
    const { metadata, vifAuthoring } = this.props;
    const dimension = getDimension(vifAuthoring);
    const chartSorting = !isDimensionTypeCalendarDate(metadata, dimension) ?
      this.renderChartSorting() :
      null;

    const axisScaleSelector = this.renderAxisScaleSelector();
    const referenceLinesControls = this.renderReferenceLinesControls();

    return (
      <AccordionContainer>
        {axisScaleSelector}
        {chartSorting}
        {referenceLinesControls}
      </AccordionContainer>
    );
  }

  renderPieChartControls() {
    const chartSorting = this.renderChartSorting();

    return (
      <AccordionContainer>
        {chartSorting}
      </AccordionContainer>
    );
  }

  renderEmptyPane() {
    return <EmptyPane />;
  }

  render() {
    const { vifAuthoring } = this.props;

    let configuration;

    if (isBarChart(vifAuthoring)) {
      configuration = this.renderBarChartControls();
    } else if (isColumnChart(vifAuthoring)) {
      configuration = this.renderColumnChartControls();
    } else if (isComboChart(vifAuthoring)) {
      configuration = this.renderComboChartControls();
    } else if (isHistogram(vifAuthoring)) {
      configuration = this.renderHistogramControls();
    } else if (isTimelineChart(vifAuthoring)) {
      configuration = this.renderTimelineChartControls();
    } else if (isPieChart(vifAuthoring)) {
      configuration = this.renderPieChartControls();
    } else {
      configuration = this.renderEmptyPane();
    }

    return (
      <form>
        {configuration}
      </form>
    );
  }
}

AxisPane.defaultProps = {
  chartSorting: _.cloneDeep(CHART_SORTING)
};

AxisPane.propTypes = {
  chartSorting: PropTypes.arrayOf(PropTypes.object),
  onAppendReferenceLine: PropTypes.func,
  onRemoveReferenceLine: PropTypes.func,
  onSetOrderBy: PropTypes.func,
  onSetReferenceLineColor: PropTypes.func,
  onSetReferenceLineLabel: PropTypes.func,
  onSetReferenceLineValue: PropTypes.func,
  timelinePrecision: PropTypes.arrayOf(PropTypes.object)
};

const mapDispatchToProps = {
  onAppendReferenceLine: appendReferenceLine,
  onRemoveReferenceLine: removeReferenceLine,
  onSetOrderBy: setOrderBy,
  onSetReferenceLineColor: setReferenceLineColor,
  onSetReferenceLineLabel: setReferenceLineLabel,
  onSetReferenceLineValue: setReferenceLineValue
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(AxisPane);
