// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  removeDrilldownColumn,
  setCenterAndZoom,
  setCurrentDrilldownColumnName,
  setDimension,
  setFilters,
  setMapType,
  setOrderBy,
  setShowNullsAsFalse,
  setVisualizationType,
  setXAxisScalingMode
} from '../../actions';
import {
  getCurrentMetadata,
  getMapType,
  getRecommendedDimensions,
  getValidDimensions,
  hasData,
  isDimensionTypeCheckbox
} from '../../selectors/metadata';
import {
  hasDimensionWithDrilldowns,
  getAnyDimension,
  getChartFilters,
  getDimension,
  getDimensionGrouping,
  getDrilldowns,
  getVisualizationType,
  hasVisualizationType,
  isNewGLMap,
  isScatterChart
} from '../../selectors/vifAuthoring';
import Dimension from './Dimension';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { atLeastOneDrilldownFilter } from 'common/components/FilterBar/lib/drilldowns';
import {
  getNewFilters as getNewFiltersForDrilldown,
  getFiltersWithoutRemovedDrilldown
} from 'common/components/FilterBar/lib/drilldowns';
import AutocompleteColumnSelector from './AutocompleteColumnSelector';

export class DimensionSelector extends Component {
  onChangeDimension = (dimension) => {
    if (dimension == null) {
      return;
    }

    const {
      metadata,
      onSetCurrentDrilldownColumnName,
      onSetDimension,
      onSetFilters,
      onSetOrderBy,
      onSetXAxisScalingMode,
      vifAuthoring
    } = this.props;
    const filters = getChartFilters(vifAuthoring);

    if (atLeastOneDrilldownFilter(filters)) {
      const drilldownDimensions = _.map(getDrilldowns(vifAuthoring), 'columnName');
      const newFilters = getNewFiltersForDrilldown(
        [dimension.value, ...drilldownDimensions],
        filters,
        metadata.datasetUid,
        metadata.data.columns
      );
      onSetFilters(newFilters);
      onSetCurrentDrilldownColumnName(dimension.value);
    }

    onSetDimension(dimension.value);

    if (dimension.type === 'calendar_date') {
      onSetOrderBy({ parameter: 'dimension', sort: 'asc' });
    } else {
      onSetOrderBy({ parameter: 'measure', sort: 'desc' });
      onSetXAxisScalingMode({ shouldFit: false });
    }

    // Set a visualization type if not set
    if (!hasVisualizationType(vifAuthoring)) {
      this.setDefaultVisualizationType(dimension);
    }

    this.tryUnsetShowNullsAsFalse(dimension.value);
  };

  tryUnsetShowNullsAsFalse = (dimensionColumnName) => {
    const { metadata, onSetShowNullsAsFalse, vifAuthoring } = this.props;
    const dimension = { columnName: dimensionColumnName };
    const grouping = getDimensionGrouping(vifAuthoring);
    const hasCheckboxDimension = !_.isNil(dimension) && isDimensionTypeCheckbox(metadata, dimension);
    const hasCheckboxGrouping = !_.isNil(grouping) && isDimensionTypeCheckbox(metadata, grouping);

    if (!hasCheckboxDimension && !hasCheckboxGrouping) {
      onSetShowNullsAsFalse(false);
    }
  };

  setDefaultVisualizationType(dimension) {
    const { metadata, onChangeVisualizationType, onSetMapType, onSetVisualizationType } = this.props;

    if (_.includes(['amount', 'checkbox', 'double', 'money', 'number', 'string', 'text'], dimension.type)) {
      onSetVisualizationType('columnChart');
    } else if (_.includes(['calendar_date', 'date', 'floating_timestamp'], dimension.type)) {
      onSetVisualizationType('timelineChart');
    } else if (
      _.includes(['line', 'location', 'multipoint', 'multipolygon', 'point', 'polygon'], dimension.type)
    ) {
      const isNewGLMapEnabled = FeatureFlags.value('enable_new_maps');
      if (isNewGLMapEnabled) {
        onSetVisualizationType('map');
        const mapType = getMapType(metadata, { columnName: dimension.value });
        onSetMapType(mapType);
        setTimeout(() => {
          if (onChangeVisualizationType) {
            onChangeVisualizationType();
          }
        });
      } else {
        onSetVisualizationType('featureMap');
      }
    }
  }

  onDeleteDimension = (dimension) => {
    const {
      vifAuthoring,
      onRemoveDrilldownColumn,
      onSetCurrentDrilldownColumnName,
      onSetDimension,
      onSetFilters
    } = this.props;

    const drilldowns = getDrilldowns(vifAuthoring);
    if (drilldowns.length < 1) {
      return;
    }
    // Remove the first drilldown and make it the dimension
    const removeDimensionColumnName = _.get(getDimension(vifAuthoring), 'columnName');
    const firstDrilldownColumnName = _.get(drilldowns, [0, 'columnName']);
    const filters = getChartFilters(vifAuthoring);
    const newFilters = getFiltersWithoutRemovedDrilldown(
      filters,
      drilldowns,
      firstDrilldownColumnName,
      removeDimensionColumnName
    );

    onSetDimension(firstDrilldownColumnName);
    onSetCurrentDrilldownColumnName(firstDrilldownColumnName);
    onRemoveDrilldownColumn(0);
    onSetFilters(newFilters);
  };

  renderDimensionOption = (recommended, option) => {
    const containerProps = {
      className: classNames('dataset-column-selector-option', {
        'recommended-container': recommended
      })
    };

    const dimensionProps = {
      name: option.title,
      recommended,
      type: option.type
    };

    return (
      <div {...containerProps}>
        <Dimension {...dimensionProps} />
      </div>
    );
  };

  renderDimensionSelector = () => {
    const { metadata, onSetCenterAndZoom, onSetDimension, onSetMapType, vifAuthoring } = this.props;
    const dimension = getAnyDimension(vifAuthoring);
    const scope = 'shared.visualizations.panes.data.fields.dimension';
    const visualizationType = getVisualizationType(vifAuthoring);
    const value = dimension.columnName;
    const recommendedDimensionRenderer = this.renderDimensionOption.bind(this, true);
    const dimensionRenderer = this.renderDimensionOption.bind(this, false);
    const buildOption = (recommended, group) => {
      return (dimension) => ({
        group,
        render: recommended ? recommendedDimensionRenderer : dimensionRenderer,
        title: dimension.name,
        type: dimension.renderTypeName,
        value: dimension.fieldName
      });
    };
    const toRenderableOption = buildOption(false, I18n.t('groups.all_columns', { scope }));
    const drilldownDimensions = _.map(getDrilldowns(vifAuthoring), 'columnName');
    const toRenderableRecommendedOption = buildOption(true, I18n.t('groups.recommended_columns', { scope }));
    const renderableOptions = _.chain(getValidDimensions(metadata))
      .filter((validDimension) => !_.includes(drilldownDimensions, validDimension.fieldName))
      .map(toRenderableOption)
      .value();
    const renderableRecommendedOptions = _.chain(getRecommendedDimensions(metadata, visualizationType))
      .filter((recommendedDimension) => !_.includes(drilldownDimensions, recommendedDimension.fieldName))
      .map(toRenderableRecommendedOption)
      .value();

    if (isNewGLMap(vifAuthoring)) {
      const dropdownProps = {
        id: 'geo-column-selection',
        label: I18n.t('title', { scope }),
        onSelection: (dimension) => {
          onSetDimension(dimension.value);
          const mapType = getMapType(metadata, { columnName: dimension.value });
          onSetMapType(mapType);
          onSetCenterAndZoom(null);
        },
        options: [...renderableRecommendedOptions, ...renderableOptions],
        value
      };

      return (
        <div className="geo-column-selector-container">
          <AutocompleteColumnSelector {...dropdownProps} />
        </div>
      );
    } else {
      const dropdownProps = {
        // should this be a class name?
        id: 'dimension-selection',
        label: I18n.t('title', { scope }),
        onDelete: this.onDeleteDimension,
        onSelection: this.onChangeDimension,
        options: [...renderableRecommendedOptions, ...renderableOptions],
        placeholder: I18n.t('placeholder', { scope }),
        shouldRenderDeleteColumnLink: hasDimensionWithDrilldowns(vifAuthoring),
        value
      };

      return (
        <div className="dimension-selector-container">
          <AutocompleteColumnSelector {...dropdownProps} />
        </div>
      );
    }
  };

  render() {
    const { metadata, vifAuthoring } = this.props;

    // exclude scatter charts because they have a x and y selector rather than dimension and measure selectors
    return hasData(metadata) && !isScatterChart(vifAuthoring) ? this.renderDimensionSelector() : null;
  }
}

DimensionSelector.propTypes = {
  metadata: PropTypes.object,
  onChangeVisualizationType: PropTypes.func,
  onRemoveDrilldownColumn: PropTypes.func,
  onSetCenterAndZoom: PropTypes.func,
  onSetCurrentDrilldownColumnName: PropTypes.func,
  onSetDimension: PropTypes.func,
  onSetFilters: PropTypes.func,
  onSetOrderBy: PropTypes.func,
  onSetShowNullsAsFalse: PropTypes.func,
  onSetXAxisScalingMode: PropTypes.func,
  onSetVisualizationType: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onRemoveDrilldownColumn: removeDrilldownColumn,
  onSetCenterAndZoom: setCenterAndZoom,
  onSetCurrentDrilldownColumnName: setCurrentDrilldownColumnName,
  onSetDimension: setDimension,
  onSetFilters: setFilters,
  onSetMapType: setMapType,
  onSetShowNullsAsFalse: setShowNullsAsFalse,
  onSetOrderBy: setOrderBy,
  onSetVisualizationType: setVisualizationType,
  onSetXAxisScalingMode: setXAxisScalingMode
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(DimensionSelector);
