// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setLabelBottom,
  setLabelLeft,
  setLabelRight,
  setLabelTop
} from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedInput from '../../shared/DebouncedInput';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.presentation';

export class AxisTitlesSelector extends Component {

  renderVisualizationLabel = (id, onChange, title, value) => {
    const labelProps = { className: 'block-label', htmlFor: id };
    const inputProps = { className: 'text-input', id, onChange, value };

    return (
      <div className="authoring-field">
        <label {...labelProps}>{title}</label>
        <DebouncedInput {...inputProps} />
      </div>
    );
  };

  render() {
    const {
      bottom,
      left,
      onSetLabelBottom,
      onSetLabelLeft,
      onSetLabelRight,
      onSetLabelTop,
      right,
      top,
      vifAuthoring
    } = this.props;

    const axisLabels = selectors.getAxisLabels(vifAuthoring);

    const bottomLabel = bottom ?
      this.renderVisualizationLabel(
        'label-bottom',
        (event) => onSetLabelBottom(event.target.value),
        I18n.t('fields.bottom_axis_title.title', { scope }),
        _.get(axisLabels, 'bottom', '')
      ) : null;

    const leftLabel = left ?
      this.renderVisualizationLabel(
        'label-left',
        (event) => onSetLabelLeft(event.target.value),
        I18n.t('fields.left_axis_title.title', { scope }),
        _.get(axisLabels, 'left', '')
      ) : null;

    const rightLabel = right ?
      this.renderVisualizationLabel(
        'label-right',
        (event) => onSetLabelRight(event.target.value),
        I18n.t('fields.right_axis_title.title', { scope }),
        _.get(axisLabels, 'right', '')
      ) : null;

    const topLabel = top ?
      this.renderVisualizationLabel(
        'label-top',
        (event) => onSetLabelTop(event.target.value),
        I18n.t('fields.top_axis_title.title', { scope }),
        _.get(axisLabels, 'top', '')
      ) : null;

    return (
      <div>
        {leftLabel}
        {rightLabel}
        {bottomLabel}
        {topLabel}
      </div>
    );
  }
}

AxisTitlesSelector.propTypes = {
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  onSetLabelBottom: PropTypes.func,
  onSetLabelLeft: PropTypes.func,
  onSetLabelRight: PropTypes.func,
  onSetLabelTop: PropTypes.func,
  right: PropTypes.bool,
  top: PropTypes.bool,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetLabelBottom: setLabelBottom,
  onSetLabelLeft: setLabelLeft,
  onSetLabelRight: setLabelRight,
  onSetLabelTop: setLabelTop
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(AxisTitlesSelector);
