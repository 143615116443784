// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setPrimaryColor } from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import ColorPicker from 'common/components/ColorPicker';
import I18n from 'common/i18n';

// Constants
import { COLORS } from '../../../constants';

const scope = 'shared.visualizations.panes.presentation';

export class ColorSelector extends Component {

  render() {
    const { onSetPrimaryColor, vifAuthoring } = this.props;
    const primaryColor = selectors.getPrimaryColor(vifAuthoring);
    const colorPickerAttributes = {
      handleColorChange: (primaryColor) => onSetPrimaryColor(0, primaryColor),
      palette: COLORS,
      value: primaryColor
    };
    const label = selectors.isScatterChart(vifAuthoring) ?
       I18n.t('fields.point_color.title', { scope }) :
       I18n.t('fields.bar_color.title', { scope });

    return (
      <div>
        <label className="block-label" htmlFor="primary-color">{label}</label>
        <ColorPicker {...colorPickerAttributes} />
      </div>
    );
  }
}

ColorSelector.propTypes = {
  onSetPrimaryColor: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetPrimaryColor: setPrimaryColor
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(ColorSelector);
