// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setColorPaletteProperties,
  setLineStylePointRadius,
  setLineStylePoints,
  setShowDimensionLabels,
  setShowLineValueLabels,
  setShowNullsAsFalse,
  setShowValueLabels,
  setShowValueLabelsAsPercent
} from 'common/authoring_workflow/actions';
import { getCheckboxColumns, getCurrentMetadata } from 'common/authoring_workflow/selectors/metadata';
import * as selectors from 'common/authoring_workflow/selectors/vifAuthoring';
import DimensionLabelRotationSelector from './DimensionLabelRotationSelector';
import I18n from 'common/i18n';

// Constants
import {
  DEFAULT_CIRCLE_HIGHLIGHT_RADIUS,
  LINE_STYLE_POINTS_CLOSED
} from 'common/visualizations/views/SvgConstants';

const scope = 'shared.visualizations.panes.presentation';

export class LabelsSelector extends Component {
  renderShowValueLabelsControls() {
    const { onSetShowValueLabels, vifAuthoring } = this.props;
    const inputAttributes = {
      checked: selectors.getShowValueLabels(vifAuthoring),
      id: 'show-value-labels',
      onChange: (event) => onSetShowValueLabels(event.target.checked),
      type: 'checkbox'
    };

    const title = selectors.isComboChart(vifAuthoring)
      ? I18n.t('fields.show_column_value_labels.title', { scope })
      : I18n.t('fields.show_value_labels.title', { scope });

    return (
      <div className="authoring-field">
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-value-labels">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {title}
          </label>
        </div>
      </div>
    );
  }

  renderShowLineValueLabelsControls() {
    const { onSetShowLineValueLabels, vifAuthoring } = this.props;
    const inputAttributes = {
      checked: selectors.getShowLineValueLabels(vifAuthoring),
      id: 'show-line-value-labels',
      onChange: (event) => onSetShowLineValueLabels(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field">
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-line-value-labels">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_line_value_labels.title', { scope })}
          </label>
        </div>
      </div>
    );
  }

  renderShowDataPointsControls() {
    const { onSetLineStylePointRadius, onSetLineStylePoints, vifAuthoring } = this.props;
    const checked = selectors.getLineStylePoints(vifAuthoring) === 'closed';
    const inputAttributes = {
      checked,
      id: 'show-data-points',
      onChange: (event) => {
        const points = event.target.checked ? LINE_STYLE_POINTS_CLOSED : null;
        onSetLineStylePoints(points);

        const pointRadius = event.target.checked ? DEFAULT_CIRCLE_HIGHLIGHT_RADIUS : null;
        onSetLineStylePointRadius(pointRadius);
      },
      type: 'checkbox'
    };

    return (
      <div className="authoring-field">
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-data-points">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_data_points.title', { scope })}
          </label>
        </div>
      </div>
    );
  }

  hasCheckboxColumns() {
    const { metadata, vifAuthoring } = this.props;
    const columns = getCheckboxColumns(metadata);
    const lookupTable = _.keyBy(columns, 'fieldName');
    const series = selectors.getSeries(vifAuthoring);

    for (let i = 0; i < series.length; i++) {
      const item = series[i];
      const dimensionColumnName = _.get(item, 'dataSource.dimension.columnName');
      if (!_.isNil(lookupTable[dimensionColumnName])) {
        return true;
      }
      const groupingColumnName = _.get(item, 'dataSource.dimension.grouping.columnName');
      if (!_.isNil(lookupTable[groupingColumnName])) {
        return true;
      }
      const measureColumnName = _.get(item, 'dataSource.measure.columnName');
      if (!_.isNil(lookupTable[measureColumnName])) {
        return true;
      }
    }

    return false;
  }

  renderShowNullsAsFalse() {
    const { onSetShowNullsAsFalse, onSetColorPaletteProperties, vifAuthoring } = this.props;
    const showNullsAsFalse = selectors.getShowNullsAsFalse(vifAuthoring);
    const isScatterChart = selectors.isScatterChart(vifAuthoring);
    const inputAttributes = {
      defaultChecked: showNullsAsFalse,
      id: 'show-nulls-as-false',
      onChange: (event) => {
        onSetShowNullsAsFalse(event.target.checked);

        if (isScatterChart) {
          onSetColorPaletteProperties();
        }
      },
      type: 'checkbox'
    };

    return (
      <div className="authoring-field">
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-nulls-as-false">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_nulls_as_false.title', { scope })}
          </label>
        </div>
      </div>
    );
  }

  getShowPercentLabelsLabelText() {
    const { vifAuthoring } = this.props;
    if (selectors.isBarChart(vifAuthoring)) {
      return I18n.t('fields.label_chart_bars_as_percent.title', { scope });
    } else if (selectors.isColumnChart(vifAuthoring)) {
      return I18n.t('fields.label_chart_columns_as_percent.title', { scope });
    } else {
      // default to pie slices
      return I18n.t('fields.label_chart_slices_as_percent.title', { scope });
    }
  }

  renderShowPercentLabelsControls() {
    const { onSetShowValueLabelsAsPercent, vifAuthoring } = this.props;
    const showLabels = selectors.getShowValueLabels(vifAuthoring);
    const inputAttributes = {
      defaultChecked: selectors.getShowValueLabelsAsPercent(vifAuthoring),
      disabled: !showLabels,
      id: 'show-value-labels-as-percent',
      onChange: (event) => onSetShowValueLabelsAsPercent(event.target.checked),
      type: 'checkbox'
    };
    const authoringFieldClasses = classNames('authoring-field', {
      disabled: !showLabels
    });
    const labelText = this.getShowPercentLabelsLabelText();
    return (
      <div className={authoringFieldClasses}>
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-value-labels-as-percent">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {labelText}
          </label>
        </div>
      </div>
    );
  }

  renderDimensionLabelsControls() {
    const { onSetShowDimensionLabels, vifAuthoring } = this.props;
    const inputAttributes = {
      defaultChecked: selectors.getShowDimensionLabels(vifAuthoring),
      id: 'show-dimension-labels',
      onChange: (event) => onSetShowDimensionLabels(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field dimension-labels-container">
        <div className="checkbox">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-dimension-labels">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_dimension_labels.title', { scope })}
          </label>
        </div>
        {this.renderDimensionLabelRotationControls()}
      </div>
    );
  }

  renderDimensionLabelRotationControls() {
    const shouldRender = selectors.getShowDimensionLabels(this.props.vifAuthoring);

    return shouldRender ? <DimensionLabelRotationSelector /> : null;
  }

  render() {
    const { vifAuthoring } = this.props;

    const shouldRenderDimensionLabelsControls = selectors.isChartOfType([
      'barChart',
      'columnChart',
      'comboChart',
      'timelineChart'
    ])(vifAuthoring);

    const shouldRenderShowValueLabelsControls =
      selectors.isChartOfType(['barChart', 'columnChart', 'timelineChart', 'comboChart', 'pieChart'])(
        vifAuthoring
      ) && !selectors.hasErrorBars(vifAuthoring);

    const shouldRenderShowLineValueLabelControls = selectors.isComboChart(vifAuthoring);

    const shouldRenderShowPercentValueLabelsControls =
      (selectors.isBarChart(vifAuthoring) ||
        selectors.isColumnChart(vifAuthoring) ||
        selectors.isPieChart(vifAuthoring)) &&
      !selectors.isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring);

    const shouldRenderShowDataPointsControls =
      selectors.isTimelineChart(vifAuthoring) ||
      (selectors.isComboChart(vifAuthoring) && selectors.hasSeriesVariantLine(vifAuthoring));

    const shouldRenderShowNullsAsFalse =
      selectors.isChartOfType([
        'barChart',
        'columnChart',
        'timelineChart',
        'comboChart',
        'pieChart',
        'scatterChart'
      ])(vifAuthoring) && this.hasCheckboxColumns();

    if (
      !shouldRenderShowValueLabelsControls &&
      !shouldRenderDimensionLabelsControls &&
      !shouldRenderShowPercentValueLabelsControls &&
      !shouldRenderShowDataPointsControls &&
      !shouldRenderShowNullsAsFalse
    ) {
      return null;
    }

    const dimensionLabels = shouldRenderDimensionLabelsControls ? this.renderDimensionLabelsControls() : null;
    const valueLabels = shouldRenderShowValueLabelsControls ? this.renderShowValueLabelsControls() : null;
    const lineValueLabels = shouldRenderShowLineValueLabelControls
      ? this.renderShowLineValueLabelsControls()
      : null;
    const percentValueLabels = shouldRenderShowPercentValueLabelsControls
      ? this.renderShowPercentLabelsControls()
      : null;
    const dataPoints = shouldRenderShowDataPointsControls ? this.renderShowDataPointsControls() : null;
    const showNullsAsFalse = shouldRenderShowNullsAsFalse ? this.renderShowNullsAsFalse() : null;

    return (
      <div>
        {dimensionLabels}
        {valueLabels}
        {lineValueLabels}
        {percentValueLabels}
        {dataPoints}
        {showNullsAsFalse}
      </div>
    );
  }
}

LabelsSelector.propTypes = {
  onSetColorPaletteProperties: PropTypes.func,
  onSetLineStylePointRadius: PropTypes.func,
  onSetLineStylePoints: PropTypes.func,
  onSetShowDimensionLabels: PropTypes.func,
  onSetShowLineValueLabels: PropTypes.func,
  onSetShowNullsAsFalse: PropTypes.func,
  onSetShowValueLabels: PropTypes.func,
  onSetShowValueLabelsAsPercent: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorPaletteProperties: setColorPaletteProperties,
  onSetLineStylePointRadius: setLineStylePointRadius,
  onSetLineStylePoints: setLineStylePoints,
  onSetShowDimensionLabels: setShowDimensionLabels,
  onSetShowLineValueLabels: setShowLineValueLabels,
  onSetShowNullsAsFalse: setShowNullsAsFalse,
  onSetShowValueLabels: setShowValueLabels,
  onSetShowValueLabelsAsPercent: setShowValueLabelsAsPercent
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelsSelector);
