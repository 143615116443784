// Vendor Imports
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import {
  setDimensionGroupingColumnName,
  setShowNullsAsFalse
} from '../../../actions';
import {
  getCurrentMetadata,
  getValidDimensions,
  isDimensionTypeCheckbox
} from '../../../selectors/metadata';
import {
  getDimension,
  getDimensionGroupingColumnName
} from '../../../selectors/vifAuthoring';
import BlockLabel from 'common/components/BlockLabel';
import SingleColumnSelector from '../../shared/SingleColumnSelector';
import I18n from 'common/i18n';

export class DimensionGroupingColumnNameSelector extends Component {
  render() {
    const {
      metadata,
      vifAuthoring
    } = this.props;

    const validDimensions = _.map(
      getValidDimensions(metadata),
      dimension => ({
        title: dimension.name,
        value: dimension.fieldName
      })
    );

    const selectedDimension = getDimension(vifAuthoring);
    const validGroupingDimensions = _.reject(
      validDimensions,
      { value: _.get(selectedDimension, 'columnName') }
    );

    const scope = 'shared.visualizations.panes.data.fields.dimension_grouping_column_name';

    const options = [
      {
        title: I18n.t('no_value', { scope }),
        value: null
      },
      ...validGroupingDimensions
    ];

    const columnName = getDimensionGroupingColumnName(vifAuthoring);

    const dropdownAttributes = {
      id: 'dimension-grouping-column-name-selection',
      onSelection: (option) => this.onChangeDimensionGrouping(option.value),
      options,
      value: columnName
    };

    const labelAttributes = {
      description: I18n.t('description', { scope }),
      htmlFor: dropdownAttributes.id,
      title: I18n.t('subtitle', { scope })
    };

    return (
      <div className="authoring-field">
        <BlockLabel {...labelAttributes} />
        <SingleColumnSelector {...dropdownAttributes} />
      </div>
    );
  }

  onChangeDimensionGrouping(groupingColumnName) {
    this.props.onSetDimensionGroupingColumnName(groupingColumnName);
    this.tryUnsetShowNullsAsFalse(groupingColumnName);
  }

  tryUnsetShowNullsAsFalse(groupingColumnName) {
    const { metadata, onSetShowNullsAsFalse, vifAuthoring } = this.props;
    const dimension = getDimension(vifAuthoring);
    const grouping = { columnName: groupingColumnName };
    const hasCheckboxDimension = !_.isNil(dimension) && isDimensionTypeCheckbox(metadata, dimension);
    const hasCheckboxGrouping = !_.isNil(grouping) && isDimensionTypeCheckbox(metadata, grouping);

    if (!hasCheckboxDimension && !hasCheckboxGrouping) {
      onSetShowNullsAsFalse(false);
    }
  }
}

DimensionGroupingColumnNameSelector.propTypes = {
  metadata: PropTypes.object,
  onSetDimensionGroupingColumnName: PropTypes.func,
  onSetShowNullsAsFalse: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetDimensionGroupingColumnName: setDimensionGroupingColumnName,
  onSetShowNullsAsFalse: setShowNullsAsFalse
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(DimensionGroupingColumnNameSelector);
