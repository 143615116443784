// Vendor Imports
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setDateDisplayFormat } from '../../../actions';
import { getDateDisplayFormat, getPrecision } from '../../../selectors/vifAuthoring';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { DATE_DISPLAY_FORMATS } from 'common/visualizations/helpers/DateHelpers';

export class DateDisplayFormatSelector extends Component {
  renderFormatOption(option) {
    return (
      <div>
        {option.title}
      </div>
    );
  }

  render() {
    const {
      dateDisplayFormat,
      onSetDateDisplayFormat,
      precision
    } = this.props;

    const disabled = _.isNil(precision);
    let formats;

    if (disabled) {
      formats = [];
    } else if (precision === 'none') {
      formats = [
        null,
        ..._.get(DATE_DISPLAY_FORMATS, 'day', []),
        ..._.get(DATE_DISPLAY_FORMATS, 'month', []),
        ..._.get(DATE_DISPLAY_FORMATS, 'year', [])
      ];
    } else {
      formats = _.get(DATE_DISPLAY_FORMATS, precision, []);
    }

    const scope = 'shared.visualizations.panes.data.fields.date_display_format';
    const now = moment('2017-09-23', 'YYYY-MM-DD');
    const options = _.map(formats, (format) => ({
      render: this.renderFormatOption,
      title: _.isNil(format) ? I18n.t('none', { scope }) : now.format(format),
      value: format
    }));

    const dropdownAttributes = {
      disabled,
      id: 'date-display-format-selection',
      onSelection: (option) => onSetDateDisplayFormat(option.value),
      options,
      value: dateDisplayFormat
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="date-display-format">
          {I18n.t('title', { scope })}
        </label>
        <div className="authoring-field">
          <Dropdown {...dropdownAttributes} />
        </div>
      </div>
    );
  }
}

DateDisplayFormatSelector.propTypes = {
  onSetDateDisplayFormat: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetDateDisplayFormat: setDateDisplayFormat
};

const mapStateToProps = state => ({
  dateDisplayFormat: getDateDisplayFormat(state.vifAuthoring),
  precision: getPrecision(state.vifAuthoring)
});

export default connect(mapStateToProps, mapDispatchToProps)(DateDisplayFormatSelector);
