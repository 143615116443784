// Project Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Vendor Imports
import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';
import {
  getNewFilters as getNewFiltersForDrilldown,
  getFiltersWithoutRemovedDrilldown
} from 'common/components/FilterBar/lib/drilldowns';
import { getCurrentMetadata, getNonGeoLocationColumns, hasData } from '../../../selectors/metadata';
import MultiColumnSelector from '../../shared/MultiColumnSelector';
import {
  addDrilldownColumn,
  changeDrilldownColumn,
  removeDrilldownColumn,
  setCurrentDrilldownColumnName,
  setFilters
} from '../../../actions';
import {
  getCurrentDrilldownColumnName,
  getDimension,
  getDrilldowns,
  getChartFilters,
  isBarChart,
  isColumnChart,
  isPieChart
} from '../../../selectors/vifAuthoring';
import { HIERARCHY_LIMITS } from '../../../constants';

// Constants
const scope = 'shared.visualizations.panes.data';

export class DrilldownHierarchySelector extends Component {
  onAddDrilldown = (drilldownColumnName) => {
    const { vifAuthoring, onAddDrilldownColumn, onSetFilters, onSetCurrentDrilldownColumnName, metadata } =
      this.props;
    const filters = getChartFilters(vifAuthoring);
    const drilldowns = _.map(getDrilldowns(vifAuthoring), 'columnName');
    const dimension = _.get(getDimension(vifAuthoring), 'columnName');
    const allDrilldowns = [dimension, ...drilldowns, drilldownColumnName];
    const newFilters = getNewFiltersForDrilldown(allDrilldowns, filters, metadata.datasetUid, metadata.data.columns);

    if (_.isEmpty(drilldowns)) {
      onSetCurrentDrilldownColumnName(dimension);
    }
    onSetFilters(newFilters);
    onAddDrilldownColumn(drilldownColumnName);
  };

  onRemoveDrilldown = (index) => {
    const { vifAuthoring, onRemoveDrilldownColumn, onSetCurrentDrilldownColumnName, onSetFilters } =
      this.props;
    const drilldowns = getDrilldowns(vifAuthoring);
    const dimensionColumnName = _.get(getDimension(vifAuthoring), 'columnName');
    const currentDrilldownColumnName = getCurrentDrilldownColumnName(vifAuthoring);
    const removeDrilldownColumnName = _.get(drilldowns, [index, 'columnName']);
    const previousDrilldownColumnName = _.get(drilldowns, [index - 1, 'columnName'], dimensionColumnName);
    const filters = getChartFilters(vifAuthoring);
    const withOutCurrentDrillDownColumnFilters = getFiltersWithoutRemovedDrilldown(
      filters,
      drilldowns,
      dimensionColumnName,
      removeDrilldownColumnName
    );

    if (currentDrilldownColumnName === removeDrilldownColumnName) {
      onSetCurrentDrilldownColumnName(previousDrilldownColumnName);
    }

    onSetFilters(withOutCurrentDrillDownColumnFilters);
    onRemoveDrilldownColumn(index);
  };

  onChangeDrilldown = (index, columnName) => {
    const { vifAuthoring, onChangeDrilldownColumn, onSetFilters, onSetCurrentDrilldownColumnName, metadata } =
      this.props;
    const filters = getChartFilters(vifAuthoring);
    const currentDrilldownColumnName = getCurrentDrilldownColumnName(vifAuthoring);
    const changedDrilldownDimension = _.get(getDrilldowns(vifAuthoring), [index, 'columnName']);
    const dimension = _.get(getDimension(vifAuthoring), 'columnName');
    const drilldowns = _.map(getDrilldowns(vifAuthoring), (drilldown, drilldownIndex) => {
      if (drilldownIndex === index) {
        return columnName;
      }
      return drilldown.columnName;
    });

    const newFilters = getNewFiltersForDrilldown(
      [dimension, ...drilldowns],
      filters,
      metadata.datasetUid,
      metadata.data.columns
    );

    if (changedDrilldownDimension === currentDrilldownColumnName) {
      onSetCurrentDrilldownColumnName(columnName);
    }

    onSetFilters(newFilters);
    onChangeDrilldownColumn(index, columnName);
  };

  renderDrilldowns = () => {
    const { metadata, vifAuthoring } = this.props;
    const drilldowns = _.map(getDrilldowns(vifAuthoring), 'columnName');
    const dimension = _.get(getDimension(vifAuthoring), 'columnName');
    const selectedDimensions = _.compact([dimension, ...drilldowns]);
    const isStrictPermissions = FeatureFlags.value('strict_permissions');
    const disableAddColumnLink = isStrictPermissions
      ? _.size(drilldowns) >= HIERARCHY_LIMITS.SCGC
      : _.size(drilldowns) >= HIERARCHY_LIMITS.ODP;
    const flyoutDescription = isStrictPermissions
      ? I18n.t('fields.hierarchy.limit', { count: HIERARCHY_LIMITS.SCGC, scope })
      : I18n.t('fields.hierarchy.limit', { count: HIERARCHY_LIMITS.ODP, scope });

    const columnSelectorAttributes = {
      addColumnLinkTitle: I18n.t('fields.hierarchy.add_value', { scope }),
      additionalFlyoutColumns: _.map(getDrilldowns(vifAuthoring), 'columnName'),
      columns: _.sortBy(getNonGeoLocationColumns(metadata), 'name'),
      disableAddColumnLink,
      flyoutDescription,
      labelFromIndex: (index) => I18n.t('fields.hierarchy.label', { scope, number: index + 2 }),
      listItemKeyPrefix: 'drill-down',
      onAdd: this.onAddDrilldown,
      onDelete: this.onRemoveDrilldown,
      onUpdate: this.onChangeDrilldown,
      selectedValues: selectedDimensions,
      shouldRenderAddColumnLink: true,
      shouldRenderDeleteColumnLink: true
    };
    const shouldRenderDrilldownHierarchy =
      isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) || isPieChart(vifAuthoring);

    return shouldRenderDrilldownHierarchy ? <MultiColumnSelector {...columnSelectorAttributes} /> : null;
  };

  render() {
    const { metadata } = this.props;

    return hasData(metadata) ? this.renderDrilldowns() : null;
  }
}

DrilldownHierarchySelector.propTypes = {
  metadata: PropTypes.object,
  onAddDrilldownColumn: PropTypes.func,
  onChangeDrilldownColumn: PropTypes.func,
  onRemoveDrilldownColumn: PropTypes.func,
  onSetCurrentDrilldownColumnName: PropTypes.func,
  onSetFilters: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onAddDrilldownColumn: addDrilldownColumn,
  onChangeDrilldownColumn: changeDrilldownColumn,
  onRemoveDrilldownColumn: removeDrilldownColumn,
  onSetCurrentDrilldownColumnName: setCurrentDrilldownColumnName,
  onSetFilters: setFilters
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(DrilldownHierarchySelector);
