import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SocrataIcon from 'common/components/SocrataIcon';
import classNames from 'classnames';

export class VisualizationPreviewWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true
    };
  }

  onDismiss() {
    this.setState({isVisible: false});
  }

  render() {
    const className = classNames('visualization-preview-warning-container', {
      'hidden': !this.state.isVisible
    });
    return (
      <div className={className}>
        <div className="visualization-preview-warning alert warning">
          <SocrataIcon name="socrata-icon-warning" />
          {this.props.text}
          <button className="visualization-preview-logarithmic-scale-text-dismiss btn btn-transparent" onClick={() => this.onDismiss()}>
            <SocrataIcon name="close-2" />
          </button>
        </div>
      </div>
    );
  }
}

VisualizationPreviewWarning.propTypes = {
  text: PropTypes.string.isRequired
};

export default VisualizationPreviewWarning;
