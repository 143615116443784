// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setGroupingOrderBy } from '../../../actions';
import { getGroupingOrderBy, getOrderBy } from '../../../selectors/vifAuthoring';
import BlockLabel from 'common/components/BlockLabel';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

// Constants
import { CHART_SORTING } from '../../../constants';

export class DimensionGroupingOrderBySelector extends Component {
  renderChartSortingOption(option) {
    return (
      <div>
        <span className={option.icon}></span> {option.title}
      </div>
    );
  }

  render() {
    const { onSetGroupingOrderBy, vifAuthoring } = this.props;
    const scope = 'shared.visualizations.panes.data.fields.dimension_grouping_order_by';

    let orderBy = getGroupingOrderBy(vifAuthoring);

    if (_.isNil(orderBy)) {
      orderBy = getOrderBy(vifAuthoring);

      if (_.isNil(orderBy)) {
        orderBy = { parameter: 'dimension', sort: 'asc' };
      }
    }

    const options = _.map(CHART_SORTING, (option) => _.extend({}, option, {
      render: this.renderChartSortingOption,
      value: `${option.orderBy.parameter}-${option.orderBy.sort}`
    }));

    const dropdownAttributes = {
      id: 'dimension-grouping-order-by-selection',
      onSelection: (option) => onSetGroupingOrderBy(option.orderBy),
      options,
      value: `${orderBy.parameter}-${orderBy.sort}`
    };

    const labelAttributes = {
      htmlFor: dropdownAttributes.id,
      title: I18n.t('subtitle', { scope })
    };

    return (
      <div className="authoring-field">
        <BlockLabel {...labelAttributes} />
        <Dropdown {...dropdownAttributes} />
      </div>
    );
  }
}

DimensionGroupingOrderBySelector.propTypes = {
  onSetGroupingOrderBy: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetGroupingOrderBy: setGroupingOrderBy
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(DimensionGroupingOrderBySelector);
