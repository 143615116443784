// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setLockCalendarViewControl } from '../../../actions';
import { getLockCalendarViewControl } from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';

export class LockCalendarViewControl extends Component {
  render() {
    const { onSetLockCalendarViewControl, vifAuthoring } = this.props;
    const scope = 'shared.visualizations.panes.presentation.fields';
    const inputAttributes = {
      checked: getLockCalendarViewControl(vifAuthoring),
      id: 'lock-calendar-view-control',
      onChange: (event) => onSetLockCalendarViewControl(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="lock-calendar-view-control">
          <span className="fake-checkbox"><span className="icon-checkmark3"></span></span>
          {I18n.t('lock_calendar_view_control.title', { scope })}
        </label>
      </div>
    );
  }
}

LockCalendarViewControl.propTypes = {
  onSetLockCalendarViewControl: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetLockCalendarViewControl: setLockCalendarViewControl
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(LockCalendarViewControl);
