// Vendor Imports
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setDimensionLabelRotationAngle, setWrapDimensionLabels } from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedInput from '../../shared/DebouncedInput';
import DebouncedSlider from '../../shared/DebouncedSlider';
import I18n from 'common/i18n';
import { getInputDebounceMs } from '../../../constants';
import { getCurrentMetadata, isDimensionTypeCalendarDate } from '../../../selectors/metadata';

export class DimensionLabelRotationSelector extends Component {

  // This function takes an angle and normalizes it to a value between -90 and 90.
  // For example, passing an angle of 270 will be normalized to -90.  Angles outside
  // the -90 to 90 range are invalid and this function returns null for those values.
  normalizeAngle(i) {
    i = i % 360;

    if ((i >= -90) && (i <= 90)) {
      return i;
    }

    if (i >= 270) {
      i -= 360;
      return i;
    }

    if (i <= -270) {
      i += 360;
      return i;
    }

    return null;
  }

  render() {
    const {
      metadata,
      onSetDimensionLabelRotationAngle,
      onSetWrapDimensionLabels,
      vifAuthoring
    } = this.props;

    const scope = 'shared.visualizations.panes.presentation';
    const hasError = _.get(this.state, 'error', false);
    const angle = selectors.getDimensionLabelRotationAngle(vifAuthoring);
    const dimension = selectors.getDimension(vifAuthoring);
    const isDimensionCalendarDate = isDimensionTypeCalendarDate(metadata, dimension);
    const showDimensionLabels = selectors.getShowDimensionLabels(vifAuthoring);

    const enabled = showDimensionLabels && (
      selectors.isColumnChart(vifAuthoring) ||
      selectors.isComboChart(vifAuthoring) ||
      (selectors.isTimelineChart(vifAuthoring) && !isDimensionCalendarDate)
    );

    const negative90ButtonAttributes = {
      className: classNames('btn btn-default btn-sm rotation-negative-90', {
        active: (angle === -90) && enabled
      }),
      disabled: !enabled,
      onClick: () => {
        this.setState({ error: false });
        onSetDimensionLabelRotationAngle(-90);
      },
      type: 'button'
    };

    const positive90ButtonAttributes = {
      className: classNames('btn btn-default btn-sm rotation-positive-90', {
        active: (angle === 90) && enabled
      }),
      disabled: !enabled,
      onClick: () => {
        this.setState({ error: false });
        onSetDimensionLabelRotationAngle(90);
      },
      type: 'button'
    };

    const zeroButtonAttributes = {
      className: classNames('btn btn-default btn-sm rotation-zero', {
        active: (angle === 0) && enabled
      }),
      disabled: !enabled,
      onClick: () => {
        this.setState({ error: false });
        onSetDimensionLabelRotationAngle(0);
      },
      type: 'button'
    };

    const inputAttributes = {
      className: classNames('text-input', {
        'orientation-input-error': hasError
      }),
      disabled: !enabled,
      forceEnterKeyHandleChange: true,
      max: 90,
      min: -90,
      onChange: (event) => {
        const eventAngle = _.toNumber(event.target.value);

        if (!_.isFinite(eventAngle)) {
          this.setState({ error: true });
          return;
        }

        const normalizedAngle = this.normalizeAngle(eventAngle);

        if (_.isNil(normalizedAngle)) {
          this.setState({ error: true });
          return;
        }

        this.setState({ error: false });
        onSetDimensionLabelRotationAngle(normalizedAngle);
      },
      step: 5,
      type: 'number',
      value: angle
    };

    const sliderAttributes = {
      delay: getInputDebounceMs(),
      disabled: !enabled,
      formatLabel: (label) => (_.round(label, 1) + '°'),
      onChange: (value) => {
        this.setState({ error: false });
        onSetDimensionLabelRotationAngle(value);
      },
      rangeMax: 90,
      rangeMin: -90,
      step: 5,
      value: angle
    };

    const errorContainer = hasError ? (
      <div className="orientation-warning-container">
        <span className="socrata-icon-warning"></span>
        {I18n.t('fields.dimension_label_direction.warning', { scope })}
      </div>
    ) : null;

    const abcText = I18n.t('fields.dimension_label_direction.button_label', { scope });

    const wrapInputAttributes = {
      checked: selectors.getWrapDimensionLabels(vifAuthoring),
      id: 'wrap-text',
      onChange: (event) => onSetWrapDimensionLabels(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div>
        <div className="orientation-label-container">
          {I18n.t('fields.dimension_label_direction.title', { scope })}
        </div>
        <div className="orientation-button-container">
          <button {...positive90ButtonAttributes}><span>{abcText}</span></button>
          <button {...negative90ButtonAttributes}><span>{abcText}</span></button>
          <button {...zeroButtonAttributes}><span>{abcText}</span></button>
        </div>
        <div className="orientation-input-slider-container">
          <div>
            <DebouncedInput {...inputAttributes} />
          </div>
          <div className="orientation-slider-container">
            <DebouncedSlider {...sliderAttributes} />
          </div>
        </div>
        {errorContainer}
        <div className="checkbox">
          <input {...wrapInputAttributes} />
          <label className="inline-label" htmlFor="wrap-text">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.wrap_text.title', { scope })}
          </label>
        </div>
      </div>
    );
  }
}

DimensionLabelRotationSelector.propTypes = {
  metadata: PropTypes.object,
  onSetDimensionLabelRotationAngle: PropTypes.func,
  onSetWrapDimensionLabels: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetDimensionLabelRotationAngle: setDimensionLabelRotationAngle,
  onSetWrapDimensionLabels: setWrapDimensionLabels
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(DimensionLabelRotationSelector);
