// Vendor Imports
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { isEqual, get } from 'lodash';

import { ForgeButton, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import {
  VifAuthoring,
  AuthoringWorkflowState,
  FontStyle,
  FontStyleTypes,
  FormatStyle
} from 'common/authoring_workflow/reducers/types';
import {
  updateTableVisualizationHeaderStyle,
  updateTableVisualizationHeaderTextStyle
} from 'common/authoring_workflow/actions';
import { COLORS } from 'common/authoring_workflow/constants';
import ColorPickerWithLabel from 'common/components/ColorPickerWithLabel';
import I18n from 'common/i18n';
import { getHeaderFormat } from 'common/authoring_workflow/selectors/vifAuthoring';
import {
  DEFAULT_TABLE_HEADER_TEXT_COLOR,
  DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
  presentationPaneScope
} from 'common/authoring_workflow/constants';
import './AgGridHeaderFormatSelector.scss';

export interface AgGridHeaderFormatSelectorProps {
  vifAuthoring: VifAuthoring;
  onUpdateHeaderFormatStyle: (newHeaderFormat: FormatStyle) => void;
  onUpdateHeaderFormatTextStyle: (newHeaderTextFormat: FontStyle) => void;
}

const AgGridHeaderFormatSelector = (props: AgGridHeaderFormatSelectorProps): JSX.Element => {
  const { vifAuthoring, onUpdateHeaderFormatStyle, onUpdateHeaderFormatTextStyle } = props;
  const textColorLabel = I18n.t('common.schema_preview.data_types.text');
  const backgroundColorLabel = I18n.t('fields.row_stripe.fill_label', { scope: presentationPaneScope });
  const headerFormat: FormatStyle = getHeaderFormat(vifAuthoring);
  const textColor = headerFormat.textColor || DEFAULT_TABLE_HEADER_TEXT_COLOR;
  const backgroundColor = headerFormat.backgroundColor || DEFAULT_TABLE_HEADER_BACKGROUND_COLOR;
  const fontStyle = useMemo(() => get(headerFormat, 'fontStyle'), [headerFormat]);
  const defaultFontStyle: FontStyle = {
    isBold: true,
    isItalic: false,
    isWrapped: false
  };
  const fontStyleIsBold = fontStyle?.isBold === undefined ? defaultFontStyle.isBold : fontStyle.isBold;
  const fontStyleIsItalic =
    fontStyle?.isItalic === undefined ? defaultFontStyle.isItalic : fontStyle.isItalic;
  const defaultHeaderFormat = {
    textColor: DEFAULT_TABLE_HEADER_TEXT_COLOR,
    backgroundColor: DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
    fontStyle: defaultFontStyle,
    textFont: ''
  };
  const resetButtonLabel = I18n.t('fields.column_format.reset_to_default', { scope: presentationPaneScope });

  const handleColorChange = (key: string, newColor: string) => {
    onUpdateHeaderFormatStyle({
      ...(headerFormat ? headerFormat : defaultHeaderFormat),
      [key]: newColor
    });
  };

  const handleTextStyleChange = (textStyle: string) => {
    const currentFontStyle = { isBold: fontStyleIsBold, isItalic: fontStyleIsItalic };
    onUpdateHeaderFormatTextStyle({
      ...(fontStyle ? fontStyle : defaultFontStyle),
      [textStyle]: !currentFontStyle[textStyle]
    });
  };

  const handleResetHeaderFormat = () => {
    onUpdateHeaderFormatStyle(defaultHeaderFormat);
  };

  const isHeaderFormatEqual = (selectedFormat: FormatStyle) => {
    const selectedHeaderFormat = {
      textColor: selectedFormat.textColor || DEFAULT_TABLE_HEADER_TEXT_COLOR,
      backgroundColor: selectedFormat.backgroundColor || DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
      fontStyle: {
        isBold: selectedFormat?.fontStyle?.isBold === undefined ? true : selectedFormat.fontStyle.isBold,
        isItalic:
          selectedFormat?.fontStyle?.isItalic === undefined ? false : selectedFormat.fontStyle.isItalic,
        isWrapped: false
      },
      textFont: ''
    };
    return isEqual(selectedHeaderFormat, defaultHeaderFormat);
  };

  return (
    <div className="ag-grid-header-format-container">
      <div className="text-formatting">
        <div className="header-color-selector">
          <ColorPickerWithLabel
            keyId="text"
            color={textColor}
            onChangeColor={(newColor: string) => {
              handleColorChange('textColor', newColor);
            }}
            label={textColorLabel}
            palette={COLORS}
          />
          <ColorPickerWithLabel
            keyId="fill"
            color={backgroundColor}
            onChangeColor={(newColor: string) => {
              handleColorChange('backgroundColor', newColor);
            }}
            label={backgroundColorLabel}
            palette={COLORS}
          />
        </div>
        <ForgeIconButton dense densityLevel={2} toggle isOn={fontStyleIsBold}>
          <button
            type="button"
            aria-label="bold"
            onClick={() => handleTextStyleChange(FontStyleTypes.isBold)}
          >
            <ForgeIcon name="format_bold" forge-icon-button-on />
            <ForgeIcon name="format_bold" />
          </button>
        </ForgeIconButton>
        <ForgeIconButton dense densityLevel={2} toggle isOn={fontStyleIsItalic}>
          <button
            type="button"
            aria-label="italic"
            onClick={() => handleTextStyleChange(FontStyleTypes.isItalic)}
          >
            <ForgeIcon name="format_italic" forge-icon-button-on />
            <ForgeIcon name="format_italic" />
          </button>
        </ForgeIconButton>
      </div>
      <ForgeButton className="ag-grid-reset-format-button">
        <button
          aria-label={resetButtonLabel}
          onClick={handleResetHeaderFormat}
          disabled={isHeaderFormatEqual(headerFormat)}
        >
          <ForgeIcon name="refresh" />
          <span>{resetButtonLabel}</span>
        </button>
      </ForgeButton>
    </div>
  );
};

const mapDispatchToProps = {
  onUpdateHeaderFormatStyle: updateTableVisualizationHeaderStyle,
  onUpdateHeaderFormatTextStyle: updateTableVisualizationHeaderTextStyle
};

const mapStateToProps = (state: AuthoringWorkflowState) => ({
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(AgGridHeaderFormatSelector);
