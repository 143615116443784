import React from 'react';

import I18n from 'common/i18n';
import {
  AG_GRID_BOOLEAN_FILTER,
  AG_GRID_NUMBER_FILTER,
  AG_GRID_TEXT_FILTER,
  AgFilterDataType
} from 'common/authoring_workflow/constants';
import BlockLabel from 'common/components/BlockLabel';
import { ForgeSelect } from '@tylertech/forge-react';

export interface OperatorSelectorProps {
  condition: string;
  dataTypeName: string;
  onConditionSelect: (condition: string, index?: number) => void;
  index?: number;
}

const OperatorSelector = (props: OperatorSelectorProps): JSX.Element => {
  const { condition, dataTypeName, onConditionSelect, index } = props;

  const getFilterOptions = () => {
    let options;
    switch (dataTypeName) {
      case AgFilterDataType.NUMBER:
        options = AG_GRID_NUMBER_FILTER.options;
        break;
      case AgFilterDataType.TEXT:
        options = AG_GRID_TEXT_FILTER.options;
        break;
      case AgFilterDataType.BOOLEAN:
        options = AG_GRID_BOOLEAN_FILTER.options;
        break;
      default:
        return {};
    }
    return options.map((option) => ({
      label: option.title,
      value: option.value
    }));
  };

  const dropDownAttributes = {
    value: condition,
    alwaysCalculateHeight: true,
    onChange: (event: CustomEvent) => {
      onConditionSelect(event.detail, index);
    },
    options: getFilterOptions(),
    id: 'operator-dropdown',
    'data-testid': 'operator-dropdown'
  };

  return (
    <div id="condition-selector-container">
      <ForgeSelect {...dropDownAttributes} />
    </div>
  );
};

export default OperatorSelector;
