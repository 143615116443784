// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setEventBackgroundColor, setEventTextColor, setEventOutlineColor } from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import ColorPicker from 'common/components/ColorPicker';
import I18n from 'common/i18n';

// Constants
import { COLORS } from '../../../constants';

const scope = 'shared.visualizations.panes.presentation';

export class CalendarColorSelector extends Component {
  renderColorPicker = ({ className, label, value, onSetColor }) => {
    const colorPickerAttributes = {
      handleColorChange: (color) => onSetColor(color),
      palette: COLORS,
      value
    };

    return (
      <div className={className}>
        <label className="block-label" htmlFor="calendar-event-color">{label}</label>
        <ColorPicker {...colorPickerAttributes} />
      </div>
    );
  };

  renderEventOutlineColor = () => {
    const { onSetEventOutlineColor, vifAuthoring } = this.props;
    const eventOutlineColor = selectors.getEventOutlineColor(vifAuthoring);
    const label = I18n.t('fields.event_outline_color', { scope });

    return this.renderColorPicker({
      className: 'calendar-event-outline-color',
      label,
      value: eventOutlineColor,
      onSetColor: onSetEventOutlineColor
    });
  };

  renderEventBackgroundColor = () => {
    const { onSetEventBackgroundColor, vifAuthoring } = this.props;
    const eventBackgroundColor = selectors.getEventBackgroundColor(vifAuthoring);
    const label = I18n.t('fields.event_background_color', { scope });

    return this.renderColorPicker({
      className: 'calendar-event-background-color',
      label,
      value: eventBackgroundColor,
      onSetColor: onSetEventBackgroundColor
    });
  };

  renderEventTextColor = () => {
    const { onSetEventTextColor, vifAuthoring } = this.props;
    const eventTextColor = selectors.getEventTextColor(vifAuthoring);
    const label = I18n.t('fields.event_text_color', { scope });

    return this.renderColorPicker({
      className: 'calendar-event-text-color',
      label,
      value: eventTextColor,
      onSetColor: onSetEventTextColor
    });
  };

  render() {
    return (
      <div className="calendar-color-selector">
        {this.renderEventTextColor()}
        {this.renderEventBackgroundColor()}
        {this.renderEventOutlineColor()}
      </div>
    );
  }
}

CalendarColorSelector.propTypes = {
  onSetEventBackgroundColor: PropTypes.func,
  onSetEventOutlineColor: PropTypes.func,
  onSetEventTextColor: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetEventBackgroundColor: setEventBackgroundColor,
  onSetEventOutlineColor: setEventOutlineColor,
  onSetEventTextColor: setEventTextColor
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(CalendarColorSelector);
