import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateName } from './actions';
import Alert from './components/Alert';
import AuthoringWorkflowModal from './components/AuthoringWorkflowModal';
import FilterBar from './components/FilterBar';
import InfoPane from './components/InfoPane';
import PreviewBar from './components/PreviewBar';
import ShareVisualizationModal from './components/ShareVisualizationModal';
import SigninModal from './components/SigninModal';
import Table from './components/Table';
import Visualizations from './components/Visualizations';
import VizCanAssetActionBar from './components/VizCanAssetActionBar';
import SetCanvasNameModal from './components/SetCanvasNameModal';
import SaveNotification from './components/SaveNotification';
import { ModeStates, VIZ_PANE_CSS_ANIMATION_DELAY } from './lib/constants';
import { isDeskTop } from 'common/visualizations/helpers/MediaQueryHelper';
import { getNonHiddenFilters } from 'common/components/FilterBar/lib/allFilters';
import FeatureFlags from 'common/feature_flags';
import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';
import * as configApi from 'common/core/configurations';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { getCurrentDomain } from 'common/currentDomain';
import I18n from 'common/i18n';


export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerticalFilterBarExpanded: this.shouldExpandVerticalFilterBar(),
      companyName: I18n.t('common.metadata.this_organization')
    };

    _.bindAll(this, [
      'renderEditMode',
      'renderPreviewMode',
      'renderTable',
      'renderViewMode',
      'setSiteChromeVisibility']
    );
  }

  componentDidMount() {
    this.setSiteChromeVisibility();
    this.fetchSiteThemeConfig();
  }

  fetchSiteThemeConfig = async () => {
    const themeSiteConfig = await configApi.fetchSiteThemeConfig(getCurrentDomain());
    var themeProperties = configApi.handleSiteThemeConfig(themeSiteConfig);
    var companyName = themeProperties.find(({ name }) => name === 'strings.company');
    this.setState({ companyName: companyName.value });
  };

  shouldExpandVerticalFilterBar = () => {
    return isDeskTop() && !this.isReadOnlyAndEmptyFilters();
  };

  isReadOnlyAndEmptyFilters = () => {
    const { filters, isPending, mode } = this.props;
    const isReadOnly = (mode === ModeStates.EDIT) ? isPending : true;

    return isReadOnly && _.isEmpty(getNonHiddenFilters(filters, isReadOnly));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.setSiteChromeVisibility();
    }
  }

  getCanvasBodyClassNames(modeClassName) {
    const { isAuthoringWorkflowActive } = this.props;
    const { isVerticalFilterBarExpanded } = this.state;

    return classNames(
      'visualization-canvas-body',
      modeClassName,
      {
        'vertical-filter-expanded': isVerticalFilterBarExpanded,
        'is-authoring-experience-active': isAuthoringWorkflowActive
      }
    );
  }

  onInEditFilterChange = (filter) => {
    $(this._visualizationRef).
      find('.socrata-visualization-renderer').
      trigger(
        'SOCRATA_VISUALIZATION_IN_EDIT_FILTER_CHANGED',
        filter
      );
  };

  onUpdate = (filters) => {
    $(this._visualizationRef).
      find('.socrata-visualization-renderer').
      trigger(
        'SOCRATA_VISUALIZATION_FILTER_CHANGED',
        { filters }
      );
  };

  onVerticalFilterBarToggle = (isVerticalFilterBarExpanded) => {
    this.setState({ isVerticalFilterBarExpanded });
    setTimeout(() => {
      $(this._visualizationRef).
        find('.socrata-visualization-renderer').
        trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }, VIZ_PANE_CSS_ANIMATION_DELAY);
  };

  setSiteChromeVisibility() {
    const { mode, isAuthoringWorkflowActive } = this.props;

    // Site chrome header should not be read by screen readers while AX modal is open
    const siteChromeContainer = document.querySelector('#site-chrome-container');
    if (siteChromeContainer) {
      siteChromeContainer.setAttribute('aria-hidden', isAuthoringWorkflowActive);
    }

    const adminHeader = document.body.querySelector('#site-chrome-admin-header');

    switch (mode) {
      case ModeStates.EDIT:
        document.body.classList.remove('preview-mode');
        document.body.classList.add('hide-site-chrome');
        return;

      case ModeStates.PREVIEW:
        document.body.classList.remove('hide-site-chrome');
        document.body.classList.add('preview-mode');
        return;

      case ModeStates.VIEW:
        document.body.classList.remove('hide-site-chrome');
        document.body.classList.remove('preview-mode');
        return;

      default:
        return;
    }
  }

  renderFilterBar = (filterBarProps) => {
    filterBarProps = _.merge({}, filterBarProps, { showFilterBar: isDeskTop() });
    return <FilterBar.Vertical {...filterBarProps} onToggle={this.onVerticalFilterBarToggle} />;
  };

  renderTable() {
    const { vifs } = this.props;
    const shouldShowTable = _.get(vifs[0], 'configuration.showDataTableControl', true);
    if (shouldShowTable) {
      return (<Table />);
    }
  }

  renderEditMode() {
    const { onNameChanged, alert, isEphemeral, isPending, vifs } = this.props;
    const showNullsAsFalse = _.get(vifs[0], 'configuration.showNullsAsFalse', false);

    const alertBox = alert ? <Alert /> : null;
    const containerClasses = classNames({
      'alert-active': !_.isNull(alertBox),
      ephemeral: isEphemeral
    });

    const filterBarAttributes = {
      isReadOnly: isPending,
      onInEditFilterChange: this.onInEditFilterChange,
      onUpdate: this.onUpdate,
      showNullsAsFalse
    };
    const infoPane = isPending ?
      <InfoPane /> :
      <InfoPane onNameChanged={isEphemeral ? null : onNameChanged} />;

    return (
      <div className={containerClasses}>
        {!isEphemeral && <VizCanAssetActionBar />}
        {isEphemeral && <SaveNotification />}
        {alertBox}
        <div className={this.getCanvasBodyClassNames('edit-mode')}>
          {infoPane}
          <div className="filter-canvas">
            <div ref={(ref) => this._visualizationRef = ref}>
              <Visualizations isEditable />
            </div>
            {/* This is rendered after the visualization for a better tab order. */}
            {this.renderFilterBar(filterBarAttributes)}
            {this.renderTable()}
          </div>
        </div>
        <AuthoringWorkflowModal />
        <ShareVisualizationModal />
        <SigninModal />
        <SetCanvasNameModal />
      </div>
    );
  }

  renderPreviewMode() {
    const showNullsAsFalse = _.get(this.props.vifs[0], 'configuration.showNullsAsFalse', false);
    const filterBarAttributes = {
      onInEditFilterChange: this.onInEditFilterChange,
      onUpdate: this.onUpdate,
      showNullsAsFalse
    };

    return (
      <div>
        <PreviewBar />
        <div className={this.getCanvasBodyClassNames('preview-mode')}>
          <InfoPane />
          <div className="filter-canvas">
            <div ref={(ref) => this._visualizationRef = ref}>
              <Visualizations />
            </div>
            {/* This is rendered after the visualization for a better tab order. */}
            {this.renderFilterBar(filterBarAttributes)}
            {this.renderTable()}
          </div>
        </div>
        <ShareVisualizationModal />
      </div>
    );
  }

  getCommunityAssetMessage() {
    const { companyName } = this.state;
    let message = I18n.t('common.metadata.community_disclosure', { assetType: I18n.t('common.metadata.visualization'), companyName: companyName });
    return message;
  }

  renderViewMode() {
    const { alert } = this.props;
    const showNullsAsFalse = _.get(this.props.vifs[0], 'configuration.showNullsAsFalse', false);
    const filterBarAttributes = {
      onInEditFilterChange: this.onInEditFilterChange,
      onUpdate: this.onUpdate,
      showNullsAsFalse
    };

    const alertBox = alert ? <Alert /> : null;
    const containerClasses = classNames(
      'view-mode',
      {
        'alert-active': !_.isNull(alertBox)
      }
    );
    const filterCanvasClasses = classNames('filter-canvas', {
      'align-right': this.isReadOnlyAndEmptyFilters()
    });

    const disclaimer = this.getCommunityAssetMessage();
    // Community assets disclosure should be shown when the community badge is shown
    const shouldShowCommunityMessage = FeatureFlags.value('disable_authority_badge') == 'none' ||
      FeatureFlags.value('disable_authority_badge') == 'official2';

    return (
      <div className={containerClasses}>
        <VizCanAssetActionBar />
        {alertBox}
        <div className={this.getCanvasBodyClassNames()}>
          <InfoPane />
          {shouldShowCommunityMessage && window.initialState.view.provenance == 'community' &&
            <ForgeInlineMessage >
              <ForgeIcon slot="icon" name="account_group" />
              <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
            </ForgeInlineMessage>}
          <div className={filterCanvasClasses}>
            <div ref={(ref) => this._visualizationRef = ref}>
              <Visualizations />
            </div>
            {/* This is rendered after the visualization for a better tab order. */}
            {this.renderFilterBar(filterBarAttributes)}
            {this.renderTable()}
          </div>
        </div>
        <ShareVisualizationModal />
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="main-spinner">
        <div className="spinner-default spinner-large" />
      </div>
    );
  }

  render() {
    const { mode, isLoading } = this.props;

    if (isLoading) {
      return this.renderLoadingSpinner();
    }

    switch (mode) {
      case ModeStates.EDIT:
        return this.renderEditMode();
      case ModeStates.PREVIEW:
        return this.renderPreviewMode();
      case ModeStates.VIEW:
        return this.renderViewMode();

      default:
        throw new Error(`invalid mode: ${mode}`);
    }
  }
}

App.propTypes = {
  // App mode, e.g. edit, preview, or view
  mode: PropTypes.oneOf(_.values(ModeStates)).isRequired,

  // Function that responds to the viz-can name being changed
  onNameChanged: PropTypes.func.isRequired,

  // Information to display in an alert box
  alert: PropTypes.object,

  // Whether or not this VizCan is ephemeral.
  isEphemeral: PropTypes.bool,

  // If we should show a spinner.
  isLoading: PropTypes.bool,
  vifs: PropTypes.array
};

function mapStateToProps(state) {
  const approvalsGuidance = _.get(state, 'approvalsGuidance', null);
  return {
    ..._.pick(state, 'mode', 'alert', 'isEphemeral', 'vifs', 'filters'),
    isLoading: state.navigatingAway,
    isPending: approvalsGuidance ? withGuidanceV2(approvalsGuidance).isPending() : false,
    isAuthoringWorkflowActive: state.authoringWorkflow.isActive
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onNameChanged: name => dispatch(updateName({ name }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
