// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setMeasureAxisPrecision } from '../../../actions';
import { getMeasureAxisPrecision } from '../../../selectors/vifAuthoring';
import PrecisionSelector from '../../shared/PrecisionSelector';
import { isLogarithmicScale } from '../../../selectors/vifAuthoring';

export class AxisPrecisionSelectorSingle extends Component {
  render() {
    const { onSetMeasureAxisPrecision, vifAuthoring } = this.props;
    const customPrecisionDisabled = isLogarithmicScale(vifAuthoring);
    const precision = getMeasureAxisPrecision(vifAuthoring);
    const selectorAttributes = {
      id: 'measure-axis-precision',
      className: 'measure-axis-precision-container',
      customPrecisionDisabled,
      precision,
      onSetPrecision: onSetMeasureAxisPrecision
    };

    return (
      <PrecisionSelector {...selectorAttributes} />
    );
  }
}

AxisPrecisionSelectorSingle.propTypes = {
  onSetMeasureAxisPrecision: PropTypes.func
};

const mapDispatchToProps = {
  onSetMeasureAxisPrecision: setMeasureAxisPrecision
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(AxisPrecisionSelectorSingle);
