// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setUserActive, setUserIdle } from '../../actions';
import { getInputDebounceMs } from '../../constants';
import { isUserCurrentlyActive } from '../../selectors/vifAuthoring';
import Slider from 'common/components/Slider';

export class DebouncedSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.timeoutId = null;
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({
        value: nextProps.value
      });
    }
  };

  handleChange(value) {
    this.setState(
      { value },
      () => {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        if (!isUserCurrentlyActive(this.props.vifAuthoring)) {
          this.props.onSetUserActive();
        }

        this.timeoutId = setTimeout(() => {
          this.props.onSetUserIdle();
          return this.props.onChange(this.state.value);
        }, getInputDebounceMs());
      }
    );
  }

  render() {
    const props = _.omit(this.props, ['value', 'onChange', 'onSetUserActive', 'onSetUserIdle', 'vifAuthoring']);
    return <Slider {...props} value={this.state.value} onChange={this.handleChange} />;
  }
}

DebouncedSlider.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSetUserActive: PropTypes.func,
  onSetUserIdle: PropTypes.func
};

DebouncedSlider.defaultProps = {
  disabled: false
};

const mapDispatchToProps = {
  onSetUserActive: setUserActive,
  onSetUserIdle: setUserIdle
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(DebouncedSlider);
