import React from 'react';
import { ForgeSelect } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import { NUMBER_PRECISION_STYLES } from 'common/authoring_workflow/constants';
import { PrecisionStyle } from 'common/types/viewColumn';

const scope = 'shared.dataset_management_ui.format_column';

export interface PrecisionStyleProps {
  precisionStyle: string | undefined;
  onPrecisionStyleChange: (newAlignment: PrecisionStyle) => void;
}

const AgGridNumberPrecisionStyle = (props: PrecisionStyleProps) => {
  const { precisionStyle, onPrecisionStyleChange } = props;

  const precisionStyleProps = {
    'data-testid': 'ag-grid-number-precision-style',
    options: NUMBER_PRECISION_STYLES,
    value: precisionStyle,
    label: I18n.t('display_format', { scope }),
    floatLabelType: 'always',
    onChange: (event: CustomEvent) => {
      onPrecisionStyleChange(event.detail);
    }
  };

  return (
    <div className="authoring-field">
      <ForgeSelect {...precisionStyleProps} />
    </div>
  );
};

export default AgGridNumberPrecisionStyle;
