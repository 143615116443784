// Vendor Imports
import _ from 'lodash';
import I18n from 'common/i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setDateDisplayFormat, setPrecision } from '../../actions';
import { getCurrentMetadata, isDimensionTypeCalendarDate } from '../../selectors/metadata';
import { getCurrentDimensionColumnName, getPrecision } from '../../selectors/vifAuthoring';
import Dropdown from 'common/components/Dropdown';
import { getDefaultDateDisplayFormat } from 'common/visualizations/helpers/DateHelpers';

// Constants
import { TIMELINE_PRECISION } from '../../constants';

export class TimelinePrecisionSelector extends Component {
  renderTimelinePrecisionOption(option) {
    return (
      <div>
        {option.title}
      </div>
    );
  }

  render() {
    const {
      metadata,
      onSetDateDisplayFormat,
      onSetPrecision,
      timelinePrecision,
      vifAuthoring
    } = this.props;

    const defaultPrecision = getPrecision(vifAuthoring) || null;

    const options = _.map(timelinePrecision, (option) => {
      option.render = this.renderTimelinePrecisionOption;
      return option;
    });

    const currentDimensionColumnName = getCurrentDimensionColumnName(vifAuthoring);
    const disabled = !isDimensionTypeCalendarDate(metadata, currentDimensionColumnName);

    const attributes = {
      disabled,
      id: 'timeline-precision-selection',
      onSelection: (option) => {
        const precision = option.value;
        onSetPrecision(precision);

        const format = getDefaultDateDisplayFormat(precision);
        onSetDateDisplayFormat(format);
      },
      options,
      value: defaultPrecision
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="timeline-precision">
          {I18n.t('shared.visualizations.panes.data.fields.timeline_precision.title')}
        </label>
        <div id="timeline-precision" className="authoring-field">
          <Dropdown {...attributes} />
        </div>
      </div>
    );
  }
}

TimelinePrecisionSelector.defaultProps = {
  timelinePrecision: _.cloneDeep(TIMELINE_PRECISION)
};

TimelinePrecisionSelector.propTypes = {
  metadata: PropTypes.object,
  onSetDateDisplayFormat: PropTypes.func,
  onSetPrecision: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetDateDisplayFormat: setDateDisplayFormat,
  onSetPrecision: setPrecision
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelinePrecisionSelector);
