import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Modal, { ModalContent, ModalHeader, ModalFooter } from 'common/components/Modal';
import Button from 'common/components/Button';

import SaveButton from './SaveButton';
import { SaveStates } from '../lib/constants';

import { editVisualization, save, updateName } from '../actions';

export class SetCanvasNameModal extends Component {
  state = {
    name: ''
  };

  onCancel = () => {
    const { onCancel } = this.props;

    if (_.isFunction(onCancel)) {
      onCancel();
    }
  };

  onChangeTitle = (e) => {
    // Don't dispatch an action just yet - changes in this modal should be ephemeral
    // until the user accepts.
    this.setState({ name: e.target.value });
  };

  onAccept = (e) => {
    const { onAcceptName } = this.props;
    const { name } = this.state;

    if (!_.isEmpty(name) && _.isFunction(onAcceptName)) {
      e.preventDefault();
      onAcceptName(name);
    }
  };

  render() {
    const { busy, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal onDismiss={this.onCancel} className="set-name-modal">
        <ModalHeader onDismiss={this.onCancel} />
        <ModalContent>
          <form onSubmit={this.onAccept}>
            <label className="block-label">
              <h1>{I18n.t('visualization_canvas.name_canvas')}</h1>
              <input
                type="text"
                id="description"
                className="text-input text-area"
                onChange={this.onChangeTitle}
                value={this.state.name} />
            </label>
          </form>
        </ModalContent>
        <ModalFooter>
          <Button onClick={this.onCancel} disabled={busy}>
            {I18n.t('visualization_canvas.cancel')}
          </Button>
          <SaveButton
            dark='false'
            onClick={this.onAccept}
            disabled={_.isEmpty(this.state.name) || busy} />
        </ModalFooter>
      </Modal>
    );
  }
}

SetCanvasNameModal.propTypes = {
  busy: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAcceptName: PropTypes.func,
  onCancel: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isOpen: state.nameModalOpen,
    busy: state.saveState === SaveStates.SAVING
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onAcceptName: (name) => {
      dispatch(updateName({ name }));
      dispatch(save());
    },
    onCancel: () => {
      dispatch(editVisualization({ vifIndex: 0 })); // Re-open AX.
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SetCanvasNameModal);
