// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setMeasureAxisPrecision,
  setSecondaryMeasureAxisPrecision
} from '../../../actions';
import {
  getMeasureAxisPrecision,
  getSecondaryMeasureAxisPrecision,
  isUsingPrimaryAxisOnly,
  isUsingSecondaryAxisOnly
} from '../../../selectors/vifAuthoring';
import AxisPrecisionSelector from './AxisPrecisionSelector';
import Tabs from '../../shared/Tabs';
import I18n from 'common/i18n';

// Constants
import {
  MEASURE_AXIS_PRECISION_AUTOMATIC,
  MEASURE_AXIS_PRECISION_CUSTOM
} from '../../../constants';

const scope = 'shared.visualizations.panes.data.fields.combo_chart_measure_axis_options';

export class AxisPrecisionSelectorDual extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'onRadioButtonChange'
    ]);

    this.state = {
      tabIndex: this.props.selectedTabIndex || 0
    };
  }

  onRadioButtonChange(event) {
    const {
      onSetMeasureAxisPrecision,
      onSetSecondaryMeasureAxisPrecision,
      vifAuthoring
    } = this.props;
    const value = _.get(event, 'target.value');
    const precision = this.isPrimaryAxis() ?
      getMeasureAxisPrecision(vifAuthoring) :
      getSecondaryMeasureAxisPrecision(vifAuthoring);

    const setPrecision = this.isPrimaryAxis(vifAuthoring) ?
      onSetMeasureAxisPrecision :
      onSetSecondaryMeasureAxisPrecision;

    if (value === MEASURE_AXIS_PRECISION_AUTOMATIC) {
      setPrecision();
    } else if ((value === MEASURE_AXIS_PRECISION_CUSTOM) && !_.isFinite(precision)) {
      setPrecision(0);
    }
  }

  isPrimaryAxis() {
    return (this.state.tabIndex === AxisPrecisionSelectorDual.primaryAxisTabIndex);
  }

  renderMeasurePrecisionSelector() {
    const {
      onSetMeasureAxisPrecision,
      onSetSecondaryMeasureAxisPrecision,
      vifAuthoring
    } = this.props;
    const disabled =
      (this.isPrimaryAxis() && isUsingSecondaryAxisOnly(vifAuthoring)) ||
      (!this.isPrimaryAxis() && isUsingPrimaryAxisOnly(vifAuthoring));

    const precision = this.isPrimaryAxis() ?
      getMeasureAxisPrecision(vifAuthoring) :
      getSecondaryMeasureAxisPrecision(vifAuthoring);

    const setPrecision = this.isPrimaryAxis(vifAuthoring) ?
      onSetMeasureAxisPrecision :
      onSetSecondaryMeasureAxisPrecision;

    const selectorAttributes = {
      disabled,
      key: this.state.tabIndex,
      onRadioButtonChange: this.onRadioButtonChange,
      onSliderChange: setPrecision,
      precision
    };

    return (
      <AxisPrecisionSelector {...selectorAttributes} />
    );
  }

  renderTab({ selected, tabIndex, title }) {
    const linkAttributes = {
      className: selected ? 'selected' : null,
      onClick: () => this.setState({ tabIndex })
    };

    return (
      <li>
        <a {...linkAttributes}>{title}</a>
      </li>
    );
  }

  renderTabs() {
    const { tabIndex } = this.state;
    const tabs = [
      {
        onClickTab: (tabIndex) => this.setState({ tabIndex }),
        selected: (tabIndex === AxisPrecisionSelectorDual.primaryAxisTabIndex),
        tabIndex: AxisPrecisionSelectorDual.primaryAxisTabIndex,
        title: I18n.translate('primary_axis', { scope })
      },
      {
        onClickTab: (tabIndex) => this.setState({ tabIndex }),
        selected: (tabIndex === AxisPrecisionSelectorDual.secondaryAxisTabIndex),
        tabIndex: AxisPrecisionSelectorDual.secondaryAxisTabIndex,
        title: I18n.translate('secondary_axis', { scope })
      }
    ];

    return (
      <Tabs tabs={tabs} />
    );
  }

  render() {
    return (
      <div className="measure-axis-precision-dual-container">
        {this.renderTabs()}
        {this.renderMeasurePrecisionSelector()}
      </div>
    );
  }
}

AxisPrecisionSelectorDual.primaryAxisTabIndex = 0;
AxisPrecisionSelectorDual.secondaryAxisTabIndex = 1;

AxisPrecisionSelectorDual.propTypes = {
  metadata: PropTypes.object,
  onSetMeasureAxisPrecision: PropTypes.func,
  onSetSecondaryMeasureAxisPrecision: PropTypes.func,
  selectedTabIndex: PropTypes.number,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetMeasureAxisPrecision: setMeasureAxisPrecision,
  onSetSecondaryMeasureAxisPrecision: setSecondaryMeasureAxisPrecision
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(AxisPrecisionSelectorDual);
