import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ExportFlannel from 'common/components/ExportFlannel';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import FilterPropType from 'common/propTypes/FilterPropType';
import FeatureFlags from 'common/feature_flags';

export class InfoPaneButtons extends PureComponent {

  render() {
    const {
      exportFormats,
      filters,
      parentView,
      vifs
    } = this.props;

    // EN-68996: update this to use parentView.id once we do the db migration
    const parentViewId = FeatureFlags.valueOrDefault('use_vif_dataset_uid_for_vizcan_filters', true) ?
      get(vifs, '[0].series[0].dataSource.datasetUid', parentView.id) : parentView.id;

    const whereClause = SoqlHelpers.whereClauseFilteringOwnColumn(
      {
        format: {
          type: 'visualization_interchange_format',
          version: 3
        },
        series: [
          {
            dataSource: {
              datasetUid: parentViewId, // EN-68996: update this to use parentView.id once we do the db migration
              dimension: {},
              domain: window.location.hostname,
              type: 'socrata.soql',
              filters
            },
            type: 'table'
          }
        ]
      },
      0
    );

    const flannelProps = {
      exportFormats,
      exportFilteredData: true,
      view: parentView,
      whereClause
    };

    return (
      <div className="info-pane-buttons">
        <ExportFlannel {...flannelProps} />
      </div>
    );
  }

}

InfoPaneButtons.propTypes = {
  exportFormats: PropTypes.arrayOf(PropTypes.string),
  parentView: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(FilterPropType)
};

export function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(InfoPaneButtons);
