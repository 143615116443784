// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setColorScale } from '../../../actions';
import { getColorScale } from '../../../selectors/vifAuthoring';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

// Constants
import { COLOR_SCALES } from '../../../constants';

const scope = 'shared.visualizations.panes.presentation';

export class RegionMapSelector extends Component {
  render() {
    const {
      colorScales,
      onSetColorScale,
      vifAuthoring
    } = this.props;
    const defaultColorScale = getColorScale(vifAuthoring);

    const defaultColorScaleKey = _.find(colorScales, colorScale => {
      const negativeColorsMatch = defaultColorScale.negativeColor === colorScale.scale[0];
      const zeroColorsMatch = defaultColorScale.zeroColor === colorScale.scale[1];
      const positiveColorsMatch = defaultColorScale.positiveColor === colorScale.scale[2];

      return negativeColorsMatch && zeroColorsMatch && positiveColorsMatch;
    }).value;

    const colorScaleAttributes = {
      id: 'color-scale',
      onSelection: (colorScale) => onSetColorScale(...colorScale.scale),
      options: colorScales,
      value: defaultColorScaleKey
    };

    return (
      <div>
        <label
          className="block-label"
          htmlFor="color-scale">{I18n.t('fields.color_scale.title', { scope })}</label>
        <div className="color-scale-dropdown-container">
          <Dropdown {...colorScaleAttributes} />
        </div>
      </div>
    );
  }
}

RegionMapSelector.defaultProps = {
  colorScales: COLOR_SCALES
};

RegionMapSelector.propTypes = {
  onSetColorScale: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorScale: setColorScale
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(RegionMapSelector);
