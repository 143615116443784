// Project Imports
import React from 'react';

import Button, { SIZES, VARIANTS } from 'common/components/Button';
import { IconName, SocrataIcon } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

import { Hierarchy } from 'common/visualizations/vif';

// project constants
const scope = 'shared.visualizations.panes.data';

export interface AddHierarchyLinkProps {
  tableHierarchies: Hierarchy[];
  disabled: boolean;
  handleOnNewHierarchy: () => void;
}

const AddHierarchyLink = (props: AddHierarchyLinkProps) => {
  const { tableHierarchies, disabled, handleOnNewHierarchy } = props;

  const buttonAttributes = {
    id: 'add-hierarchy-link',
    disabled: disabled,
    onClick: () => handleOnNewHierarchy(),
    variant: VARIANTS.LINK,
    size: SIZES.SMALL
  };

  // When we remove the 'enable_flexible_table_hierarchies' feature flag
  // we should remove the extraneous translations as well
  const linkTitle = tableHierarchies.length
    ? I18n.t('fields.table_hierarchies.add_another_value', { scope })
    : I18n.t('fields.table_hierarchies.add_value', { scope });

  const linkTitleTabs = tableHierarchies.length
    ? I18n.t('fields.table_hierarchies.flexible_add_another_value', { scope })
    : I18n.t('fields.table_hierarchies.flexible_add_value', { scope });

  const testid = tableHierarchies.length ? 'add-another-value' : 'add-value';

  const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);
  return (
    <div className="add-link-container">
      <Button data-testid={testid} {...buttonAttributes}>
        <SocrataIcon name={IconName.Add} />
        {flexibleHierarchiesEnabled ? linkTitleTabs : linkTitle}
      </Button>
    </div>
  );
};

export default AddHierarchyLink;
