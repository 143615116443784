import _ from 'lodash';
import agTableVif from './agTableVif';
import barChartVif from './barChartVif';
import mapVif from './mapVif';
import regionMapVif from './regionMapVif';
import calendarVif from './calendarVif';
import columnChartVif from './columnChartVif';
import comboChartVif from './comboChartVif';
import pieChartVif from './pieChartVif';
import featureMapVif from './featureMapVif';
import histogramVif from './histogramVif';
import scatterChartVif from './scatterChartVif';
import tableVif from './tableVif';
import dataTableVif from './dataTableVif';
import timelineChartVif from './timelineChartVif';

export default () => _.cloneDeep({
  barChart: barChartVif,
  map: mapVif,
  regionMap: regionMapVif,
  calendar: calendarVif,
  columnChart: columnChartVif,
  comboChart: comboChartVif,
  pieChart: pieChartVif,
  featureMap: featureMapVif,
  histogram: histogramVif,
  scatterChart: scatterChartVif,
  table: tableVif,
  dataTable: dataTableVif,
  agTable: agTableVif,
  timelineChart: timelineChartVif
});
