import _ from 'lodash';
import React, { useEffect } from 'react';
import CustomizationTabPane from './CustomizationTabPane';
import ScrollView from 'common/components/Accordion/ScrollView';

interface ITab {
  id: string;
  paneComponent: any;
}

interface ICustomizationTabPaneProps {
  onChangeVisualizationType: () => void;
  selection: string;
  tabs: any[];
}

const focusFirstTabPaneAccordion = () => {
  const MAX_TIMEOUT = 3000;
  const accumulator = 100;

  let accumulatedTime = 0;
  const intervalId = setInterval(() => {
    const accordion = document.querySelector(
      '.customization-tab-pane:not(.customization-tab-pane_hidden) .socrata-accordion-pane-title'
    ) as HTMLElement;
    const emptyPane = document.querySelector('.authoring-empty-pane');

    if (accordion) {
      accordion.focus();
      clearInterval(intervalId);
    } else if (emptyPane) {
      clearInterval(intervalId);
    } else if (accumulatedTime > MAX_TIMEOUT) {
      clearInterval(intervalId);
    } else {
      accumulatedTime += accumulator;
    }
  }, accumulator);
};

export const CustomizationTabPanes = (props: ICustomizationTabPaneProps) => {
  const { selection, onChangeVisualizationType, tabs } = props;

  useEffect(() => {
    focusFirstTabPaneAccordion();
  }, [selection]);

  const paneAttributes = (tab: ITab) => {
    return {
      key: tab.id,
      id: tab.id,
      show: tab.id === selection
    };
  };

  const pane = (tab: ITab) => {
    const paneProps = { onChangeVisualizationType };
    return (
      <CustomizationTabPane {...paneAttributes(tab)}>
        {React.createElement(tab.paneComponent, paneProps)}
      </CustomizationTabPane>
    );
  };

  return (
    <ScrollView className="visualization-tab-panes">
      {_.map(tabs, (tab) => {
        return pane(tab);
      })}
    </ScrollView>
  );
};
