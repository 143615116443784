// Vendor Imports
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import DebouncedSlider from '../../shared/DebouncedSlider';
import I18n from 'common/i18n';
import {
  getInputDebounceMs,
  MEASURE_AXIS_PRECISION_AUTOMATIC,
  MEASURE_AXIS_PRECISION_CUSTOM
} from '../../../constants';

const scope = 'shared.visualizations.panes.axis.fields.precision';

export class AxisPrecisionSelector extends Component {
  renderRadioButton({ checked, className, disabled, id, label, onChange, value }) {
    const inputAttributes = {
      checked,
      disabled,
      id,
      onChange,
      name: className,
      type: 'radio',
      value
    };
    const radioButtonContainerClassName = `${className} ${id}-selector`;

    return (
      <div className={radioButtonContainerClassName}>
        <input {...inputAttributes} />
        <label htmlFor={id}>
          <span className="fake-radiobutton" />
          <div className="translation-within-label">{label}</div>
        </label>
      </div>
    );
  }

  renderSlider({ disabled, onChange, value }) {
    const sliderAttributes = {
      delay: getInputDebounceMs(),
      disabled,
      onChange,
      rangeMax: 4,
      rangeMin: 0,
      step: 1,
      value
    };

    return (
      <div className="measure-axis-precision-slider-container">
        <DebouncedSlider {...sliderAttributes} />
      </div>
    );
  }

  render() {
    const {
      id,
      className,
      disabled,
      onRadioButtonChange,
      onSliderChange,
      precision,
      customPrecisionDisabled
    } = this.props;

    const axisPrecisionClassName = classNames('authoring-field radiobutton', {
      disabled
    });

    const automaticRadioButton = this.renderRadioButton({
      checked: !_.isFinite(precision),
      className,
      disabled,
      id: `${id}-automatic`,
      label: I18n.t('automatic', { scope }),
      onChange: onRadioButtonChange,
      value: MEASURE_AXIS_PRECISION_AUTOMATIC
    });

    const customRadioButton = this.renderRadioButton({
      checked: _.isFinite(precision),
      className: classNames(className, { disabled: disabled || customPrecisionDisabled }),
      disabled: disabled || customPrecisionDisabled,
      id: `${id}-custom`,
      label: I18n.t('custom', { scope }),
      onChange: onRadioButtonChange,
      value: MEASURE_AXIS_PRECISION_CUSTOM
    });

    const slider = this.renderSlider({
      disabled: disabled || customPrecisionDisabled,
      onChange: onSliderChange,
      value: precision || 0 // default slider to 0 if no precision is defined
    });

    return (
      <div>
        <div className={axisPrecisionClassName}>
          {automaticRadioButton}
          {customRadioButton}
          {slider}
        </div>
      </div>
    );
  }
}

AxisPrecisionSelector.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onRadioButtonChange: PropTypes.func,
  onSliderChange: PropTypes.func,
  precision: PropTypes.number,
  customPrecisionDisabled: PropTypes.bool
};

AxisPrecisionSelector.defaultProps = {
  id: 'axis-precision',
  className: 'axis-precision-container'
};

export default AxisPrecisionSelector;
