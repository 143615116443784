import _ from 'lodash';
import I18n from 'common/i18n';

export const getVisualizationCanvasName = (state) => {
  return _.get(state, 'view.name') ||
    I18n.t('visualization_canvas.info_pane.bootstrap_title', { name: state.parentView.name });
};

export const getVisualizationCanvasDescription = (state) => {
  return _.get(state, 'view.description') || _.get(state, 'parentView.description') || '';
};

// As of https://github.com/socrata/core/pull/1776, VizCan 'views' no longer
// contain column data. Only the parent view (i.e. the backing dataset) for column info
export const getVisualizationCanvasColumns = (state) => {
  return _.get(state, 'parentView.columns', []);
};

export const getVisualizationCanvasComputedColumns = (state) => {
  return _.get(state, 'parentView.computedColumns', []);
};
