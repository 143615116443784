import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import I18n from 'common/i18n';
import confirmUnload from 'visualizationCanvas/lib/confirmUnload';

import airbrake from 'common/airbrake';
import { isDevelopmentEnvironment } from 'common/environment';
import { fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';

// add styling for socrata-viz maps
import 'leaflet/dist/leaflet.css';

import store from './store';
import Root from './root';
import { guidanceLoaded } from './actions';

airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);

window.addEventListener('beforeunload', confirmUnload(store));

// Defer rendering so the spinner in the erb can render.
_.defer(async function () {

  // Render the App, falling back to rendering an error if it fails.
  try {
    if (!window.initialState.isEphemeral) {
      // not using assetIdFor because this is always a published or working copy (never revision or story draft)
      try {
        const guidance = await fetchApprovalsGuidanceV2(window.initialState.view.id); // TODO: Think about providing this globally via Rails.
        store.dispatch(guidanceLoaded(guidance));
      } catch (error) {
        // 404s are expected if the asset is being viewed on a federation target domain - see EN-29416.
        if (_.get(error, 'response.status') !== 404) {
          // Notify but don't re-raise - the application will continue to work with the asset action bar disabled.
          airbrake.notify({
            error,
            context: 'Guidance failed to fetch. Asset action bar will not be shown on this vizcan.'
          });
        }
      }
    }

    ReactDOM.render(
      <Root store={store} />,
      document.querySelector('#app')
    );

  } catch (e) {
    console.error('Fatal error when rendering:', e);

    ReactDOM.render(
      <div>
        <div className="alert error alert-full-width-top">{I18n.t('visualization_canvas.render_error')}</div>
        {isDevelopmentEnvironment && (<pre>{e.stack}</pre>)}
      </div>,
      document.querySelector('.placeholder-wrapper')
    );

    return;
  }

  // Show the footer
  const footer = document.querySelector('#site-chrome-footer');
  if (footer) {
    footer.style.visibility = 'visible';
  }
});
