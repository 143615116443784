// Vendor Imports
import _ from 'lodash';

// Project Imports
import * as actions from '../../actions';
import baseVifReducer from './base';
import { appendSeries } from '../../helpers';
import { CALENDAR } from '../../constants';
import {
  getCalendarDate,
  getLockCalendarViewControl
} from 'common/visualizations/helpers/VifSelectors';

// Constants
import vifs from '../../vifs';

export default function calendar(state, action) {
  if (_.isUndefined(state)) {
    return vifs().calendar;
  }

  state = _.cloneDeep(state);

  switch (action.type) {

    case actions.APPEND_SERIES:
      appendSeries(state, action);
      break;

    case actions.RESET_STATE:
      state = vifs().calendar;
      break;

    case actions.SET_START_DATE_COLUMN:
      _.set(state, `series[${CALENDAR.START_DATE_SERIES_INDEX}].dataSource.dimension.columnName`, action.startDateColumn);
      break;

    case actions.SET_DEFAULT_DISPLAY_DATE:
      _.set(state, 'configuration.defaultDisplayDate', action.defaultDisplayDate);
      break;

    case actions.SET_CURRENT_DISPLAY_DATE:
      _.set(state, 'configuration.currentDisplayDate', action.currentDisplayDate);
      if (getLockCalendarViewControl(state)) {
        // While the user is navigating the calendar in Authoring Workflow,
        // keep defaultDisplayDate in sync with currentDisplayDate
        _.set(state, 'configuration.defaultDisplayDate', action.currentDisplayDate);
      }
      break;

    case actions.SET_LOCK_CALENDAR_VIEW_CONTROL:
      _.set(state, 'configuration.lockCalendarViewControl', action.lockCalendarViewControl);
      if (action.lockCalendarViewControl === true) {
        _.set(state, 'configuration.defaultDisplayDate', getCalendarDate(state));
      } else {
        _.unset(state, 'configuration.defaultDisplayDate');
      }
      break;

    case actions.SET_END_DATE_COLUMN:
      _.set(state, `series[${CALENDAR.END_DATE_SERIES_INDEX}].dataSource.dimension.columnName`, action.endDateColumn);
      break;

    case actions.SET_EVENT_TITLE_COLUMN:
      _.set(state, `series[${CALENDAR.EVENT_TITLE_SERIES_INDEX}].dataSource.dimension.columnName`, action.eventTitleColumn);
      break;

    case actions.RECEIVE_METADATA:
    case actions.REMOVE_SERIES:
    case actions.SET_DATASET_UID:
    case actions.SET_DESCRIPTION:
    case actions.SET_DOMAIN:
    case actions.SET_FILTERS:
    case actions.SET_EVENT_BACKGROUND_COLOR:
    case actions.SET_EVENT_OUTLINE_COLOR:
    case actions.SET_EVENT_TEXT_COLOR:
    case actions.SET_MEASURE_COLUMN:
    case actions.SET_SHOW_DATA_TABLE_CONTROL:
    case actions.SET_TITLE:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}
