// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setCenterAndZoom, setPointAggregation } from '../../../actions';
import { getCurrentMetadata, shouldNotShowRegionMapOption, hasData } from '../../../selectors/metadata';
import { getPointAggregation, getSeries } from '../../../selectors/vifAuthoring';
import MeasureSelector from '../../shared/MeasureSelector';
import RegionSelector from '../../shared/RegionSelector';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.data.fields.region';

export class PointMapAggregationSelector extends Component {
  renderPointAggregationRadioButton(pointAggregation, shouldShowFlyoutAndDisableRadioButton = false) {
    const { onSetCenterAndZoom, onSetPointAggregation, vifAuthoring } = this.props;
    const selectedPointAggregation = getPointAggregation(vifAuthoring);
    const id = `${pointAggregation}_point_aggregation`;
    const inputAttributes = {
      checked: selectedPointAggregation === pointAggregation,
      id,
      disabled: shouldShowFlyoutAndDisableRadioButton,
      name: 'point-aggregation-options',
      type: 'radio',
      onChange: () => {
        onSetPointAggregation(pointAggregation);
        onSetCenterAndZoom(null);
      }
    };
    const className = `${pointAggregation}-point-aggregation-container`;
    const flyoutId = `${pointAggregation}_flyout`;
    const labelAttributes = {
      htmlFor: id,
      'aria-label': flyoutId,
      'data-flyout': flyoutId,
      id: flyoutId,
      className
    };

    return (
      <div className={className}>
        <input {...inputAttributes} />
        <label {...labelAttributes}>
          <span className="fake-radiobutton" />
          <div className="translation-within-label">
            {I18n.t(pointAggregation, {
              scope: 'shared.visualizations.panes.map_layers.fields.point_aggregation_options'
            })}
          </div>
        </label>
        {shouldShowFlyoutAndDisableRadioButton && this.renderFlyoutContent(flyoutId)}
      </div>
    );
  }

  renderFlyoutContent(flyoutId) {
    return (
      <div id={flyoutId} className="flyout flyout-right flyout-hidden">
        <section className="flyout-content">
          <h3 className="flyout-header">
            <div className="visualization-type-title">
              <span
                dangerouslySetInnerHTML={{
                  __html: I18n.t('without_computed_column_and_hidden_computed_column_message', { scope })
                }}
              />
            </div>
          </h3>
        </section>
      </div>
    );
  }

  renderRegionCustomBoundaryAndMeasure() {
    const attributes = {
      series: getSeries(this.props.vifAuthoring).map((seriesItem, index) => {
        return _.extend({ seriesIndex: index }, seriesItem);
      }),
      shouldRenderAddMeasureLink: false,
      shouldRenderDeleteLink: false
    };

    return (
      <div className="region-and-measure-list-container">
        <RegionSelector />
        <div className="measure-list-container">
          <label>
            {I18n.t('combo_chart_measure_selector.title', {
              scope: 'shared.visualizations.panes.data.fields'
            })}
          </label>
          <MeasureSelector {...attributes} />
        </div>
      </div>
    );
  }

  render() {
    const { metadata } = this.props;

    return (
      hasData(metadata) && (
        <div className="radiobutton" id="point-aggregation-options">
          {this.renderPointAggregationRadioButton('none')}
          {this.renderPointAggregationRadioButton('heat_map')}
          {this.renderPointAggregationRadioButton('region_map', shouldNotShowRegionMapOption(metadata))}
          {this.renderRegionCustomBoundaryAndMeasure()}
        </div>
      )
    );
  }
}

PointMapAggregationSelector.propTypes = {
  onSetCenterAndZoom: PropTypes.func,
  onSetPointAggregation: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetCenterAndZoom: setCenterAndZoom,
  onSetPointAggregation: setPointAggregation
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(PointMapAggregationSelector);
