// project imports
import _ from 'lodash';

// data parsers
import { getTableHierarchies } from 'common/authoring_workflow/selectors/vifAuthoring';

import FeatureFlags from 'common/feature_flags';
import { HierarchyColumnConfig } from 'common/visualizations/vif';
import { TableHierarchySelectorProps } from './index';

const useTableHierarchySelectorHooks = (props: TableHierarchySelectorProps) => {
  const { vifAuthoring, updateTableHierarchy } = props;
  const tableHierarchies = getTableHierarchies(vifAuthoring);

  const handleOnRearrangeHierarchy = (
    existingHierarchyColumnConfigs: HierarchyColumnConfig[],
    hierarchyIndex: number,
    currentGroupedColumnConfigs?: HierarchyColumnConfig[]
  ) => {
    if (
      currentGroupedColumnConfigs !== undefined &&
      !_.isEqual(existingHierarchyColumnConfigs, currentGroupedColumnConfigs)
    ) {
      const aggregatedColumns = _.filter(existingHierarchyColumnConfigs, ['isGrouping', false]);
      const newHierarchyColumnConfigs = _.concat(currentGroupedColumnConfigs, aggregatedColumns);
      updateTableHierarchy(newHierarchyColumnConfigs, hierarchyIndex);
    }
  };

  const handleOnUpdateHierarchy = (
    existingHierarchyColumnConfigs: HierarchyColumnConfig[],
    hierarchyIndex: number,
    updatedColumnConfig?: HierarchyColumnConfig
  ) => {
    let hierarchiesColumnConfigsDiff: HierarchyColumnConfig[] = [];

    if (updatedColumnConfig) {
      const foundHierarchyColumnConfig = _.find(
        existingHierarchyColumnConfigs,
        (h) => h.columnName === updatedColumnConfig.columnName
      );

      if (!foundHierarchyColumnConfig) {
        // When a box is checked that does not have an aggregation set or
        // an ungrouped column is hidden
        // add it to the existing hierarchies object.
        hierarchiesColumnConfigsDiff = existingHierarchyColumnConfigs.concat(updatedColumnConfig);
      } else {
        hierarchiesColumnConfigsDiff = existingHierarchyColumnConfigs.map((column) =>
          column.columnName === updatedColumnConfig.columnName ? updatedColumnConfig : column
        );
      }
      updateTableHierarchy(hierarchiesColumnConfigsDiff, hierarchyIndex);
    } else {
      updateTableHierarchy(existingHierarchyColumnConfigs, hierarchyIndex);
    }
  };

  const handleOnUpdateHierarchyName = (hierarchyIndex: number, name: string) => {
    props.updateTableHierarchyName(hierarchyIndex, name);
  };

  const handleOnNewHierarchy = () => {
    props.newTableHierarchy();
  };

  const handleOnRemoveHierarchy = (hierarchyIndex: number) => {
    // remove hierarchy at the passed in index
    props.removeTableHierarchy(hierarchyIndex);
  };

  const handleOnResetHierarchy = (hierarchyIndex: number) => {
    props.resetTableHierarchy(hierarchyIndex);
  };

  const handleOnDuplicateHierarchy = (hierarchyIndex: number) => {
    props.duplicateTableHierarchy(hierarchyIndex);
  };

  const handleOnShowGrandTotal = (hierarchyIndex: number, showGrandTotal: boolean) => {
    props.showGrandTotal(hierarchyIndex, showGrandTotal);
  };

  const handleOnShowSubTotal = (hierarchyIndex: number, showSubTotal: boolean) => {
    props.showSubTotal(hierarchyIndex, showSubTotal);
  };

  return {
    state: {
      tableHierarchies
    },
    functions: {
      handleOnNewHierarchy,
      handleOnRearrangeHierarchy,
      handleOnDuplicateHierarchy,
      handleOnUpdateHierarchy,
      handleOnUpdateHierarchyName,
      handleOnRemoveHierarchy,
      handleOnResetHierarchy,
      handleOnShowGrandTotal,
      handleOnShowSubTotal
    }
  };
};

export default useTableHierarchySelectorHooks;
