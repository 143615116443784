import React from 'react';

import I18n from 'common/i18n';
import { DropdownOption } from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';
import Dimension from 'common/authoring_workflow/components/shared/Dimension';

import { VifAuthoring } from 'common/authoring_workflow/reducers/types';
import { getTableColumns } from 'common/authoring_workflow/selectors/vifAuthoring.js';
import AgAutocomplete from './AgAutocomplete';

// Constants
const scope = 'shared.visualizations.panes.presentation';

export interface ColumnNameProps {
  columnName: string;
  vifAuthoring: VifAuthoring;
  onColumnNameChange: (dimension: DropdownOption) => void;
}

const AgColumnNameSelector = (props: ColumnNameProps): JSX.Element => {
  const { columnName, vifAuthoring, onColumnNameChange } = props;
  const tableColumns = getTableColumns(vifAuthoring);
  const columnNameLabel = I18n.t('fields.ag_column_selector.title', { scope });
  const selectedColumns = tableColumns.filter((column: any) => {
    return (
      column.dataTypeName === 'text' ||
      column.dataTypeName === 'number' ||
      column.dataTypeName === 'checkbox' ||
      column.dataTypeName === 'date' ||
      column.dataTypeName === 'calendar_date'
    );
  });

  const renderColumn = (option: DropdownOption) => {
    const containerProps = {
      className: 'dataset-column-selector-option'
    };

    const dimensionProps = {
      name: option.title,
      recommended: false,
      type: option.type
    };

    return (
      <div {...containerProps}>
        <Dimension {...dimensionProps} />
      </div>
    );
  };

  const columnOptions = selectedColumns.map((dimension: any) => ({
    render: renderColumn,
    title: dimension.name,
    type: dimension.renderTypeName,
    value: dimension.fieldName
  }));

  const dropdownProps = {
    id: 'ag-grid-column-selection',
    label: '',
    placeholder: columnNameLabel,
    onSelection: onColumnNameChange,
    options: columnOptions,
    value: columnName,
    shouldRenderDeleteColumnLink: true
  };

  return (
    <>
      <div className="authoring-field" key="columnSelector">
        <div className="geo-column-selector-container">
          <AgAutocomplete {...dropdownProps} />
        </div>
      </div>
    </>
  );
};

export default AgColumnNameSelector;
