import _ from 'lodash';

import vifs from '../../vifs';
import baseVifReducer from './base';

import * as actions from '../../actions';

export default function table(state, action) {
  if (_.isUndefined(state)) {
    return vifs().table;
  }

  state = _.cloneDeep(state);

  switch (action.type) {
    case actions.RESET_STATE:
      state = vifs().table;
      break;

    case actions.SET_TABLE_COLUMNS:
      const { tableColumns } = action;
      const path = ['series', 0, 'dataSource', 'dimension', 'columns'];
      _.set(state, path, tableColumns);
      break;

    case actions.ADD_TABLE_COLUMN:
    case actions.SET_DESCRIPTION:
    case actions.REMOVE_TABLE_COLUMN:
    case actions.SELECT_ALL_COLUMNS:
    case actions.RESET_ALL_COLUMNS:
    case actions.RECEIVE_METADATA:
    case actions.REORDER_TABLE_COLUMNS:
    case actions.SET_DOMAIN:
    case actions.SET_DATASET_UID:
    case actions.SET_FILTERS:
    case actions.SET_TITLE:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
      return baseVifReducer(state, action);
    default:
      break;
  }

  return state;
}
