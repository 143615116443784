import _ from 'lodash';
import { ModeStates, SaveStates } from './constants';

const message = window.I18n.unsaved_changes;

export default (store) =>
  (event) => {
    const {
      authoringWorkflow,
      isDirty,
      isEphemeral,
      mode,
      saveState,
      signinModal
    } = store.getState();
    const hasChanged = isDirty || authoringWorkflow.isActive;
    const isViewMode = mode === ModeStates.VIEW;
    const isIdle = saveState === SaveStates.IDLE;
    const signinModalActive = _.get(signinModal, 'isActive');

    let isExportLink = false;
    try {
      isExportLink = /accessType=DOWNLOAD/.test(event.target.activeElement.href);
    } catch (ex) {
      // pass through: we didn't find what we're looking for, so it's not export
    }

    if (hasChanged && isIdle && !isViewMode && !isExportLink && !signinModalActive && !isEphemeral) {
      event.returnValue = message;
      return message;
    }
  };
