import React, { Component, Fragment } from 'react';
import { ForgeIcon, ForgeListItem, ForgeTooltip } from '@tylertech/forge-react';

interface ICustomizationTabProps {
  id: string;
  title: string;
  icon: string;
  selected?: boolean;
  onTabNavigation: (tabId: string) => void;
}

class ForgeTab extends Component<ICustomizationTabProps> {
  tooltipAttributes = () => {
    const { id, title } = this.props;

    return {
      target: `.${id}-panel-btn`,
      text: title,
      delay: 500,
      position: 'right'
    };
  };

  listItemAttributes = () => {
    const { id, selected, title, onTabNavigation } = this.props;
    return {
      id: id,
      role: 'tab',
      indented: false,
      'aria-label': title,
      'aria-controls': `${id}-panel`,
      'aria-selected': selected,
      className: `panel-btn ${id}-panel-btn`,
      selected,
      onClick: onTabNavigation.bind(this, id)
    };
  };

  render() {
    const { id, icon } = this.props;

    return (
      <Fragment key={id}>
        <ForgeListItem {...this.listItemAttributes()}>
          <ForgeIcon slot="leading" name={icon} />
        </ForgeListItem>
        <ForgeTooltip {...this.tooltipAttributes()} />
      </Fragment>
    );
  }
}

export default ForgeTab;
