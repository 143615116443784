// Vendor Imports
import _ from 'lodash';

// Project Imports
import * as actions from '../../actions';
import baseVifReducer from './base';
import {
  appendSeries,
  forEachSeries,
  hasMultipleNonFlyoutSeries,
  setBooleanValueOrDefaultValue,
  setBooleanValueOrDeleteProperty,
  setNumericValueOrDeleteProperty,
  setSeriesVariant,
  setStringValueOrDeleteProperty
} from '../../helpers';

// Constants
import vifs from '../../vifs';

export default function comboChart(state, action) {
  if (_.isUndefined(state)) {
    return vifs().comboChart;
  }

  state = _.cloneDeep(state);

  switch (action.type) {

    case actions.APPEND_SERIES:
      appendSeries(state, action);
      break;

    case actions.RESET_STATE:
      state = vifs().comboChart;
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE:
      const customColorPalette = action.customColorPalette;
      const grouping = action.dimensionGroupingColumnName;
      _.set(state, 'series[0].color.customPalette', {
        [grouping]: customColorPalette
      });
      break;

    case actions.SET_DIMENSION_LABEL_AREA_SIZE:
      _.set(state, 'configuration.dimensionLabelAreaSize', action.width);
      break;

    case actions.SET_LIMIT_COUNT_AND_SHOW_OTHER_CATEGORY:
      setBooleanValueOrDeleteProperty(state, 'configuration.showOtherCategory', action.showOtherCategory);

      forEachSeries(state, series => {
        _.set(series, 'dataSource.limit', parseInt(action.limitCount, 10));
      });
      break;

    case actions.SET_LIMIT_NONE_AND_SHOW_OTHER_CATEGORY:
      setBooleanValueOrDeleteProperty(state, 'configuration.showOtherCategory', action.showOtherCategory);

      forEachSeries(state, series => {
        _.unset(series, 'dataSource.limit');
      });
      break;

    case actions.SET_SECONDARY_MEASURE_AXIS_MAX_VALUE:
      setNumericValueOrDeleteProperty(state, 'configuration.secondaryMeasureAxisMaxValue', action.measureAxisMaxValue);
      break;

    case actions.SET_SECONDARY_MEASURE_AXIS_MIN_VALUE:
      setNumericValueOrDeleteProperty(state, 'configuration.secondaryMeasureAxisMinValue', action.measureAxisMinValue);
      break;

    case actions.SET_SECONDARY_MEASURE_AXIS_PRECISION:
      // Use this instead of setNumericValueOrDeleteProperty because 0 is a permissible precision.
      if (_.isFinite(action.precision)) {
        _.set(state, 'configuration.secondaryMeasureAxisPrecision', action.precision);
      } else {
        _.unset(state, 'configuration.secondaryMeasureAxisPrecision');
      }
      break;

    case actions.SET_SECONDARY_MEASURE_AXIS_SCALE:
      setStringValueOrDeleteProperty(state, 'configuration.secondaryMeasureAxisScale', action.scale);
      break;

    case actions.SET_SERIES_VARIANT:
      setSeriesVariant(state, {
        seriesIndex: action.seriesIndex,
        seriesVariant: action.seriesVariant
      });
      break;

    case actions.SET_SHOW_DIMENSION_LABELS:
      setBooleanValueOrDefaultValue(state, 'configuration.showDimensionLabels', action.showDimensionLabels, true);
      break;

    case actions.SET_SHOW_OTHER_CATEGORY:
      setBooleanValueOrDeleteProperty(state, 'configuration.showOtherCategory', action.showOtherCategory);
      break;

    case actions.SET_STACKED:
      if (!action.oneHundredPercent && hasMultipleNonFlyoutSeries(state)) {
        return baseVifReducer(state, action);
      }
      break;

    case actions.SET_USE_SECONDARY_AXIS_FOR_COLUMNS:
      setBooleanValueOrDeleteProperty(state, 'configuration.useSecondaryAxisForColumns', action.useSecondaryAxis);

      const useSecondaryAxisForLines = _.get(state, 'configuration.useSecondaryAxisForLines', false);

      if (action.useSecondaryAxis && useSecondaryAxisForLines) {
        _.unset(state, 'configuration.axisLabels.left');
      } else if (!action.useSecondaryAxis && !useSecondaryAxisForLines) {
        _.unset(state, 'configuration.axisLabels.right');
      }
      break;

    case actions.SET_USE_SECONDARY_AXIS_FOR_LINES:
      setBooleanValueOrDeleteProperty(state, 'configuration.useSecondaryAxisForLines', action.useSecondaryAxis);

      const useSecondaryAxisForColumns = _.get(state, 'configuration.useSecondaryAxisForColumns', false);

      if (action.useSecondaryAxis && useSecondaryAxisForColumns) {
        _.unset(state, 'configuration.axisLabels.left');
      } else if (!action.useSecondaryAxis && !useSecondaryAxisForColumns) {
        _.unset(state, 'configuration.axisLabels.right');
      }

      if (action.useSecondaryAxis) {
        _.unset(state, 'configuration.measureAxisScale');
      } else {
        _.unset(state, 'configuration.secondaryMeasureAxisScale');
      }
      break;

    case actions.UPDATE_CUSTOM_COLOR_PALETTE: {
      const { dimensionGroupingColumnName, group, selectedColor } = action;
      const path = ['series', 0, 'color', 'customPalette', dimensionGroupingColumnName, group, 'color'];
      _.set(state, path, selectedColor);
      break;
    }

    case actions.SET_SHOW_LINE_VALUE_LABELS:
      // Set showLineValueLabels since for combo we have two kinds of value labels --
      // those on the columns (controlled by showValueLabels) and those on the lines
      // (controlled by showLineValueLabels).
      setBooleanValueOrDefaultValue(state, 'configuration.showLineValueLabels', action.showLineValueLabels, true);
      break;

    case actions.APPEND_REFERENCE_LINE:
    case actions.RECEIVE_METADATA:
    case actions.REMOVE_REFERENCE_LINE:
    case actions.REMOVE_SERIES:
    case actions.SET_COLOR_PALETTE:
    case actions.SET_DATASET_UID:
    case actions.SET_DATE_DISPLAY_FORMAT:
    case actions.SET_DESCRIPTION:
    case actions.SET_DIMENSION:
    case actions.SET_DIMENSION_LABEL_ROTATION_ANGLE:
    case actions.SET_DOMAIN:
    case actions.SET_ERROR_BARS_BAR_COLOR:
    case actions.SET_ERROR_BARS_LOWER_BOUND_COLUMN_NAME:
    case actions.SET_ERROR_BARS_UPPER_BOUND_COLUMN_NAME:
    case actions.SET_FILTERS:
    case actions.SET_LABEL_BOTTOM:
    case actions.SET_LABEL_LEFT:
    case actions.SET_LABEL_RIGHT:
    case actions.SET_LEGEND_POSITION:
    case actions.SET_LINE_STYLE_POINT_RADIUS:
    case actions.SET_LINE_STYLE_POINTS:
    case actions.SET_MEASURE_AGGREGATION:
    case actions.SET_MEASURE_AXIS_MAX_VALUE:
    case actions.SET_MEASURE_AXIS_MIN_VALUE:
    case actions.SET_MEASURE_AXIS_PRECISION:
    case actions.SET_MEASURE_AXIS_SCALE:
    case actions.SET_MEASURE_COLUMN:
    case actions.SET_ORDER_BY:
    case actions.SET_PRECISION:
    case actions.SET_PRIMARY_COLOR:
    case actions.SET_REFERENCE_LINE_COLOR:
    case actions.SET_REFERENCE_LINE_LABEL:
    case actions.SET_REFERENCE_LINE_VALUE:
    case actions.SET_SECONDARY_COLOR:
    case actions.SET_SHOW_DATA_TABLE_CONTROL:
    case actions.SET_SHOW_LEGEND:
    case actions.SET_SHOW_LEGEND_OPENED:
    case actions.SET_SHOW_NULLS_AS_FALSE:
    case actions.SET_SHOW_VALUE_LABELS:
    case actions.SET_TITLE:
    case actions.SET_TREAT_NULL_VALUES_AS_ZERO:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
    case actions.SET_WRAP_DIMENSION_LABELS:
    case actions.SWAP_COLOR_PALETTE:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}
