import React from 'react';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';

import { Expression } from 'common/authoring_workflow/reducers/types';
import { ForgeTextField } from '@tylertech/forge-react';
import { get, isEqual } from 'lodash';
import { FILTER_FUNCTION } from 'common/components/FilterBar/SoqlFilter';

// Constants
const scope = 'shared.visualizations.panes.presentation.fields.ag_updated_column_format.conditional_options';

export interface OperandFieldsProps {
  conditionFormat: Expression;
  isNumberRenderType: boolean;
  onConditionValueChange: (newConditionValue: string | number, key: string, index?: number) => void;
  index?: number;
}

const OperandFields = (props: OperandFieldsProps): JSX.Element => {
  const { conditionFormat, isNumberRenderType, onConditionValueChange, index } = props;
  const formatCondition = get(conditionFormat, 'function', '');
  const conditionalValue = get(conditionFormat, 'arguments.value', '');
  const conditionalStartValue = get(conditionFormat, 'arguments.start', '');
  const conditionalEndValue = get(conditionFormat, 'arguments.end', '');
  const conditionalValuePlaceholder = I18n.t('operands.value_placeholder', { scope });
  const startValuePlaceholder = I18n.t('operands.start_placeholder', { scope });
  const endValuePlaceholder = I18n.t('operands.end_placeholder', { scope });
  const isRangeValue =
    isEqual(formatCondition, FILTER_FUNCTION.RANGE_EXCLUSIVE) ||
    isEqual(formatCondition, FILTER_FUNCTION.RANGE_INCLUSIVE);

  const renderConditionalValue = () => {
    const inputAttributes = {
      id: 'operand-value',
      'data-testid': 'operand-value',
      type: `${isNumberRenderType ? 'number' : 'text'}`,
      value: conditionalValue,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        onConditionValueChange(event.target.value, 'value', index),
      placeholder: conditionalValuePlaceholder
    };
    return (
      <div className="single-operand-container">
        <ForgeTextField>
          <input {...inputAttributes} />
        </ForgeTextField>
      </div>
    );
  };

  const renderRangeConditionalValue = () => {
    const startValueAttributes = {
      id: 'operand-start-value',
      'data-testid': 'operand-start-value',
      type: 'number',
      value: conditionalStartValue,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        onConditionValueChange(event.target.value, 'start', index),
      placeholder: startValuePlaceholder
    };
    const endValueAttributes = {
      id: 'operand-end-value',
      'data-testid': 'operand-end-value',
      type: 'number',
      value: conditionalEndValue,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        onConditionValueChange(event.target.value, 'end', index),
      placeholder: endValuePlaceholder
    };

    return (
      <div className="range-operand-container">
        <div id="start-value-container">
          <ForgeTextField>
            <input {...startValueAttributes} />
          </ForgeTextField>
        </div>
        <div id="end-value-container">
          <ForgeTextField>
            <input {...endValueAttributes} />
          </ForgeTextField>
        </div>
      </div>
    );
  };

  return (
    <div data-testid="operand-fields-container">
      {!isRangeValue && renderConditionalValue()}
      {isRangeValue && renderRangeConditionalValue()}
    </div>
  );
};

export default OperandFields;
