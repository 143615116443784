import _ from 'lodash';

// data parsers
import { getTableColumns, getTableHierarchies } from 'common/authoring_workflow/selectors/vifAuthoring';

// types
import { ViewColumn } from 'common/types/viewColumn';
import { Hierarchy, HierarchyColumnConfig } from 'common/visualizations/vif';
import { TableHierarchyGroupingsProps } from './index';

const useTableHierarchyGroupingsHooks = (props: TableHierarchyGroupingsProps) => {
  const tableHierarchies: Hierarchy[] = getTableHierarchies(props.vifAuthoring);
  const tableColumns = getTableColumns(props.vifAuthoring);

  // NECESSARY CONSTANT VALUES
  const existingColumnConfigs = _.intersectionWith(
    tableHierarchies[props.hierarchyIndex]?.columnConfigurations ?? [],
    tableColumns,
    (h: HierarchyColumnConfig, c: ViewColumn) => c.fieldName === h.columnName
  );
  const existingColumnConfigsToRemove = _.difference(
    tableHierarchies[props.hierarchyIndex]?.columnConfigurations ?? [],
    existingColumnConfigs
  );

  const groupingColumnsInCurrentHierarchy = _.intersectionWith(
    tableColumns,
    existingColumnConfigs,
    (c: ViewColumn, h: HierarchyColumnConfig) =>
      // Only return columns from the existing Hierarchies object that still exist in the selected columns for the table.
      // Checked columns will have isGrouping = true while other objects in the hierarchies list may have isGrouping = false.
      c.fieldName === h.columnName && h.isGrouping === true
  );
  // this is so that the columns are displayed at the top in the order in which they were selected
  const orderedGroupingColumns = _.sortBy(groupingColumnsInCurrentHierarchy, (c: ViewColumn) =>
    _.findIndex(existingColumnConfigs, (h: HierarchyColumnConfig) => c.fieldName === h.columnName)
  );
  const nonGroupingColumnsInCurrentHierarchy = _.difference(tableColumns, groupingColumnsInCurrentHierarchy);
  const combinedColumns = orderedGroupingColumns.concat(nonGroupingColumnsInCurrentHierarchy);

  // HANDLER FUNCTIONS
  const handleOnAggregationSelection = (aggregationValue: string, columnName: string) => {
    const currentColumnConfig = { columnName, aggregation: aggregationValue, isGrouping: false };
    props.handleOnUpdateHierarchy(existingColumnConfigs, props.hierarchyIndex, currentColumnConfig);
  };

  const handleOnRearrangeHierarchy = (currentColumnConfigs: HierarchyColumnConfig[]) => {
    props.handleOnRearrangeHierarchy(existingColumnConfigs, props.hierarchyIndex, currentColumnConfigs);
  };

  const handleOnUpdateHierarchy = (currentColumnConfig: HierarchyColumnConfig) => {
    props.handleOnUpdateHierarchy(existingColumnConfigs, props.hierarchyIndex, currentColumnConfig);
  };

  const handleOnUpdateHierarchyName = (name: string) => {
    props.handleOnUpdateHierarchyName(props.hierarchyIndex, name);
  };

  const handleOnRemoveHierarchy = () => {
    props.handleOnRemoveHierarchy(props.hierarchyIndex);
  };

  const handleOnResetHierarchy = () => {
    props.handleOnResetHierarchy(props.hierarchyIndex);
  };

  const handleOnDuplicateHierarchy = () => {
    props.handleOnDuplicateHierarchy(props.hierarchyIndex);
  };

  const handleOnShowGrandTotal = (isShowing: boolean) => {
    props.handleOnShowGrandTotal(props.hierarchyIndex, isShowing);
  };

  const handleOnShowSubTotal = (isShowing: boolean) => {
    props.handleOnShowSubTotal(props.hierarchyIndex, isShowing);
  };

  // This handles when a column that is part of a hierarchy is removed from the column section and no longer visible in the hierarchy section
  const handleOnColumnsUpdatedRemoveHierarchies = () => {
    props.handleOnUpdateHierarchy(existingColumnConfigs, props.hierarchyIndex);
  };

  const setActiveHierarchy = () => {
    props.setActiveHierarchyId(tableHierarchies[props.hierarchyIndex].id);
  };

  return {
    state: {
      tableColumns,
      hierarchy: tableHierarchies[props.hierarchyIndex] ?? {},
      totalHierarchiesDefined: tableHierarchies.length,
      combinedColumns,
      existingColumnConfigs,
      existingColumnConfigsToRemove
    },
    functions: {
      setActiveHierarchy,
      handleOnDuplicateHierarchy,
      handleOnUpdateHierarchy,
      handleOnRearrangeHierarchy,
      handleOnUpdateHierarchyName,
      handleOnRemoveHierarchy,
      handleOnResetHierarchy,
      handleOnColumnsUpdatedRemoveHierarchies,
      handleOnAggregationSelection,
      handleOnShowGrandTotal,
      handleOnShowSubTotal
    }
  };
};

export default useTableHierarchyGroupingsHooks;
