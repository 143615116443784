// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  initiateRegionCoding,
  requestShapefileMetadata,
  setCenterAndZoom,
  setComputedColumn
} from '../../actions';
import {
  getCurrentMetadata,
  getValidComputedColumns,
  getValidCuratedRegions,
  hasData,
  hasRegions
} from '../../selectors/metadata';
import {
  getDatasetUid,
  getDimension,
  getDomain,
  getMapType,
  getPointAggregation,
  getRegionCodingShapefileIdInProcess,
  getShapefileUid,
  isNewGLMap,
  isRegionMap
} from '../../selectors/vifAuthoring';
import BlockLabel from 'common/components/BlockLabel';
import Dropdown from 'common/components/Dropdown';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

// Constants
const scope = 'shared.visualizations.panes.data.fields.region';

export class RegionSelector extends Component {
  onSelectRegion({ computedColumn, curatedRegion, domain }) {
    const {
      onInitiateRegionCoding,
      onRequestShapefileMetadata,
      onSetCenterAndZoom,
      onSetComputedColumn,
      vifAuthoring
    } = this.props;

    if (computedColumn) {
      onSetComputedColumn(computedColumn.fieldName);
      onRequestShapefileMetadata(domain, computedColumn.uid);
      onSetCenterAndZoom(null);
    } else if (curatedRegion) {
      onInitiateRegionCoding(
        getDomain(vifAuthoring),
        getDatasetUid(vifAuthoring),
        getDimension(vifAuthoring).columnName,
        curatedRegion
      );
    }
  }

  renderRegionProcessingMessage = () => {
    const {
      regionCodingLastChecked,
      showRegionCodingProcessingMessage
    } = this.props.vifAuthoring.authoring;

    if (showRegionCodingProcessingMessage) {
      return (
        <div className="region-processing-info alert warning">
          <p>{I18n.t('selected_region_processing', { scope })}</p>
          <p>{I18n.t('region_coding_duration', { scope })}</p>
          <p>{I18n.t('stay_or_return_later', { scope })}</p>
          <p className="region-processing-info-last-checked">
            <span className="spinner-default" />
            {I18n.t('last_checked', { scope })}
            {regionCodingLastChecked ? regionCodingLastChecked : I18n.t('never', { scope })}
          </p>
        </div>
      );
    }
  };

  renderRegionProcessingError = () => {
    const { regionCodingError } = this.props.vifAuthoring.authoring;

    if (regionCodingError) {
      return (
        <div className="region-processing-error alert error">
          <p>{I18n.t('oh_no', { scope })}<br />{I18n.t('error_processing_region', { scope })}</p>
        </div>
      );
    }
  };

  renderSelector = () => {
    const { metadata, vifAuthoring } = this.props;
    const reference = (ref) => { this.selector = ref; };
    const domain = getDomain(vifAuthoring);
    const isDerivedDataset = !(_.get(metadata, ['data', 'flags'], []).includes('default'));
    /** The write right is revoked on all data federated assets,
     * which hides the option to choose processing maps/curated
     * regions from users on target domains. */
    const hasWriteRight = (_.get(metadata, ['data', 'rights'], []).includes('write'));
    const defaultRegion = getShapefileUid(vifAuthoring);
    const curatedRegionInProcess = getRegionCodingShapefileIdInProcess(vifAuthoring);
    const selectedRegion = _.isNull(defaultRegion) ? curatedRegionInProcess : defaultRegion;
    const computedColumns = _.map(getValidComputedColumns(metadata), (computedColumn) => {
      return {
        title: computedColumn.name,
        value: computedColumn.uid,
        group: I18n.t('groups.ready_to_use', { scope }),
        computedColumn,
        domain
      };
    });
    const curatedRegions = isDerivedDataset ? [] : _.map(getValidCuratedRegions(metadata), (curatedRegion) => {
      return {
        title: curatedRegion.name,
        value: curatedRegion.uid,
        group: I18n.t('groups.requires_processing', { scope }),
        curatedRegion
      };
    });

    let disabled = !hasRegions(metadata);
    let placeholder = I18n.t('placeholder', { scope });
    const isNewGLMapEnabled = FeatureFlags.value('enable_new_maps') && isNewGLMap(vifAuthoring);
    const options = [...computedColumns];

    if (isNewGLMapEnabled) {
      disabled = disabled || getPointAggregation(vifAuthoring) !== 'region_map';
      placeholder = I18n.t('placeholder', { scope: 'shared.visualizations.panes.data.fields.region_map' });
    }

    if (hasWriteRight) {
      options.push(...curatedRegions);
    }

    const regionAttributes = {
      disabled,
      id: 'region-selection',
      onSelection: (option) => this.onSelectRegion(option),
      options: options,
      placeholder,
      value: selectedRegion
    };
    const sectionTitle = isNewGLMapEnabled ?
      null :
      <BlockLabel
        htmlFor="region-selection"
        title={I18n.t('title', { scope })}
        description={I18n.t('region_processing', { scope })} />;

    return (
      <div className="region-selector-container" ref={reference}>
        {sectionTitle}
        <Dropdown {...regionAttributes} />
        {this.renderRegionProcessingMessage()}
        {this.renderRegionProcessingError()}
      </div>
    );
  };

  render() {
    const { metadata, vifAuthoring } = this.props;
    const dimension = getDimension(vifAuthoring);
    const isNewGLMapEnabled = FeatureFlags.value('enable_new_maps');
    const isPointMap = getMapType(vifAuthoring) === 'pointMap';
    const canRender = hasData(metadata) &&
      (isRegionMap(vifAuthoring) || (isNewGLMapEnabled && isNewGLMap(vifAuthoring) && isPointMap)) &&
      _.isString(dimension.columnName);

    return canRender ?
      this.renderSelector() :
      null;
  }
}

RegionSelector.propTypes = {
  metadata: PropTypes.object,
  onInitiateRegionCoding: PropTypes.func,
  onRequestShapefileMetadata: PropTypes.func,
  onSetCenterAndZoom: PropTypes.func,
  onSetComputedColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onInitiateRegionCoding: initiateRegionCoding,
  onRequestShapefileMetadata: requestShapefileMetadata,
  onSetCenterAndZoom: setCenterAndZoom,
  onSetComputedColumn: setComputedColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionSelector);
