// Vendor Imports
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import {
  setLimitCountAndShowOtherCategory,
  setLimitNoneAndShowOtherCategory,
  setShowOtherCategory,
  setTreatNullValuesAsZero
} from '../../../actions';

import {
  getCurrentDimensionColumnName,
  getLimitCount,
  getSeries,
  getShowOtherCategory,
  getTreatNullValuesAsZero,
  getVisualizationType,
  isBarChart,
  isColumnChart,
  isComboChart,
  isPieChart
} from '../../../selectors/vifAuthoring';

import { getCurrentMetadata, isDimensionTypeCalendarDate } from '../../../selectors/metadata';
import DateDisplayFormatSelector from './DateDisplayFormatSelector';
import DebouncedInput from '../../shared/DebouncedInput';
import GroupedStackedSelector from './GroupedStackedSelector';
import TimelinePrecisionSelector from '../../shared/TimelinePrecisionSelector';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';

// Constants
import { SERIES_TYPE_COMBO_CHART_COLUMN } from '../../../constants';

export class DisplayOptions extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'renderGroupedStackedSelector',
      'renderTimelinePrecisionSelector',
      'renderLimitCountSelector'
    ]);
  }

  getLabelForSlice(count) {
    const scope = 'shared.visualizations.panes.data.fields.slices';
    const format = (count == 1) ?
      I18n.t('singular_label', { scope }) :
      I18n.t('plural_label', { scope });
    return formatString(format, count);
  }

  hasMultipleColumnSeries() {
    return _.filter(
      getSeries(this.props.vifAuthoring),
      (series) => series.type === SERIES_TYPE_COMBO_CHART_COLUMN
    ).length > 1;
  }

  renderGroupedStackedSelector() {
    const { vifAuthoring } = this.props;
    const shouldRender =
      isBarChart(vifAuthoring) ||
      isColumnChart(vifAuthoring) ||
      (isComboChart(vifAuthoring) && this.hasMultipleColumnSeries());

    return shouldRender ? <GroupedStackedSelector /> : null;
  }

  renderTimelinePrecisionSelector() {
    const { metadata, vifAuthoring } = this.props;
    const currentDimensionColumnName = getCurrentDimensionColumnName(vifAuthoring);
    const shouldRender = (isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) || isComboChart(vifAuthoring)) &&
      isDimensionTypeCalendarDate(metadata, currentDimensionColumnName);

    return shouldRender ? (
      <div>
        <TimelinePrecisionSelector />
        <DateDisplayFormatSelector />
      </div>
     ) : null;
  }

  renderTreatNullValuesAsZero() {
    const { onSetTreatNullValuesAsZero, vifAuthoring } = this.props;
    const shouldRender = (isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) || isComboChart(vifAuthoring));

    const inputAttributes = {
      defaultChecked: getTreatNullValuesAsZero(vifAuthoring),
      id: 'treat-null-values-as-zero',
      onChange: (event) => onSetTreatNullValuesAsZero(event.target.checked),
      type: 'checkbox'
    };

    return shouldRender ? (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="treat-null-values-as-zero">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('shared.visualizations.panes.data.fields.treat_null_values_as_zero.title')}
        </label>
      </div>
    ) : null;
  }

  renderLimitCountSelector() {
    const {
      metadata,
      onSetLimitCountAndShowOtherCategory,
      onSetLimitNoneAndShowOtherCategory,
      onSetShowOtherCategory,
      vifAuthoring
    } = this.props;
    const currentDimensionColumnName = getCurrentDimensionColumnName(vifAuthoring);
    const shouldRender = !isDimensionTypeCalendarDate(metadata, currentDimensionColumnName);

    if (!shouldRender) {
      return null;
    }

    const limitCount = getLimitCount(vifAuthoring);
    const showOtherCategory = getShowOtherCategory(vifAuthoring);
    const visualizationType = getVisualizationType(vifAuthoring);
    const translationKeys = {
      barChart: 'bar_chart_limit',
      pieChart: 'pie_chart_limit',
      columnChart: 'column_chart_limit',
      comboChart: 'combo_chart_limit'
    };
    const translationKey = translationKeys[visualizationType] || 'default_chart_limit';
    const limitCountDisabled = (limitCount === null);

    // 'Do not limit results' radio button
    const limitNoneInputAttributes = {
      checked: limitCountDisabled,
      disabled: isPieChart(vifAuthoring),
      id: 'limit-none',
      onChange: (event) => onSetLimitNoneAndShowOtherCategory(event.target.checked, false),
      name: 'limit-radio',
      type: 'radio'
    };

    const limitNoneContainer = isPieChart(vifAuthoring) ? null : (
      <div id="limit-none-container">
        <input {...limitNoneInputAttributes} />
        <label htmlFor="limit-none">
          <span className="fake-radiobutton" />
        </label>
        {I18n.t(`shared.visualizations.panes.data.fields.${translationKey}.none`)}
      </div>
    );

    // 'Limit results' radio button
    const limitCountInputAttributes = {
      checked: !limitCountDisabled,
      id: 'limit-count',
      onChange: () => {
        const limitCountValueInput = this.limitCountValueContainerRef.querySelector('#limit-count-value');
        onSetLimitCountAndShowOtherCategory(
          parseInt(limitCountValueInput.value, 10),
          this.showOtherCategoryCheckbox.checked
        );
      },
      name: 'limit-radio',
      type: 'radio'
    };

    // 'Limit results to' number input and other category group checkbox
    const limitCountValueContainerAttributes = {
      className: `authoring-field${(limitCountDisabled) ? ' disabled' : ''}`,
      id: 'limit-count-value-container',
      ref: (ref) => this.limitCountValueContainerRef = ref
    };

    const showOtherCategoryInputAttributes = {
      defaultChecked: showOtherCategory,
      disabled: limitCountDisabled,
      id: 'show-other-category',
      onChange: (event) => onSetShowOtherCategory(event.target.checked),
      ref: (ref) => this.showOtherCategoryCheckbox = ref,
      type: 'checkbox'
    };

    const limitCountValueInput = isPieChart(vifAuthoring) ?
      this.renderLimitCountDropdown() :
      this.renderLimitCountInputText();

    const limitCountValueContainer = (
      <div {...limitCountValueContainerAttributes}>
        {limitCountValueInput}
        <div id="show-other-category-container" className="checkbox">
          <input {...showOtherCategoryInputAttributes} />
          <label className="inline-label" htmlFor="show-other-category">
            <span className="fake-checkbox">
              <span className="icon-checkmark3" />
            </span>
            {I18n.t('shared.visualizations.panes.data.fields.show_other_category.title')}
          </label>
        </div>
      </div>
    );

    const limitCountContainer = (
      <div id="limit-count-container">
        <input {...limitCountInputAttributes} />
        <label htmlFor="limit-count">
          <span className="fake-radiobutton" />
        </label>
        {I18n.t(`shared.visualizations.panes.data.fields.${translationKey}.count`)}
        {limitCountValueContainer}
      </div>
    );

    return (
      <div className="authoring-field">
        <span id="limit-subtitle">{I18n.t(`shared.visualizations.panes.data.fields.${translationKey}.subtitle`)}</span>
        <div className="radiobutton">
          {limitNoneContainer}
          {limitCountContainer}
        </div>
      </div>
    );
  }

  renderLimitCountOption(option) {
    return (
      <div>
        {option.title}
      </div>
    );
  }

  renderLimitCountDropdown() {
    const { onSetLimitCountAndShowOtherCategory, vifAuthoring } = this.props;
    const limitCount = getLimitCount(vifAuthoring);
    const disabled = (limitCount === null);

    const options = _.range(1, 13).map((i) => ({
      render: this.renderLimitCountOption,
      title: this.getLabelForSlice(i),
      value: i
    }));

    const props = {
      disabled,
      id: 'limit-count-selection',
      onSelection: (option) => onSetLimitCountAndShowOtherCategory(
        parseInt(option.value, 10),
        this.showOtherCategoryCheckbox.checked
      ),
      options,
      value: _.isNumber(limitCount) ? limitCount : 10
    };

    return (
      <div id="limit-count-container">
        <Dropdown {...props} />
      </div>
    );
  }

  renderLimitCountInputText() {
    const { onSetLimitCountAndShowOtherCategory, vifAuthoring } = this.props;
    const limitCount = getLimitCount(vifAuthoring);
    const disabled = (limitCount === null);

    const limitCountValueInputAttributes = {
      className: 'text-input',
      disabled,
      forceEnterKeyHandleChange: true,
      id: 'limit-count-value',
      min: 1,
      onChange: (event) => onSetLimitCountAndShowOtherCategory(
        parseInt(event.target.value, 10),
        this.showOtherCategoryCheckbox.checked
      ),
      step: 1,
      type: 'number',
      value: _.isNumber(limitCount) ? limitCount : 10
    };

    return (
      <div id="limit-count-container">
        <DebouncedInput {...limitCountValueInputAttributes} />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderTimelinePrecisionSelector()}
        {this.renderGroupedStackedSelector()}
        {this.renderLimitCountSelector()}
        {this.renderTreatNullValuesAsZero()}
      </div>
    );
  }
}

DisplayOptions.propTypes = {
  metadata: PropTypes.object,
  onSetLimitCountAndShowOtherCategory: PropTypes.func,
  onSetLimitNoneAndShowOtherCategory: PropTypes.func,
  onSetShowOtherCategory: PropTypes.func,
  onSetTreatNullValuesAsZero: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetLimitCountAndShowOtherCategory: setLimitCountAndShowOtherCategory,
  onSetLimitNoneAndShowOtherCategory: setLimitNoneAndShowOtherCategory,
  onSetShowOtherCategory: setShowOtherCategory,
  onSetTreatNullValuesAsZero: setTreatNullValuesAsZero
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayOptions);
