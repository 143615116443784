import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components } from 'common/visualizations';
import VisualizationPreviewWarning from './VisualizationPreviewWarning';
import I18n from 'common/i18n';

import {
  replaceVif,
  setCenterAndZoom,
  setCurrentDrilldownColumnName,
  setCurrentDisplayDate,
  setDimensionLabelAreaSize,
  setFilters,
  setMapLayerVisible,
  setPitchAndBearing,
  setSearchBoundaryLowerRightLatitude,
  setSearchBoundaryLowerRightLongitude,
  setSearchBoundaryUpperLeftLatitude,
  setSearchBoundaryUpperLeftLongitude
} from '../../actions';
import {
  getCurrentVif,
  hasVisualizationDimension,
  hasVisualizationType,
  isValidVif,
  isInsertableVisualization,
  isScatterChart,
  isLogarithmicScale,
  isTable
} from '../../selectors/vifAuthoring';

export class VisualizationPreview extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'handleVizVifUpdate',
      'onCenterAndZoomChanged',
      'onDimensionLabelAreaSizeChanged',
      'onDrilldownColumnChanged',
      'onPitchAndBearingChanged',
      'onFiltersChanged',
      'onToggleMapLayerVisible',
      'renderVisualization',
      'setGeocodeBounds'
    ]);
  }

  componentDidMount() {
    $(this.visualizationPreview)
      .on('SOCRATA_VISUALIZATION_VIF_UPDATED', this.handleVizVifUpdate)
      .on('SOCRATA_VISUALIZATION_SET_GEOCODE_BOUNDS', this.setGeocodeBounds)
      .on('SOCRATA_VISUALIZATION_MAP_CENTER_AND_ZOOM_CHANGED', this.onCenterAndZoomChanged)
      .on('SOCRATA_VISUALIZATION_PITCH_AND_BEARING_CHANGED', this.onPitchAndBearingChanged)
      .on('SOCRATA_VISUALIZATION_CURRENT_DATE_CHANGED', this.onCurrentDisplayDateChanged)
      .on('SOCRATA_VISUALIZATION_FILTERS_CHANGED', this.onFiltersChanged)
      .on('SOCRATA_VISUALIZATION_DRILL_DOWN_CHANGED', this.onDrilldownColumnChanged)
      .on('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', this.onDimensionLabelAreaSizeChanged)
      .on('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', this.onToggleMapLayerVisible);
  }

  componentWillUnmount() {
    $(this.visualizationPreview)
      .off('SOCRATA_VISUALIZATION_SET_GEOCODE_BOUNDS', this.setGeocodeBounds)
      .off('SOCRATA_VISUALIZATION_MAP_CENTER_AND_ZOOM_CHANGED', this.onCenterAndZoomChanged)
      .off('SOCRATA_VISUALIZATION_PITCH_AND_BEARING_CHANGED', this.onPitchAndBearingChanged)
      .off('SOCRATA_VISUALIZATION_CURRENT_DATE_CHANGED', this.onCurrentDisplayDateChanged)
      .off('SOCRATA_VISUALIZATION_FILTERS_CHANGED', this.onFiltersChanged)
      .off('SOCRATA_VISUALIZATION_DRILL_DOWN_CHANGED', this.onDrilldownColumnChanged)
      .off('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', this.onDimensionLabelAreaSizeChanged)
      .off('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', this.onToggleMapLayerVisible);
  }

  shouldComponentUpdate(nextProps) {
    const { vif } = this.props;
    const vifChanged = !_.isEqual(vif, nextProps.vif);

    // Our SVG maps mutate VIFs run internal state for map center and zoom which is
    // intercepted through an event. We're interested in saving this data, but we
    // don't have to re-render when it happens because the visualization code takes
    // care of that for us.
    const nextMapCenterAndZoom = _.get(nextProps.vif, 'configuration.mapCenterAndZoom');
    const mapCenterAndZoom = _.get(vif, 'configuration.mapCenterAndZoom');
    const nextMapPitchAndBearing = _.get(nextProps.vif, 'configuration.mapPitchAndBearing');
    const mapPitchAndBearing = _.get(vif, 'configuration.mapPitchAndBearing');

    if (
      _.isEmpty(nextMapCenterAndZoom) ||
      _.isEmpty(mapCenterAndZoom) ||
      _.isEmpty(nextMapPitchAndBearing) ||
      _.isEmpty(mapPitchAndBearing)
    ) {
      return vifChanged;
    } else {
      return (
        vifChanged &&
        _.isEqual(mapCenterAndZoom, nextMapCenterAndZoom) &&
        _.isEqual(mapPitchAndBearing, nextMapPitchAndBearing)
      );
    }
  }

  handleVizVifUpdate(event) {
    const { vifAuthoring } = this.props;
    if (isTable(vifAuthoring)) {
      const updatedVif = _.get(event, 'originalEvent.detail');
      this.props.onVizVifUpdate(updatedVif);
    }
  }

  onCenterAndZoomChanged(event) {
    const centerAndZoom = _.get(event, 'originalEvent.detail');
    this.props.onSetCenterAndZoom(centerAndZoom);
  }

  onPitchAndBearingChanged(event) {
    const pitchAndBearing = _.get(event, 'originalEvent.detail');
    this.props.onSetPitchAndBearing(pitchAndBearing);
  }

  onFiltersChanged(event) {
    const { filters } = _.get(event, 'originalEvent.detail');
    this.props.onSetFilters(filters);
  }

  onDrilldownColumnChanged(event) {
    const { currentDrilldownDimensionColumnName } = _.get(event, 'originalEvent.detail');
    this.props.onSetCurrentDrilldownColumnName(currentDrilldownDimensionColumnName);
  }

  onDimensionLabelAreaSizeChanged(event) {
    const width = _.get(event, 'originalEvent.detail');
    if (_.isFinite(width)) {
      this.props.onSetDimensionLabelAreaSize(width);
    }
  }

  onCurrentDisplayDateChanged = (event) => {
    const newDateString = _.get(event, 'originalEvent.detail');
    this.props.onSetCurrentDisplayDate(newDateString);
  };

  onToggleMapLayerVisible(event) {
    const { relativeIndex, visible } = _.get(event, 'originalEvent.detail');
    this.props.onSetMapLayerVisible(relativeIndex, visible);
  }

  setGeocodeBounds(event) {
    const {
      onSetSearchBoundaryLowerRightLatitude,
      onSetSearchBoundaryLowerRightLongitude,
      onSetSearchBoundaryUpperLeftLatitude,
      onSetSearchBoundaryUpperLeftLongitude
    } = this.props;

    const bounds = _.get(event, 'originalEvent.detail');

    onSetSearchBoundaryUpperLeftLatitude(bounds.getNorth());
    onSetSearchBoundaryUpperLeftLongitude(bounds.getWest());
    onSetSearchBoundaryLowerRightLatitude(bounds.getSouth());
    onSetSearchBoundaryLowerRightLongitude(bounds.getEast());
  }

  renderVisualizationPreviewWarning() {
    const { vifAuthoring } = this.props;
    const logarithmicScaleText = I18n.t(
      'shared.visualizations.charts.timeline_chart.logarithmic_scale_warning'
    );

    return isLogarithmicScale(vifAuthoring) ? (
      <VisualizationPreviewWarning text={logarithmicScaleText} />
    ) : null;
  }

  renderVisualization() {
    const { vif, vifAuthoring } = this.props;
    const hasType = hasVisualizationType(vifAuthoring);
    const hasDimension = hasVisualizationDimension(vifAuthoring);
    const isDimensionNotRequired = isScatterChart(vifAuthoring) || isTable(vifAuthoring);
    const options = {
      drilldown: {
        emitDrilldownChange: true,
        handleVifUpdatesInternally: false
      },
      inVisualizationPreview: true
    };

    return hasType && (hasDimension || isDimensionNotRequired) && isValidVif(vifAuthoring) ? (
      <components.Visualization vif={vif} options={options} />
    ) : null;
  }

  render() {
    const previewClasses = classNames('visualization-preview', {
      'visualization-preview-rendered': isInsertableVisualization(this.props.vifAuthoring),
      hide: this.props.hide
    });

    return (
      <div className={previewClasses} ref={(ref) => (this.visualizationPreview = ref)}>
        {this.renderVisualizationPreviewWarning()}
        {this.renderVisualization()}
      </div>
    );
  }
}

VisualizationPreview.propTypes = {
  onSetCenterAndZoom: PropTypes.func,
  onSetDimensionLabelAreaSize: PropTypes.func,
  onSetMapLayerVisible: PropTypes.func,
  onSetPitchAndBearing: PropTypes.func,
  onSetSearchBoundaryLowerRightLatitude: PropTypes.func,
  onSetSearchBoundaryLowerRightLongitude: PropTypes.func,
  onSetSearchBoundaryUpperLeftLatitude: PropTypes.func,
  onSetSearchBoundaryUpperLeftLongitude: PropTypes.func,
  vif: PropTypes.object,
  vifAuthoring: PropTypes.object,
  hide: PropTypes.bool
};

VisualizationPreview.defaultProps = {
  hide: false
};

const mapDispatchToProps = {
  onVizVifUpdate: replaceVif,
  onSetCenterAndZoom: setCenterAndZoom,
  onSetCurrentDrilldownColumnName: setCurrentDrilldownColumnName,
  onSetCurrentDisplayDate: setCurrentDisplayDate,
  onSetDimensionLabelAreaSize: setDimensionLabelAreaSize,
  onSetMapLayerVisible: setMapLayerVisible,
  onSetPitchAndBearing: setPitchAndBearing,
  onSetFilters: setFilters,
  onSetSearchBoundaryLowerRightLatitude: setSearchBoundaryLowerRightLatitude,
  onSetSearchBoundaryLowerRightLongitude: setSearchBoundaryLowerRightLongitude,
  onSetSearchBoundaryUpperLeftLatitude: setSearchBoundaryUpperLeftLatitude,
  onSetSearchBoundaryUpperLeftLongitude: setSearchBoundaryUpperLeftLongitude
};

const mapStateToProps = (state) => ({
  vif: getCurrentVif(state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationPreview);
