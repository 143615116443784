import { combineReducers } from 'redux';

import metadataCollection from './metadataCollection';
import authoring from './authoring';

import agTable from './vifs/agTable';
import barChart from './vifs/barChart';
import map from './vifs/map';
import regionMap from './vifs/regionMap';
import calendar from './vifs/calendar';
import columnChart from './vifs/columnChart';
import comboChart from './vifs/comboChart';
import pieChart from './vifs/pieChart';
import featureMap from './vifs/featureMap';
import histogram from './vifs/histogram';
import scatterChart from './vifs/scatterChart';
import table from './vifs/table';
import timelineChart from './vifs/timelineChart';
import dataTable from './vifs/dataTable';

// Placeholder reducer for initialVif
const initialVif = state => state || {};

export default combineReducers({
  metadataCollection,
  vifAuthoring: combineReducers({
    authoring,
    vifs: combineReducers({
      initialVif,
      agTable,
      barChart,
      map,
      regionMap,
      calendar,
      columnChart,
      comboChart,
      pieChart,
      featureMap,
      histogram,
      scatterChart,
      table,
      dataTable,
      timelineChart
    })
  })
});
