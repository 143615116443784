// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setShowDataTableControl } from '../../actions';
import { getShowDataTableControl } from '../../selectors/vifAuthoring';
import I18n from 'common/i18n';

export class ShowDataTableControl extends Component {
  render() {
    const { onSetShowDataTableControl, vifAuthoring } = this.props;
    const scope = 'shared.visualizations.panes';
    const inputAttributes = {
      checked: getShowDataTableControl(vifAuthoring),
      id: 'show-data-table-control',
      onChange: (event) => onSetShowDataTableControl(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-data-table-control">
          <span className="fake-checkbox"><span className="icon-checkmark3"></span></span>
          {I18n.t('show_data_table_control.title', { scope })}
        </label>
      </div>
    );
  }
}

ShowDataTableControl.propTypes = {
  onSetShowDataTableControl: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetShowDataTableControl: setShowDataTableControl
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(ShowDataTableControl);
